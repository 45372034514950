import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Table from "../../../ui-components/table/table";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { userSelector } from "../../../slices/user/userSlice";
import {
  changePickups,
  resetDrivers,
  getDrivers,
  getDriverById,
  pickupAssignmentSelector
} from "../../../slices/pickup/pickupAssignmentSlice";
import {
  DriversResponseContentModel,
  PickupAssignRequestModel,
  PickupsResponseContentModel
} from "../../../app/data/pickup/models";
import person from "../../../images/person.svg";
import truckBlack from "../../../images/truck_black.svg";
import truckBlue from "../../../images/truck_blue.svg";
import manifest from "../../../images/manifest.svg";
import { PickupStatus } from "../../../app/data/common/route";
import { getPickupGroupStatus, getPickupsByIds } from "../../../services/pickups";
import { cn } from "../../../services/common/className";

export interface PickupListProps {
  selectedDriverId: number | null;
  scrollToDriverId: number | null;
  onDriverSelect: (id: number | null) => void;
  pickupIds: string[];
  groupId: string;
  onClickReschedule: () => void;
};

const DriversList: React.FC<PickupListProps> = ({ selectedDriverId, onDriverSelect, onClickReschedule, ...props }) => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const userState = useSelector(userSelector);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pickups, setPickups] = useState<PickupsResponseContentModel[]>([]);
  const tableRef = React.useRef<HTMLElement>(null);

  const selectedDriver = selectedDriverId && pickupAssignmentState.drivers.find(driver => driver.driverId === selectedDriverId);
  const selectedDriverName = selectedDriver ? selectedDriver.driverName : "";
  const shipperZip = useRef("");
  const status = useMemo(() => (pickups.length ? getPickupGroupStatus(pickups) : ""), [pickups]);

  const changeAssignment = () => {    
    if (!pickups.length) return;

    const pickupsRequest = pickups.reduce((result: PickupAssignRequestModel[], pickup) => {
      if (status === PickupStatus.ASSIGNED) {
        return [
          ...result,
          {
            pickupId: pickup.pickup.pickupId,
            driverId: null,
            status: PickupStatus.UNASSIGNED,
          }
        ];
      } else if (status === PickupStatus.UNASSIGNED) {
        return [
          ...result,
          {
            pickupId: pickup.pickup.pickupId,
            driverId: selectedDriverId,
            status: PickupStatus.ASSIGNED,
          }
        ];
      } else return result;
    }, []);

    dispatch(changePickups(
      pickupsRequest,
      userState.profile?.email || "",
      props.groupId,
      () => {
        toast.info(`${pickupsRequest.length > 1 ? "Pickups" : "Pickup"} has been ${status === PickupStatus.UNASSIGNED ? "assigned" : "unassigned"}!`, { autoClose: 3000 });
        setShowConfirmation(false);
      }, (error) => {
        toast.error(error);
      }));
  };

  const columns = [
    {
      width: 200,
      Header: "",
      id: "driverData",
      Cell: ({ row }: any) => (
        <div className="xgs-pickup-assignment__drivers__list__item">
          <div className="xgs-pickup-assignment__drivers__list__item__property">
            <div className="xgs-pickup-assignment__drivers__list__item__property__icon">
              <img src={person} alt="driver name" />
            </div>
            <div className="xgs-pickup-assignment__drivers__list__item__property__text xgs-pickup-assignment__drivers__list__item__property__text--driver-name">
              {row.original.driverName}
            </div>
          </div>
          <div className="xgs-pickup-assignment__drivers__list__item__property">
            <div className="xgs-pickup-assignment__drivers__list__item__property__icon">
              <img src={truckBlack} alt="truck" />
            </div>
            <div className="xgs-pickup-assignment__drivers__list__item__property__text">
              {row.original.tractorNumber}
            </div>
          </div>
          {!!row.original.manifestNumber && (
            <div className="xgs-pickup-assignment__drivers__list__item__property">
              <div className="xgs-pickup-assignment__drivers__list__item__property__icon">
                <img src={manifest} alt="manifest" />
              </div>
              <div className="xgs-pickup-assignment__drivers__list__item__property__text">
                {row.original.manifestNumber}
              </div>
            </div>
          )}
        </div>
      )
    },
    {
      width: 52,
      minWidth: 52,
      Header: "",
      id: "selectedRow",
      Cell: ({ row }: any) => (
        <div className="text-center">
          <input
            type="radio"
            name="selectedDriverRow"
            value="selected"
            checked={row.original.driverId === selectedDriverId}
            onChange={(e) => {}}
          />
        </div>
      )
    }
  ];

  const onDriverClick = (row: DriversResponseContentModel) => {
    onDriverSelect(row.driverId);
  };

  useEffect(() => {
    setShowConfirmation(false);

    if (!pickups.length || status === PickupStatus.CANCELED) {
      dispatch(resetDrivers());
      shipperZip.current = "";
      return;
    }

    if (status === PickupStatus.UNASSIGNED) {
      const nextShipperZip = pickups[0].shipper.address.postalCode;
      if (nextShipperZip === shipperZip.current) return;
      shipperZip.current = nextShipperZip;
      dispatch(resetDrivers());
      dispatch(getDrivers(pickups[0].shipper.address.postalCode));
      onDriverSelect(null);
    } else {
      dispatch(resetDrivers());
      shipperZip.current = "";
      const driverId = pickups.find(pickup => pickup.pickup.driverId)?.pickup.driverId;
      if (driverId) {
        dispatch(getDriverById(driverId, (driverName: string) => {
          onDriverSelect(driverId);
        }));
      }
    }
    // eslint-disable-next-line
  }, [pickups]);

  useEffect(() => {
    if (!props.pickupIds.length) return;
    const pickups = getPickupsByIds(pickupAssignmentState.pickups, props.pickupIds);
    setPickups(pickups);
  }, [props.pickupIds, pickupAssignmentState.pickups]);

  useEffect(() => {
    if (!props.scrollToDriverId) return;
    const scrollPosition = pickupAssignmentState.drivers.findIndex(driver => driver.driverId === props.scrollToDriverId) * 64;
    tableRef.current?.scrollTo(0, scrollPosition);
  }, [props.scrollToDriverId, pickupAssignmentState.drivers]);

  const hasButton = (selectedDriverId && (status !== PickupStatus.COMPLETED) && (status !== PickupStatus.CANCELED) && (status !== PickupStatus.MISSED)) || ((status === PickupStatus.MISSED) && (props.pickupIds.length === 1));

  return (
    <div className="xgs-pickup-assignment__drivers">
      {!props.pickupIds.length && (
        <div className="xgs-pickup-assignment__drivers__not-selected">
          Choose Pickup
        </div>
      )}
      {!!props.pickupIds.length && (
        <>
          <div className={cn("xgs-pickup-assignment__drivers__list")({ "with-button": hasButton, "centered-content": status === PickupStatus.MISSED })}>
            {status !== PickupStatus.MISSED && (
              <Table
                isLoading={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_DRIVERS" || pickupAssignmentState.requestCreator === "GET_DRIVER")}
                columns={columns}
                data={pickupAssignmentState.drivers}
                cursorPointer
                onRowClicked={onDriverClick}
                rowHeight={64}
                minTableHeight={selectedDriverId ? 248 : 320}
                strictMinTableHeight
                noResultsText="There are no drivers"
                highlightRow={props.pickupIds.length ? pickupAssignmentState.drivers.findIndex((driverObj: DriversResponseContentModel) => driverObj.driverId === selectedDriverId) : null}
                highlightRowClass="xgs-pickup-assignment__orders__selected"
                responsive
                customHeaderRowClass="xgs-pickup-assignment__drivers__table__header"
                ref={tableRef}
              />
            )}
            {status === PickupStatus.MISSED && (
              <div className="xgs-pickup-assignment__drivers__missed-pickup">
                {props.pickupIds.length > 1 && (
                  <>Driver cannot be assigned to&nbsp;the&nbsp; missed pickup group – please reschedule each pickup from a&nbsp;group of&nbsp;pickups</>
                )}
                {props.pickupIds.length === 1 && (
                  <>Driver cannot be assigned to&nbsp;the&nbsp;missed pickup – please reschedule the pickup</>
                )}
              </div>
            )}
          </div>
          {hasButton && (
            <div className="xgs-pickup-assignment__drivers__button">
              {status !== PickupStatus.MISSED && (
                <Button
                  theme={ButtonThemes.blue}
                  type="button"
                  onClick={() => setShowConfirmation(true)}
                >
                  {status === PickupStatus.UNASSIGNED ? "Assign" : "Unassign"}
                </Button>
              )}
              {(status === PickupStatus.MISSED) && (props.pickupIds.length === 1) && (
                <Button
                  theme={ButtonThemes.blue}
                  type="button"
                  onClick={onClickReschedule}
                  className="xgs-pickup-assignment__drivers__button__reschedule"
                >
                  Reschedule
                </Button>
              )}
            </div>
          )}
        </>
      )}
      <ConfirmationModal
        fitContent
        opened={showConfirmation}
        header=""
        confirmButtonText={status === PickupStatus.UNASSIGNED ? "Assign" : "Unassign"}
        spinner={pickupAssignmentState.requestStarted && pickupAssignmentState.requestCreator === "CHANGE_PICKUP_ASSIGNMENT"}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={changeAssignment}
      >
        <div className="xgs-pickup-assignment__popup">
          <div className="xgs-pickup-assignment__popup__image">
            <img src={truckBlue} alt="truck" />
          </div>
          <div className="xgs-pickup-assignment__popup__text xgs-pickup-assignment__popup__text--centered">
            Do you really want to {status === PickupStatus.UNASSIGNED ? "assign" : "unassign"} Pickup #{pickups.map(pickup => pickup.pickup.pickupNumber).join(', ')} {status === PickupStatus.UNASSIGNED ? "to" : "for"} {selectedDriverName}?
          </div>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default DriversList;
