import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import ProgressBar from "../../ui-components/progress-bar/progressBar";
import Collapsible from "../../ui-components/collapsible/collapsible";
import Tag from "../../ui-components/molecules/tag/tag";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import { cn } from "../../services/common/className";
import { TagColor } from "../../app/data/common/tagColor";
import {
  dockLoadingSelector,
  getDoorDetails
} from "../../slices/dock-loading/dockLoadingSlice";
import {
  DropStatus,
  DropStatusHumanFriendly,
  ProbillStatus,
  ProbillStatusHumanFriendly
} from "./constants";
import { showPrettyTime } from "./common";
import weightIcon from "../../images/weight_gray.svg";
import boxIcon from "../../images/box_gray.svg";
import paperIcon from "../../images/paper_gray.svg";
import truckIcon from "../../images/truck_gray.svg";
import paperBoxIcon from "../../images/paper-box_gray.svg";
import trailerIcon from "../../images/trailer_gray.svg";

export interface DoorDetailsProps {
  show: boolean;
  onClose: () => void;
  terminalNumber: number;
  doorNumber: string;
};

const DoorDetails: React.FC<DoorDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const dockLoadingState = useSelector(dockLoadingSelector);
  const [showMore, setShowMore] = useState<any[]>([]);

  const getDropStatusColor = (status: DropStatus) => {
    switch (status) {
      case DropStatus.DISPATCHED:
        return TagColor.GREEN;
      case DropStatus.LOADED:
        return TagColor.PURPLE;
      case DropStatus.IN_PROGRESS:
        return TagColor.BLUE;
      default:
        return TagColor.GREY
    };
  };

  const getProbillStatusColor = (status: ProbillStatus) => {
    switch (status) {
      case ProbillStatus.DISPATCHED:
        return TagColor.GREEN;
      case ProbillStatus.LOADED:
        return TagColor.PURPLE;
      case ProbillStatus.IN_PROGRESS:
        return TagColor.BLUE;
      default:
        return TagColor.GREY
    };
  };

  const setShowMoreItemDetails = (probill: number, item: string) => {
    let arr = [...showMore];
    arr.push({
      probill,
      item
    })
    setShowMore(arr);
  };

  const setHideMoreItemDetails = (probill: number, item: string) => {
    let arr = [...showMore];
    const i = arr.findIndex(obj => obj.probill === probill && obj.item === item);
    if (i !== -1) {
      arr.splice(i, 1)
      setShowMore(arr);
    }
  };

  const showMoreItemDetails = (probill: number, item: string) => {
    return !!showMore.find(obj => obj.probill === probill && obj.item === item);
  };

  useEffect(() => {
    if (!props.terminalNumber || !props.doorNumber || !props.show) return;
    dispatch(getDoorDetails(props.terminalNumber, props.doorNumber));
  }, [props.terminalNumber, props.doorNumber, props.show, dispatch]);

  return (
    <SlideOutSidebar
      header="Door Details"
      onClose={props.onClose}
      show={props.show}
      spinner={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOOR_DETAILS"}
    >
      <div className="xgs-dock-loading__door-details">
        <div className="xgs-dock-loading__header">Door Details: {props.doorNumber}</div>
        {!(dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOOR_DETAILS") && dockLoadingState.door && (
          <>
            {dockLoadingState.door.manifestDetails && (
              <div className="xgs-dock-loading__door-details__summary">
                <div className="xgs-dock-loading__door-details__values-line">
                  <div className="xgs-dock-loading__door-details__values-line__pair"><img src={trailerIcon} alt="Trailer" /><span>Trailer:</span> {dockLoadingState.door.manifestDetails.trailerNumber}</div>
                  <div className="xgs-dock-loading__door-details__values-line__pair"><img src={paperBoxIcon} alt="Manifest" /><span>Manifest:</span> {dockLoadingState.door.manifestDetails.manifestNumber}</div>
                  <div className="xgs-dock-loading__door-details__values-line__pair"><img src={truckIcon} alt="Carrier" /><span>Carrier #:</span> {dockLoadingState.door.manifestDetails.carrierNumber}</div>
                  <div className="xgs-dock-loading__door-details__values-line__pair"><img src={paperIcon} alt="Probills" /><span>Probills:</span> {dockLoadingState.door.manifestDetails.probillsCount}</div>
                  <div className="xgs-dock-loading__door-details__values-line__pair"><img src={boxIcon} alt="Items" /><span>Items:</span> {dockLoadingState.door.manifestDetails.itemsCount}</div>
                  <div className="xgs-dock-loading__door-details__values-line__pair"><img src={weightIcon} alt="Weight" /><span>Lbs:</span> {dockLoadingState.door.manifestDetails.weight}</div>
                </div>
                <ProgressBar
                  percentage={dockLoadingState.door.manifestDetails.loadingProgressPercentage}
                  additionalInfo={dockLoadingState.door.manifestDetails.loadingProgressTime && showPrettyTime(dockLoadingState.door.manifestDetails.loadingProgressTime)}
                  multicolor
                />
              </div>
            )}
            {dockLoadingState.door.drops && (
              <>
                <div className="xgs-dock-loading__header xgs-dock-loading__header--bold">Loading Manifest</div>
                {dockLoadingState.door.drops.map((drop: any) => (
                  <Collapsible
                    key={"drop-" + drop.dropNumber}
                    mix={"xgs-dock-loading__door-details__collapsible " + cn("xgs-dock-loading__door-details__collapsible")({
                      dispatched: drop.status === DropStatus.DISPATCHED,
                      loaded: drop.status === DropStatus.LOADED,
                      inprogress: drop.status === DropStatus.IN_PROGRESS
                    })}
                    header={(
                      <>
                        <div className="xgs-dock-loading__door-details__header-line">
                          <div>Drop #: {drop.dropNumber}</div>
                          <Tag mods={{color: getDropStatusColor(drop.status), uppercase: true}}>
                            { DropStatusHumanFriendly[drop.status] }
                          </Tag>
                          {drop.atRiskProbillsCount > 0 && (
                            <Tag mods={{color: TagColor.RED}}>AT RISK</Tag>
                          )}
                        </div>
                        <div className="xgs-dock-loading__door-details__values-line">
                          <div className="xgs-dock-loading__door-details__values-line__pair"><span>Terminal:</span> {drop.terminalAirportCode ? `${drop.terminalAirportCode} - ` : ""}{drop.terminalName} <span>{drop.terminalNumber || ""}</span>{!drop.terminalAirportCode && !drop.terminalName && !drop.terminalNumber && "N/A"}</div>
                          <div className="xgs-dock-loading__door-details__values-line__pair"><span>Arrival:</span> {drop.arrivedAt ? <>{drop.arrivedAt.toUiDateFormat()} <span>{drop.arrivedAt.toUiTimeShortFormat()}</span></> : "N/A"}</div>
                          <div className="xgs-dock-loading__door-details__values-line__pair"><span>Arrival user:</span> {drop.arrivedBy || "N/A"}</div>
                        </div>
                      </>
                    )}
                    iconType="normal"
                  >
                    {drop.probills.map((probill: any) => (
                      <Collapsible
                        key={"probill-" + probill.probillNumber}
                        mix="xgs-dock-loading__door-details__collapsible xgs-dock-loading__door-details__collapsible--probill"
                        header={(
                          <>
                            <div className="xgs-dock-loading__door-details__header-line">
                              <div>Probill: {probill.probillNumber}</div>
                              <Tag mods={{color: getProbillStatusColor(probill.loadingStatus), uppercase: true}}>
                                { ProbillStatusHumanFriendly[probill.loadingStatus] }
                              </Tag>
                              {probill.isAtRisk && (
                                <Tag mods={{color: TagColor.RED}}>AT RISK</Tag>
                              )}
                            </div>
                            <div className="xgs-dock-loading__door-details__values-line">
                              <div className="xgs-dock-loading__door-details__values-line__pair"><span>PO:</span> {probill.poNumber}</div>
                              <div className="xgs-dock-loading__door-details__values-line__pair"><span>BOL:</span> {probill.bolNumber}</div>
                              <div className="xgs-dock-loading__door-details__values-line__pair"><span>Shipper:</span> {probill.shipperName} <span>{probill.shipperNumber}</span></div>
                              <div className="xgs-dock-loading__door-details__values-line__pair"><span>Consignee:</span> {probill.consigneeName} <span>{probill.consigneeNumber}</span></div>
                              <div className="xgs-dock-loading__door-details__values-line__pair"><span>Cons. address:</span> {probill.consigneeAddress?.street}, {probill.consigneeAddress?.city}, {probill.consigneeAddress?.state}, {probill.consigneeAddress?.zipcode}</div>
                            </div>
                          </>
                        )}
                        iconType="normal"
                      >
                        <div className="xgs-dock-loading__door-details__items xgs-dock-loading__door-details__collapsible">
                          {probill.items.map((item: any) => (
                            <div className="xgs-dock-loading__door-details__items__details">
                              <div className="xgs-dock-loading__door-details__header-line">{item.itemDesc} — {item.itemNumberType} {item.itemNumber}</div>
                              <div className="xgs-dock-loading__door-details__values-line">
                                <div className="xgs-dock-loading__door-details__values-line__pair"><span>Location:</span> {item.location}</div>
                                <div className="xgs-dock-loading__door-details__values-line__pair"><span>Status:</span> {item.status}</div>
                                <div className="xgs-dock-loading__door-details__values-line__pair"><span>Last changed:</span> {item.updatedAt.toUiDateFormat()}</div>
                                <div className="xgs-dock-loading__door-details__values-line__pair"><span>Last changed by:</span> {item.updatedBy}</div>
                              </div>
                              {!showMoreItemDetails(probill.probillNumber, item.itemNumber) && (
                                <div
                                  className="xgs-dock-loading__door-details__items__details__show-more-link"
                                  onClick={() => setShowMoreItemDetails(probill.probillNumber, item.itemNumber)}
                                >
                                  Show more
                                </div>
                              )}
                              {showMoreItemDetails(probill.probillNumber, item.itemNumber) && (
                                <div className="xgs-dock-loading__door-details__items__details__more">
                                  <ul>
                                    <li>
                                      <div className="xgs-dock-loading__door-details__items__details__more__header">Item Identifiers</div>
                                      <div className="xgs-dock-loading__door-details__values-line">
                                        {!!item.sku && <div className="xgs-dock-loading__door-details__values-line__pair"><span>SKU:</span> {item.sku}</div>}
                                        {!!item.rollNumber && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Roll Number:</span> {item.rollNumber}</div>}
                                        {!!item.itemDesc && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Item Description:</span> {item.itemDesc}</div>}
                                        {!!item.itemNumber && <div className="xgs-dock-loading__door-details__values-line__pair"><span>{item.itemNumberType || "Item #"}:</span> {item.itemNumber}</div>}
                                        {!!item.commodityCode && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Commodity Code:</span> {item.commodityCode}</div>}
                                        {!!item.color && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Color:</span> {item.color}</div>}
                                        {!!item.style && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Style:</span> {item.style}</div>}
                                        {!!item.dyelot && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Dyelot:</span> {item.dyelot}</div>}
                                        {!!item.itemPoNumber && <div className="xgs-dock-loading__door-details__values-line__pair"><span>PO #:</span> {item.itemPoNumber}</div>}
                                        {!!item.serialNumber && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Serial #:</span> {item.serialNumber}</div>}
                                        {!!item.sidemark && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Sidemark:</span> {item.sidemark}</div>}
                                        {!!item.partNumber && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Part #:</span> {item.partNumber}</div>}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="xgs-dock-loading__door-details__items__details__more__header">Physical properties</div>
                                      <div className="xgs-dock-loading__door-details__values-line">
                                        {!!item.pieces && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Pieces:</span> {item.pieces}</div>}
                                        {!!item.squareYards && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Sq. Yards:</span> {item.squareYards}</div>}
                                        {!!item.weight && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Weight:</span> {item.weight}</div>}
                                        {!!item.length && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Length:</span> {item.length}</div>}
                                        {!!item.width && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Width:</span> {item.width}</div>}
                                        {!!item.height && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Height:</span> {item.height}</div>}
                                        {!!item.size && <div className="xgs-dock-loading__door-details__values-line__pair"><span>Size:</span> {item.size}</div>}
                                      </div>
                                    </li>
                                  </ul>
                                  <div
                                    className="xgs-dock-loading__door-details__items__details__show-more-link"
                                    onClick={() => setHideMoreItemDetails(probill.probillNumber, item.itemNumber)}
                                  >
                                    Show less
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </Collapsible>
                    ))}
                  </Collapsible>
                ))}
              </>
            )}
          </>
        )}
        {!(dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOOR_DETAILS") && dockLoadingState.door?.Error && (
          <XGSErrorMessage>{dockLoadingState.door.Error}</XGSErrorMessage>
        )}
      </div>
    </SlideOutSidebar>
  );
};

export default DoorDetails;