import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CommonService from "../../app/data/common/commonService";
import { initialCommonState } from "./CommonState";

const commonService = CommonService.getInstance();

export const commonSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {
    resetCommonState: (state) => initialCommonState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    setFreightClasses: (state, { payload }) => {
      state.freightClasses = payload.map((obj: any) => {
        return {
          value: obj.value,
          label: obj.description
        }
      })
    },
    setCommodityCodes: (state, { payload }) => {
      state.commodityCodes = payload.map((obj: any) => {
        return {
          value: obj.code,
          label: obj.code,
          subtitle: obj.description,
          valueForSearch: `${obj.code}|${obj.description}`,
        }
      })
    },
    storePickupDays: (state, { payload }) => {
      state.pickupDays = payload;
    }
  }
});

export const {
  resetCommonState,
  requestStarted,
  requestSucceed,
  requestFailed,
  setFreightClasses,
  setCommodityCodes,
  storePickupDays
} = commonSlice.actions;

export const commonSelector = (state: IState) => {
  return state.common;
};

export const getFreightClasses = (
  zip: string,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_FREIGHT_CLASSES"));
  const response = await commonService.getFreightClasses(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setFreightClasses(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    onFailed && onFailed();
  }
};

export const getPickupDays = (
  zip: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_SERVICE_CENTER_DAYS"));
  const response = await commonService.getServiceCenterDays(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storePickupDays(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getCommodityCodes = (  
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_COMMODITY_CODES"));
  const response = await commonService.getCC();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setCommodityCodes(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    onFailed && onFailed();
  }
};

const commonReducer = commonSlice.reducer;
export default commonReducer;
