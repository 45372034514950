import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  cancelPickup,
  pickupDetailsSelector
} from "../../../slices/pickup/pickupDetailsSlice";
import {
  PickupCancelModel,
  PickupCancelSchema,
  PickupShipperContacts,
} from "../../../app/data/pickup/models";
import PickupDetailsBlock from "./pickupDetailsBlock";
import checkIcon from "../../../images/check_gray.svg";
import crossRedIcon from "../../../images/cross_red.svg";
import emailIcon from "../../../images/letter_gray.svg";
import personIcon from "../../../images/person_blue.svg";
import phoneIcon from "../../../images/phone_gray.svg";

import "./pickupDetails.scss";

export interface PickupCancelProps {
  show: boolean;
  pickupId: string;
  pickupNumber: string;
  shipper?: PickupShipperContacts;
  type: "CUSTOMER" | "EMPLOYEE";
  onCancel: () => void;
  onClose: () => void;
};

let initialValues: PickupCancelModel = {
  notes: ""
};

const PickupCancel: React.FC<PickupCancelProps> = ({onClose, ...props}) => {
  const dispatch = useDispatch();
  const cancelFormRef = useRef<FormikProps<PickupCancelModel>>(null);
  const pickupDetailsState = useSelector(pickupDetailsSelector);

  const onCancelPickupConfirm = (data: PickupCancelModel) => {
    dispatch(cancelPickup(
      props.pickupId,
      data.notes,
      () => {
        toast.info("Pickup has been canceled!", { autoClose: 3000 });
        props.onCancel();
      }, (error) => {
        toast.error(error);
      }
    ));
  };

  useEffect(() => {
    cancelFormRef.current?.resetForm();
  }, [props.show]);

  return (
    <SlideOutSidebar
      header="Cancel Pickup"
      show={props.show}
      size="extrasmall"
      onClose={onClose}
      className="xgs-pickup-details__slideout"
    >
      <>
        <div className="xgs-pickup-details__slideout__inner-header">
          Cancel Pickup {props.pickupNumber}
        </div>
        <div className="xgs-pickup-details__slideout__text">
          <p>Do you really want to cancel this pickup?</p>
          {props.type === "EMPLOYEE" && (
            <p><strong>The customer will receive an email that the pickup has been canceled.</strong></p>
          )}
        </div>
        {props.shipper && (
          <div className="xgs-pickup-details__cancel__shipper-contacts">
            <PickupDetailsBlock
              header="Shipper Contact"
              content={[
                ...((props.shipper.firstName || props.shipper.lastName) ? [
                  {
                    label: <div className="xgs-pickup-details__value-icon"><img src={personIcon} alt="person" /></div>,
                    value: (props.shipper.firstName || "") + " " + (props.shipper.lastName || "")
                  }
                ] : []),
                ...(props.shipper.mobilePhone ? [
                  {
                    label: <div className="xgs-pickup-details__value-icon"><img src={phoneIcon} alt="phone" /></div>,
                    value: props.shipper.mobilePhone
                  }
                ] : []),
                ...((props.shipper.allowSms !== null) ? [
                  {
                    label: props.shipper.allowSms
                      ? <div className="xgs-pickup-details__value-icon"><img src={checkIcon} alt="sms yes" /></div>
                      : <div className="xgs-pickup-details__value-icon"><img src={crossRedIcon} alt="sms no" /></div>,
                    value: props.shipper.allowSms ? "SMS allowed" : "SMS not allowed"
                  }
                ] : []),
                {
                  label: <div className="xgs-pickup-details__value-icon"><img src={emailIcon} alt="email" /></div>,
                  value: props.shipper.email
                },
              ]}
              oneLine
            />
          </div>
        )}
        <Formik
          onSubmit={onCancelPickupConfirm}
          initialValues={initialValues}
          validationSchema={PickupCancelSchema}
          innerRef={cancelFormRef}
          enableReinitialize
        >
          {(props: FormikProps<PickupCancelModel>) => (
            <Form>
              <XGSFormTextarea
                name="notes"
                label="Notes/reason:"
                required={false}
                rows={3}
                counter={255}
                className="xgs-pickup-details__cancel__notes"
              />
              <div className="xgs-pickup-details__slideout__buttons">
                <Button
                  theme={ButtonThemes.gray}
                  onClick={onClose}
                  type="button"
                >
                  No
                </Button>
                <Button
                  theme={ButtonThemes.blue}
                  spinner={pickupDetailsState.requestStarted && pickupDetailsState.requestCreator === "CANCEL_PICKUP"}
                  type="submit"
                >
                  Yes, cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </SlideOutSidebar>
  );
};

export default PickupCancel;
