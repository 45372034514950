import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { PickupStatus, StopStatus } from "../common/route";
import { AddressModel, AddressWithCoordinates } from "../common/address";
import { ItemModel } from "../common/models";
import { Coordinates } from "../map/models";

export interface PickupShipperContacts {
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  allowSms?: boolean;
  email?: string;
};

export interface PickupShipperDetailsModel extends PickupShipperContacts {
  name: string;
  address: AddressModel;
}

export interface PickupShipperModel {
  teamId: string;
  bolNumber?: string;
  poNumber?: string;
  additionalReference?: string;
  specialInstructions: string | undefined;
  pickupDate: string;
  pickupReadyTime: string | undefined;
  pickupCloseTime: string | undefined;
  shipper: {
    id: string | null;
    accountNumber: number | null;
    name: string;
    address: AddressModel,
    contacts: PickupShipperContacts;
  }
};

export const PickupShipperSchema: Yup.ObjectSchema<PickupShipperModel> = Yup.object<PickupShipperModel>({
  teamId: Yup.string().required(validationMessages.required),
  bolNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(20, "The value must be no more than 20 characters"),
  poNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(12, "The value must be no more than 12 characters"),
  additionalReference: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(25, "The value must be no more than 25 characters"),
  specialInstructions: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
  pickupDate: Yup.string().required(validationMessages.required),
  pickupReadyTime: Yup.string().required(validationMessages.required),
  pickupCloseTime: Yup.string().required(validationMessages.required),
  shipper: Yup.object().shape({
    accountNumber: Yup.number().nullable().required(validationMessages.required),
    name: Yup.string().required(validationMessages.required).max(36, "The value must be no more than 36 characters"),
    address: Yup.object().shape({
      address1: Yup.string().required(validationMessages.required).max(256, "The value must be no more than 255 characters"),
      city: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
      postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
      state: Yup.string().required(validationMessages.required),
      additionalAddressLine: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
    }).defined(),
    contacts: Yup.object().shape({
      firstName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters"),
      lastName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters"),
      mobilePhone: Yup.string()
        .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid)
        .max(25, "The value must be no more than 25 characters"),
      allowSms: Yup.boolean(),
      email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required)
    }).defined()
  }).defined()
}).defined();

export interface PickupConsigneeModel {
  name: string;
  address: AddressModel,
  phone: string;
  email?: string;
};

export const PickupConsigneeSchema: Yup.ObjectSchema<PickupConsigneeModel> = Yup.object<PickupConsigneeModel>({
  name: Yup.string().required(validationMessages.required).max(36, "The value must be no more than 36 characters"),
  address: Yup.object().shape({
    address1: Yup.string().required(validationMessages.required).max(256, "The value must be no more than 255 characters"),
    city: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required),
    additionalAddressLine: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
  }).defined(),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid)
}).defined();

export const PickupItemSchema: Yup.ObjectSchema<ItemModel> = Yup.object({
  packageType: Yup.string().required(validationMessages.required),
  units: Yup
    .number()
    .integer("The value must be a valid amount without decimal values")
    .positive()
    .max(9999)
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  yards: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid)
    .when("packageType", {
      is: "ROLL",
      then: Yup
        .number()
        .positive()
        .max(99999)
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "1",
      then: Yup
        .number()
        .positive()
        .max(99999)
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "3",
      then: Yup
        .number()
        .positive()
        .max(99999)
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    }),
  weight: Yup
    .number()
    .integer("The value must be a weight without decimal values")
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  length: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid),
  width: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid),
  height: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid),
  ratingClass: Yup.string(),
  sidemark: Yup
    .string()
    .max(40, "The value must be no more than 40 characters"),
  notes: Yup.string().max(150, "The value must be no more than 150 characters")
}).defined();

export interface PickupRequestModel
  extends PickupShipperModel {
  consignee: PickupConsigneeModel;
  items: ItemModel[];
};

export interface ResponseScrollModel {
  pageSize: number;
  idsMap: {
    date: string;
  };
  lastIds: string;
};

export interface PickupsResponseContentModel {
  shipper: {
    name: string;
    address: AddressWithCoordinates;
  };
  requester?: {
    teamName?: string;
    customerNumber: number | null;
  };
  pickup: {
    date: string;
    readyTime: string;
    closeTime: string;
    pickupNumber: string;
    pickupId: string;
    driverId: number;
    pickupStatus: PickupStatus;
    groupId: string;
    single?: boolean;
    missed?: boolean;
    driverName?: string;
  };
  items: {
    count: number;
    weight: number;
  };
  status: PickupStatus;
  pickupFullData: {
    pickupRequest: {
      probillNumber: number;
      pickupDate: string;
      pickupReadyTime: string;
      pickupCloseTime: string;
      shipperName: string;
      shipperAddress: string;
      shipperCity: string;
      shipperState: string;
      shipperZip: string;
      shipperAddressAdditional: string;
      shipperFirstName: string;
      shipperLastName: string;
      shipperMobilePhone: string;
      shipperAllowSms: boolean;
      shipperEmail: string;
      consigneeName: string;
      consigneeAddress1: string;
      consigneeCity: string;
      consigneeState: string;
      consigneePostalCode: string;
      consigneeAddressAdditional: string;
      consigneePhone: string;
      consigneeEmail: string;
      dateCreated: string;
      userCreated: string;
      creatorType: string;
      userUpdated: string;
      dateUpdated: string;
      payorName: string;
      payorAddress: string;
      payorCity: string;
      payorState: string;
      payorZip: string;
      payorPhone: string;
      bolNumber: string;
      poNumber: string;
      additionalReference: string;
      specialInstructions: string;
      documents?: {
        storageUrl: string;
        type: string;
      }[];
      items: ItemModel[];
      manifestNumber: number;
      driverId: number;
    }
  }
};

export interface PickupsResponseModel {
  content: PickupsResponseContentModel[];
  scroll: ResponseScrollModel;
};

export interface PickupsGroupModel {
  id: string;
  shipper: {
    address: string;
  };
  pickup: {      
    date: string;
    driverId: number;
  }
  pickups: PickupsResponseContentModel[];  
};

export interface PickupUpdateResponseModel {
  id: string;
  pickupStatus: PickupStatus;
  driverId: number;
  groupId: string;
};

export interface StopAddress extends AddressModel, Coordinates {};

export interface AssignedPickup extends Coordinates {
  shipper: {
    address: AddressWithCoordinates,
  },  
  pickupStatus: PickupStatus,
  pickupNumber: string,
};

export interface DriverLocation extends Coordinates {
  formattedAddress: string;
};

export interface DriversResponseContentModel {
  driverId: number,
  driverName: string,
  trailerNumber: number,
  tractorNumber: number,
  manifestNumber: number,
  polyline: string,
  stops: {
    order: number,
    type: string,
    status: StopStatus | null,
    address: StopAddress,
  }[],
  pickups: AssignedPickup[],
  driverLocation: DriverLocation;
};

export interface DriversResponseModel {
  content: DriversResponseContentModel[];
  scroll: ResponseScrollModel;
};

export interface PickupAssignRequestModel {
  pickupId: string;
  driverId: number | null;
  status: string;
};

export interface PickupLogItem {
  actionPerformed: string;
  actionExecutedTime: string;
  userExecuted: string;
  driverId: number;
  driverName: string;
  pickupNumber: string;
};

export interface PickupRescheduleModel {
  pickupDate: string;
  pickupReadyTime: string | undefined;
  pickupCloseTime: string | undefined;
};

export const PickupRescheduleSchema: Yup.ObjectSchema<PickupRescheduleModel> = Yup.object({
  pickupDate: Yup.string().required(validationMessages.required),
  pickupReadyTime: Yup.string().required(validationMessages.required),
  pickupCloseTime: Yup.string().required(validationMessages.required)
}).defined();

export interface PickupCancelModel {
  notes: string;
};

export const PickupCancelSchema: Yup.ObjectSchema<PickupCancelModel> = Yup.object({
  notes: Yup.string().max(255, "The value must be no more than 255 characters")
}).defined();

export interface PickupCancelRequestModel extends PickupCancelModel {
  pickupId: string;
};


export interface PickupsLogFilterModel {
  actionPerformed?: string;
  dateFrom?: string;
  dateTo?: string;
  pickupNumber?: string;
  userEmail?: string;
};

export const PickupsLogFilterSchema: Yup.ObjectSchema<PickupsLogFilterModel> = Yup.object({
  actionPerformed: Yup.string(),
  dateFrom: Yup.string(),
  dateTo: Yup.string(),
  pickupNumber: Yup.string().max(10, "The value must be no more than 10 characters"),
  userEmail: Yup.string().max(128, "The value must be no more than 128 characters").email(validationMessages.notValid)
}).defined();

export interface PickupSummaryDto {
  serviceCenter: {
    terminalNumber: number;
    terminalName: string;
    airportCode: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: number;
    terminalRegion: string;
    terminalTimeZone: string;
    managerName: string;
    managerPhoneNumber: string;
    managerEmail: string;
    terminalEmail: string;
    hub: string;
    hubNumb: number | null;
    primary: boolean;
  };
  assignedSummary?: {
    terminalCode: number;
    totalPickups: number;
    totalItems: number;
    totalWeight: number;
    totalDrivers: number;
  };
  completedSummary?: {
    terminalCode: number;
    totalPickups: number;
    totalItems: number;
    totalWeight: number;
    totalDrivers: number;
  };
}

export interface PickupDto {
  date: string;
  readyTime: string;
  closeTime: string;
  pickupStatus: PickupStatus;
  pickupNumber: string;
  pickupId: string;
  driverId?: number;
  groupId?: string;
}

export interface PickupDetailsModel {
  pickupId: string;
  pickupNumber: string;
  probillNumber: number;  
  pickupStatus: PickupStatus;
  missed?: boolean;
  pickupDate: string;
  pickupReadyTime: string;
  pickupCloseTime: string;
  dateCreated: string;
  userCreated: string;
  creatorType: string;
  userUpdated: string;
  dateUpdated: string;
  bolNumber: string;
  poNumber: string;
  additionalReference: string;
  specialInstructions: string;
  manifestNumber: number;
  driverId?: number;
  driverName?: string;
  requester?: string;
  shipper: PickupShipperDetailsModel;
  consignee: PickupConsigneeModel;
  payor: {
    name: string;
    address: AddressModel;    
    phone: string;
  };
  documents?: {
    storageUrl: string;
    type: string;
  }[];
  items: ItemModel[];
};

export interface UpdatePickupModel {
  pickupId: string;
  status: PickupStatus;
  username?: string;
  manifestNumber?: number;
  driverId?: number;
}
