import { urlParams } from "../../../services/common/urlParams";
import api, { ApiResponse } from "./../api";
import { ChatMessageRequestModel, ChatMessageResponseModel } from "./models";

class ChatService {
  private static instance: ChatService;

  private constructor() {}

  static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  } 

  public sendMessage = async (message: ChatMessageRequestModel): Promise<ApiResponse<ChatMessageResponseModel>> => {
    return await api.post("/proxy/xgsai/chat/probill/query", message);    
  };

  public downloadChat = async (chatIds: string[]): Promise<ApiResponse<any>> => {
    return await api.get("proxy/xgsai/chat/probill/export/pdf", { params: urlParams({ chatIds }) });
  }
}

export default ChatService;
