import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import Modal from "react-modal";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import { TagColor } from "../../../app/data/common/tagColor";
import { modalStyle } from "../../../app/data/common/modalStyle";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Tag from "../../../ui-components/molecules/tag/tag";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import {
  podRecognitionSelector,
  getPodRecognitionRecords
} from "../../../slices/documents/podRecognitionSlice";
import { userSelector } from "../../../slices/user/userSlice";
import {
  PodRecognitionFilterModel,
  PodRecognitionFilterSchema
} from "../../../app/data/documents/models";
import "./podRecognition.scss";

const initialFilterValues: PodRecognitionFilterModel = {
  probill: "",
  from: undefined,
  to: undefined,
  agent: "",
  success: undefined
};

export const PodRecognitionStatuses = [
  {
    label: "Error",
    value: false
  },
  {
    label: "Recognized",
    value: true
  }
];

let overriddenModalStyle = modalStyle;
overriddenModalStyle.content.padding = "24px 24px 24px 24px";

const PodRecognition: React.FC<{}> = (props) => {
  const podRecognitionState = useSelector(podRecognitionSelector);
  const userState = useSelector(userSelector);
  const filterFormRef = useRef<FormikProps<PodRecognitionFilterModel>>(null);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<PodRecognitionFilterModel>(initialFilterValues);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupText, setPopupText] = useState<string>("");

  const columns = [
    {
      width: 168,
      minWidth: 168,
      Header: "Date & Time",
      accessor: "dateCreated",
      Cell: (cellProps: any) => (
        <div>
          <div>
            {cellProps.value ? cellProps.value.toUiDateFormat() : ""}
            <span className="text-light xgs-table__cell-subitem">{moment(cellProps.value, "hh:mm:ss").format("h:mm A")}</span>
          </div>
        </div>
      )
    },
    {
      width: 112,
      minWidth: 112,
      Header: "Probill #",
      accessor: "probillNumber",
      Cell: ({ row }: any) => (
        <>
          {row.original.probillNumber ? (
            <>
              {row.original.isMaster ? (
                <div className="xgs-documents__pod-recognition__masterbill">
                  <div className="xgs-documents__pod-recognition__masterbill__header">Masterbill:</div>
                  <div className="xgs-documents__pod-recognition__masterbill__number">{row.original.probillNumber}</div>
                </div>
              ) : (
                <Link
                  className="blue-link"
                  to={`${userState.activeTerminal?.id ? "/" + userState.activeTerminal.id : ""}/shipments/${row.original.probillNumber}`}
                >
                  {row.original.probillNumber}
                </Link>
              )}
            </>
          ) : (<strong className="text-light">N/A</strong>)}
        </>
      )
    },
    {
      width: 120,
      minWidth: 120,
      Header: "Status",
      accessor: "successRecognition",
      Cell: (cellProps: any) => (
        <Tag mods={{color: cellProps.value ? TagColor.GREEN : TagColor.RED, uppercase: true}}>
          { PodRecognitionStatuses.find(o => o.value === cellProps.value).label }
        </Tag>
      )
    },
    {
      width: 144,
      minWidth: 144,
      Header: "Error Message",
      accessor: "errorMessage",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value && (
            <span className="blue-link" onClick={() => showErrorMessage(cellProps.value)}>Show message</span>
          )}
        </>
      )
    },
    {
      width: 512,
      minWidth: 240,
      Header: "Agent Email",
      accessor: "fromAgent"
    },
    {
      width: 160,
      minWidth: 160,
      Header: "Attachment",
      accessor: "blobURI",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value &&
            <DownloadButton
              title="Download File"
              onClick={() => window.open(cellProps.value, "_blank")}
            />
          }
          {!cellProps.value && <strong className="text-light">N/A</strong>}
        </>
      )
    }
  ];

  const onSubmitFilter = (values: PodRecognitionFilterModel) => {
    let filterValues = values;
    if (filterValues.from) filterValues.from = filterValues.from.toApiDateFormat();
    if (filterValues.to) filterValues.to = filterValues.to.toApiDateFormat();
    setFilter(values);
    dispatch(getPodRecognitionRecords(values));
  };

  const onListInfiniteScroll = () => {
    if (podRecognitionState.recordsRequest?.last) return;
    dispatch(getPodRecognitionRecords(filter, podRecognitionState.recordsRequest ? podRecognitionState.recordsRequest.page + 1 : undefined));
  };

  const getStatusesOptions = () => {
    return (
      PodRecognitionStatuses.map(
        (status): XGSSelectOption => ({
          label: status.label,
          value: status.value,
        })
      ) || []
    );
  };

  const showErrorMessage = (err: string) => {
    setPopupText(err);
    setShowPopup(true);
  };

  useEffect(() => {
    dispatch(getPodRecognitionRecords(initialFilterValues));
  }, [dispatch]);

  return (   
      <ContentContainer
        className="xgs-list xgs-list--full-width"
        mods={{ "full-width": true }}
        titleComponent={
          <ContentContainerToolbar
            title="POD Recognition"
            mods={{ "full-width": true }}
          />
        }
      >
        <div className="xgs-documents__pod-recognition">
          <div className="xgs-list__controls">
            <div className="xgs-list__controls__search">
              <Formik
                initialValues={initialFilterValues}
                onSubmit={onSubmitFilter}
                innerRef={filterFormRef}
                validationSchema={PodRecognitionFilterSchema}
              >
                {(props: FormikProps<PodRecognitionFilterModel>) => (
                  <Form className="xgs-list__controls__search-form">
                    <XGSFormInput
                      type="text"
                      name="probill"
                      label="Probill #"
                      labelMode={LabelModes.column}
                      className="xgs-item-form__field"
                    />
                    <LabeledDateRangeInput
                      label="Date Range"
                      labelMode={LabelModes.column}
                      className="xgs-item-form__field xgs-list__controls__date-range"
                      start={props.values.from}
                      end={props.values.to}
                      maxDate={new Date(moment().format("MM/DD/YYYY"))}
                      onStartChange={(v) => {
                        props.setFieldValue("from", v || undefined);
                        props.submitForm()
                      }}
                      onEndChange={(v) => {
                        props.setFieldValue("to", v || undefined);
                        props.submitForm()
                      }}
                    />
                    <XGSFormInput
                      type="text"
                      name="agent"
                      label="Agent Email"
                      labelMode={LabelModes.column}
                      className="xgs-item-form__field"
                    />
                    <XGSFormSelect
                      name="success"
                      label="Status"
                      labelMode={LabelModes.column}
                      className="xgs-item-form__field xgs-item-form__field_lg"
                      options={getStatusesOptions()}
                      isClearable
                      controlled={true}
                      onValueChange={() => props.submitForm()}
                    />
                    <Button
                      type="submit"
                      theme={ButtonThemes.blue}
                      className=""
                      spinner={false}
                    >
                      Search
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {(podRecognitionState.requestFailed && podRecognitionState.requestCreator === "GET_RECORDS") && (
            <XGSErrorMessage>{podRecognitionState.requestError}</XGSErrorMessage>
          )}
          {!(podRecognitionState.requestFailed && podRecognitionState.requestCreator === "GET_RECORDS") && (
            <Table
              isLoading={podRecognitionState.requestStarted && podRecognitionState.requestCreator === "GET_RECORDS"}
              keepTableOnLoading
              columns={columns}
              data={podRecognitionState.records}
              rowHeight={56}
              minTableHeight={240}
              noResultsText="There are no entries"
              infiniteScroll
              infiniteScrollLoading={podRecognitionState.requestStarted && podRecognitionState.requestCreator === "GET_RECORDS_PORTION"}
              infiniteScrollHasNext={!podRecognitionState.recordsRequest?.last}
              onInfiniteScroll={onListInfiniteScroll}
              responsive
            />
          )}
        </div>
        <Modal
          isOpen={showPopup}
          style={overriddenModalStyle}
        >
          <>
            <div className="xgs-modal__header xgs-documents__pod-recognition__popup__header">
              Error message
            </div>
            <div className="xgs-modal__content xgs-documents__pod-recognition__popup__content">
              {popupText}
            </div>
            <div className="xgs-modal__buttons xgs-documents__pod-recognition__popup__buttons">
              <Button
                type="button"
                theme={ButtonThemes.blueInverted}
                className="xgs-modal__button"
                onClick={() => setShowPopup(false)}
              >
                Close
              </Button>
            </div>
          </>
        </Modal>
      </ContentContainer>
    );
};

export default PodRecognition;