import { CSSProperties } from "react";
import { Props as ReactSelectProps, Styles } from "react-select";

const customXGSSelectStyles: Partial<Styles> = {
  input: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    height: "auto",
    margin: 0,
    marginTop: -3,
    padding: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  indicatorsContainer: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    height: 30,
    minHeight: 30,
    padding: 4,
  }),
  option: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    cursor: "pointer",
  }),
  control: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    height: 30,
    minHeight: 30,
  }),
  singleValue: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    top: "46%",
  }),
  placeholder: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    color: "#4F5F72",
    opacity: 0.72,
  }),
  dropdownIndicator: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    cursor: "pointer"
  }),
  clearIndicator: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    cursor: "pointer"
  }),
  multiValue: (provided: CSSProperties, state: ReactSelectProps) => {
    return state.data.isFixed ? { ...provided, backgroundColor: "#eeeeee" } : provided;
  },
  multiValueLabel: (provided: CSSProperties, state: ReactSelectProps) => {
    return state.data.isFixed
      ? { ...provided, color: "#999999", paddingLeft: 10, paddingRight: 0 }
      : provided;
  },
  multiValueRemove: (provided: CSSProperties, state: ReactSelectProps) => {
    return state.data.isFixed
      ? { ...provided, display: "none" }
      : { ...provided, cursor: "pointer" };
  }
};

export default customXGSSelectStyles;