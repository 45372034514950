import { PickupDto, PickupSummaryDto } from "../../app/data/pickup/models";

export interface InboundPickupsState {
  pickups: PickupDto[];
  summaries: PickupSummaryDto[];
  loadingSummaries: boolean;
  loadingSummariedError: string | null;
  loadingPickups: boolean;
  loadingPickupsError: string | null;
  infiniteLoading: boolean,
  infiniteLoadingError: string | null,
  infiniteLoadingHasNext: boolean;
  totalPages: number;
  totalElements: number;
}

export const initialInboundPickupsState: InboundPickupsState = {
  pickups: [],
  summaries: [],
  loadingSummaries: false,
  loadingSummariedError: null,
  infiniteLoading: false,
  infiniteLoadingError: null,
  infiniteLoadingHasNext: true,
  totalPages: 0,
  totalElements: 0,
  loadingPickups: false,
  loadingPickupsError: null,

};