import React, { ReactElement, useState } from 'react';
import { ReactComponent as ExpandIcon } from "../../images/expand.svg";
import { ReactComponent as ArrowIcon } from "../../images/arrow-down_gray.svg";
import { cn } from '../../services/common/className';

import "./collapsible.scss";

export interface CollapsibleProps {
  header: ReactElement;
  mix?: string | string[];
  mods?: {};
  open?: boolean;
  onClick?: () => void;
  displayLabel?: boolean;
  iconType?: "normal" | "withline";
}

const Collapsible: React.FC<CollapsibleProps> = ({ header, children, mix, mods, open: openByParent, onClick, displayLabel, iconType }) => {
  const [open, setOpen] = useState(false);
  const isOpen = openByParent !== undefined ? openByParent : open;
  const setIsOpen = openByParent !== undefined ? onClick : () => { setOpen(state => !state) };

  return (
    <li className={cn("collapsible")({ open: isOpen, ...mods }, mix)}>
      <div className="collapsible__header">
        <div className="collapsible__header-content">
          {header}
        </div>
        <div
          className="collapsible__button"
          onClick={setIsOpen}
        >
          {displayLabel ? <>
            {<span className="collapsible__button-label">{isOpen ? "Collapse" : "Expand"}</span>}
          </> : null}
          {(!iconType || iconType === "withline") && <ExpandIcon className="collapsible__button-icon" />}
          {(iconType === "normal") && <ArrowIcon className="collapsible__button-icon" />}
        </div>
      </div>
      <div className="collapsible__content">
        {children}
      </div>
    </li>
  )
};

export default Collapsible;