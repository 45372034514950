import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import "../../ui-components/tabs/tabs.scss";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Loading from "../../ui-components/loading/loading";
import { deleteTeam, teamsSelector } from "../../slices/company/companySlice";
import TeamAccountState from "../../slices/customers/teams/TeamAccountState";
import {
  resetErrors,
  teamAccountSelector,
  updateTeam
} from "../../slices/customers/teams/teamAccountSlice";
import { updateTeamNameLocally } from "../../slices/company/companySlice";
import TeamAccounts from "./teamAccounts"
import TeamShippers from "./teamShippers"
import TeamUsers from "./teamUsers"
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";
import ConfirmationModal from "../../ui-components/confirmation-modal/confirmationModal";
import "./company.scss";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import TeamsState from "../../slices/company/CompanyState";
import { UserUtils } from "../../app/data/user/userUtils";
import { userSelector } from "../../slices/user/userSlice";

export interface TeamDetailsProps {
  id: string | undefined;
  type: "CUSTOMER" | "AGENT";
  show: boolean;
  onClose: () => void;
}

const TeamDetails: React.FC<TeamDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const teamsState: TeamsState = useSelector(teamsSelector);
  const teamAccountState: TeamAccountState = useSelector(teamAccountSelector);
  const [teamName, setTeamName] = useState("");
  const [editTeamNameConfirm, setEditTeamNameConfirm] = useState(false);
  const [deleteTeamConfirm, setDeleteTeamConfirm] = useState(false);
  const userState = useSelector(userSelector);
  const onTeamNameInput = () => {
    const el = document.getElementById("xgs-teams__details__header--js") as HTMLInputElement;
    if (el.innerText.length > 30) {
      const cuttedName = el.innerText.slice(0, 30);
      setTeamName(cuttedName);
      el.innerText = cuttedName;
    }
  };

  const onTeamNameKeyPress = (e: any) => {
    if (e.charCode === 13) {
      e.preventDefault();
      const el = document.getElementById("xgs-teams__details__header--js") as HTMLElement;
      el.blur();
      onTeamNameBlur();
    }
  };

  const onTeamNameBlur = () => {
    if (!props.id) return;
    const teamOriginalName = teamsState.teams.find((team: any) => team.id === props.id)?.name;
    const el = document.getElementById("xgs-teams__details__header--js") as HTMLElement;
    if (el.textContent === teamOriginalName) return;
    if (!el.textContent || el.textContent?.length === 0 || !/\S/.test(el.textContent)) {
      // return to original name
      setTeamName(teamOriginalName!);
      el.innerText = teamOriginalName!;
    } else {
      // open confirmation modal
      setEditTeamNameConfirm(true);
    }
  };

  const onCancelEditTeamName = () => {
    setEditTeamNameConfirm(false);
    const teamOriginalName = teamsState.teams.find((team: any) => team.id === props.id)?.name;
    const el = document.getElementById("xgs-teams__details__header--js") as HTMLElement;

    setTeamName(teamOriginalName!);
    el.innerText = teamOriginalName!;
  }

  const onConfirmEditTeamName = () => {
    dispatch(updateTeam({
      teamId: props.id ?? '',
      name: document.getElementById("xgs-teams__details__header--js")?.textContent ?? ''
    }, () => {
      // update name in the teams list without reload of entire list
      dispatch(updateTeamNameLocally({
        teamId: props.id,
        name: document.getElementById("xgs-teams__details__header--js")?.textContent ?? ''
      }));
    }));
    setEditTeamNameConfirm(false);
  }

  const onConfirmDeleteTeam = () => {
    dispatch(deleteTeam(
      props.id!, 
      () => {
        setDeleteTeamConfirm(false);
        props.onClose();
        toast.info("The  " + (currentTeam?.agent ? "Agent" : "Customer") + " was deleted!")
      } ));
  }

  const onCancelDeleteTeam = () => {
    setDeleteTeamConfirm(false);
  }

  useEffect(() => {
    setTeamName(teamsState.teams.find((team: any) => team.id === props.id)?.name!);
  }, [teamsState.teams, props.id]);

  useEffect(() => {
    if (!teamAccountState.request_was_failed) return;
    toast.error(teamAccountState.request_fail_reason || ERROR_MESSAGES.COMMON);
    dispatch(resetErrors());
  }, [dispatch, teamAccountState.request_was_failed, teamAccountState.request_fail_reason]);

  let currentTeam = teamsState.teams.find((team: any) => team.id === props.id);

  let isDeletingTeam = teamsState.team_delete_was_started;

  let showTeamDeleteButton = UserUtils.isXGSAdministrator(userState.profile) && !!currentTeam && !currentTeam?.accounts && !currentTeam?.users;

  return (
    <SlideOutSidebar
      header={`${props.type === "CUSTOMER" ? "Customer" : ""}${props.type === "AGENT" ? "Agent" : ""} information`}
      onClose={props.onClose}
      show={props.show}
      className="xgs-teams__details"
    >
      <>
        <div className="xgs-teams__details__header-container">
          <div
            className={
              "xgs-teams__details__header " + 
              (!showTeamDeleteButton ? "xgs-teams__details__header--full-width" : "")
            }
            onInput={onTeamNameInput}
            onBlur={onTeamNameBlur}
            onKeyPress={onTeamNameKeyPress}
            id="xgs-teams__details__header--js"
            contentEditable
            suppressContentEditableWarning={true}
          >{teamName}</div>
          <Loading 
            className="xgs-teams__details__loading"
            isLoading={teamAccountState.request_was_started} 
          />
          {showTeamDeleteButton && (
            <Button
              onClick={() => setDeleteTeamConfirm(true)}
              className="xgs-teams__details__delete" 
              theme={ButtonThemes.red}
              spinner={isDeletingTeam}
            >
              <span className="xgs-teams__details__delete__large">Delete {currentTeam?.agent ? "Agent" : "Customer"}</span>
              <span className="xgs-teams__details__delete__small">Delete</span>
            </Button>
          )}
        </div>
        <Tabs>
          <TabList>
            <Tab>Accounts</Tab>
            <Tab disabled={currentTeam?.agent}>Shippers</Tab>
            <Tab>Users</Tab>
          </TabList>
          <TabPanel>
            {props.id && <TeamAccounts id={props.id} type={props.type} />}
          </TabPanel>
          <TabPanel>
            {props.id && <TeamShippers id={props.id} />}
          </TabPanel>
          <TabPanel>
            {props.id && <TeamUsers agent={currentTeam?.agent} id={props.id} />}
          </TabPanel>
        </Tabs>

        <ConfirmationModal
          opened={editTeamNameConfirm}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onCancel={() => onCancelEditTeamName()}
          onConfirm={() => onConfirmEditTeamName()}
        >
          <span className="--team-update-modal">Confirm {props.type === "CUSTOMER" ? "customer" : ""}{props.type === "AGENT" ? "agent" : ""} name update?</span>
        </ConfirmationModal>
        <ConfirmationModal 
          opened={deleteTeamConfirm}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onCancel={() => onCancelDeleteTeam()}
          onConfirm={() => onConfirmDeleteTeam()}
          spinner={isDeletingTeam}
        >
            <span className="--team-update-modal">Are you sure you want to delete {currentTeam?.agent ? "agent" : "customer"}: {teamName}?</span>
        </ConfirmationModal>
      </>
    </SlideOutSidebar>
  );
};

export default TeamDetails;
