import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import * as SmartySDK from "smartystreets-javascript-sdk";
import Modal from "react-modal";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import FormAddressSelector from "../../../../ui-components/form/address/formAddressSelector";
import {
  BolConsigneeModel,
  BolConsigneeSchema
} from "../../../../app/data/bol/models";
import { StepProps } from "../../../../app/data/common/models";
import BolState from "../../../../slices/bol/BolState";
import {
  bolSelector,
  checkConsignee,
  getConsignees,
  setBolConsignee,
} from "../../../../slices/bol/bolSlice";
import { modalStyle } from "../../../../app/data/common/modalStyle";
import { ERROR_MESSAGES } from "../../../../app/data/common/errorMessages";
import "../../bol.scss";
import { getCommodityCodes } from "../../../../slices/common/commonSlice";

const smartyCore = SmartySDK.core;
const smartyLookup = SmartySDK.usAutocompletePro.Lookup;
const credentials = new smartyCore.SharedCredentials(process.env.REACT_APP_SMARTY_KEY || "");
const clientBuilder = new smartyCore.ClientBuilder(credentials).withLicenses([process.env.REACT_APP_SMARTY_LICENSE || ""]);
const client = clientBuilder.buildUsAutocompleteProClient();

let initialValues: BolConsigneeModel = {
  name: "",
  address: {
    address1: "",
    city: "",
    postalCode: "",
    state: "",
    additionalAddressLine: "",
  },
  phone: ""
};

const BolConsigneeStep: React.FC<StepProps> = (props) => {
  const { previous, next, push } = props;
  const bolState: BolState = useSelector(bolSelector);
  const dispatch = useDispatch();
  const consigneeFormRef = useRef<any>(null);
  const [consigneeFormValue, setConsigneeFormValue] = useState<XGSSelectOption | null>();
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [addressLookupStarted, setAddressLookupStarted] = useState<boolean>(false);
  const [phoneFieldValue, setPhoneFieldValue] = useState<string>("");
  const [postalCodeServiceable, setPostalCodeServiceable] = useState<boolean>(false);
  const [showConsigneeWarning, setShowConsigneeWarning] = useState<boolean>(false);
  const [showZipCheckError, setShowZipCheckError] = useState<boolean>(false);

  const consignees = () => {
    let options: XGSSelectOption[] = [];
    if (bolState.consignees && bolState.consignees.length > 0) {
      for (let i = 0; i < bolState.consignees.length; i++) {        
        options.push({
          label: bolState.consignees[i].name,
          subtitle: `${bolState.consignees[i].address.city}, ${bolState.consignees[i].address.state}`,
          value: `${i}`,
          valueForSearch: `${bolState.consignees[i].name}|${bolState.consignees[i].address.city}|${bolState.consignees[i].address.state}`
        });
      }
    }
    return options;
  };

  const onConsigneeSelect = (v: XGSSelectOption | null | undefined) => {
    setConsigneeFormValue(v);
    if (!v?.value || v.value === "-1" || !bolState.consignees || bolState.consignees.length === 0) return;
    let index = Number(v.value);
    let consignee = bolState.consignees[index];
    consigneeFormRef.current?.resetForm();
    consigneeFormRef.current?.setFieldValue("name", consignee.name);
    consigneeFormRef.current?.setFieldValue("phone", consignee.phone.formatPhone());
    setPhoneFieldValue(consignee.phone.formatPhone());
    locationSearch(`${consignee.address.address1}, ${consignee.address.city}, ${consignee.address.state}, ${consignee.address.postalCode}`, (res) => {
      setAddressLookupValue(res.length > 0 ? res[0] : null);
      consigneeFormRef.current?.setFieldValue("address.address1", res.length > 0 ? res[0]?.value.address : "");
      consigneeFormRef.current?.setFieldValue("address.city", res.length > 0 ? res[0]?.value.city : "");
      consigneeFormRef.current?.setFieldValue("address.postalCode", res.length > 0 ? res[0]?.value.zip : "");
      consigneeFormRef.current?.setFieldValue("address.state", res.length > 0 ? res[0]?.value.state : "");
      consigneeFormRef.current?.setFieldValue("address.additionalAddressLine", res.length > 0 ? consignee.address.additionalAddressLine : "");
      setTimeout(() => {
        consigneeFormRef.current?.validateForm();
        checkZip();
      }, 50);
    });
  };

  const locationSearch = (inputValue: string, callback: (params: any) => void) => {
    if (!inputValue) return callback([]);
    setAddressLookupStarted(true);
    let lookup = new smartyLookup(inputValue);
    client.send(lookup).then((response) => {
      callback(response.result.map(item => {
        return {
          label: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
          value: {
            address: item.streetLine,
            city: item.city,
            state: item.state,
            zip: item.zipcode
          }
        };
      }));
      setAddressLookupStarted(false);
    }).catch((err) => {
      console.log("err:", err);
      setAddressLookupStarted(false);
    });
  };

  const onClickBack = (data: BolConsigneeModel) => {
    dispatch(setBolConsignee(data));
    previous && previous();
  };

  const onClickNext = (data: BolConsigneeModel) => {
    dispatch(setBolConsignee(data));
    dispatch(getCommodityCodes());
    if (bolState.payType === "THIRD_PARTY") {
      next && next();
    } else {
      push && push("bol-items-step");
    }
  };

  let checkZip = (zip?: string) => {
    setPostalCodeServiceable(false);
    setShowZipCheckError(false);
    let postalCode = zip || consigneeFormRef.current?.values.address.postalCode;
    if (!/^\d{5}$/.test(postalCode)) return;
    dispatch(checkConsignee(postalCode, () => {
      setPostalCodeServiceable(true);
    }, () => {      
      setShowConsigneeWarning(true);
      setShowZipCheckError(true);
    }, () => {
      toast.error(bolState.requestError || "Error", { autoClose: 5000 });
    }));
    // eslint-disable-next-line
  };
  checkZip = debounce(checkZip, 800);

  useEffect(() => {
    initialValues = {
      name: bolState.consignee.name,
      address: {
        address1: bolState.consignee.address.address1,
        city: bolState.consignee.address.city,
        postalCode: bolState.consignee.address.postalCode,
        state: bolState.consignee.address.state,
        additionalAddressLine: bolState.consignee.address.additionalAddressLine,
      },
      phone: bolState.consignee.phone
    }
    consigneeFormRef.current?.setFieldValue("name", bolState.consignee.name);
    if (bolState.consignee.address.address1) {
      setAddressLookupValue({
        label: `${bolState.consignee.address.address1}, ${bolState.consignee.address.city}, ${bolState.consignee.address.state}, ${bolState.consignee.address.postalCode}`,
        value: {
          address: bolState.consignee.address.address1,
          city: bolState.consignee.address.city,
          state: bolState.consignee.address.state,
          zip: bolState.consignee.address.postalCode
        }
      });
    }
    consigneeFormRef.current?.setFieldValue("address.address1", bolState.consignee.address.address1);
    consigneeFormRef.current?.setFieldValue("address.city", bolState.consignee.address.city);
    consigneeFormRef.current?.setFieldValue("address.state", bolState.consignee.address.state);
    consigneeFormRef.current?.setFieldValue("address.postalCode", bolState.consignee.address.postalCode);
    consigneeFormRef.current?.setFieldValue("address.additionalAddressLine", bolState.consignee.address.additionalAddressLine);
    consigneeFormRef.current?.setFieldValue("phone", bolState.consignee.phone);
    setPhoneFieldValue(bolState.consignee.phone);
  }, [bolState.consignee]);

  useEffect(() => {
    if (bolState.consignees) return;
    dispatch(getConsignees());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPostalCodeServiceable(bolState.consigneeCheckResult);
  }, [bolState.consigneeCheckResult, bolState.consignee.address.postalCode, bolState.consigneeZipChecked]);

  return (
    <div className="xgs-bol__consignee">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={BolConsigneeSchema}
        innerRef={consigneeFormRef}
        validateOnMount
      >
        {(props: FormikProps<BolConsigneeModel>) => (
          <Form>
            <div className="xgs-bol__section">Consignee Information</div>
            <LabeledSelectInput
              async
              name="consignee"
              label="Load Previous Consignee:"
              placeholder="Select..."
              labelMode={LabelModes.column}
              value={consigneeFormValue}
              onValueChange={onConsigneeSelect}
              options={consignees()}
              required={false}
              requiredAsteriskDisabled={true}
              formik={true}
              className="xgs-bol__field xgs-bol__field--gray-bg"
              isLoading={bolState.requestStarted && bolState.requestCreator === "consignees"}
            />
            <XGSFormInput
              type="text"
              name="name"
              label="Consignee:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-bol__field"
            />
            <div className="xgs-bol__address-info">
              <FormAddressSelector
                editable={true}
                required
                addressLookupValue={addressLookupValue}
                setAddressLookupValue={(v) => setAddressLookupValue(v)}
                onZipChange={(zip) => {
                  if (/^\d{5}$/.test(zip)) {
                    checkZip(zip);
                  }
                }}
                isLoading={addressLookupStarted || (bolState.requestStarted && bolState.requestCreator === "CHECK_CONSIGNEE_ZIP")}
                error={showZipCheckError
                  ? ERROR_MESSAGES.ZIP_SERVICE_UNAVAILABLE
                  : ""}
              />              
            </div>
            <XGSFormPhoneInput
              name="phone"
              label="Phone:"
              labelMode={LabelModes.column}
              onValueChange={(value) => {
                props.setFieldValue("phone", value);
                setPhoneFieldValue(value);
              }}
              onBlur={props.handleBlur}
              value={phoneFieldValue}
              required={true}
              requiredAsteriskDisabled={false}
              className="xgs-bol__field xgs-bol__field--short"
            />
            <div className="xgs-form__field__notes">
              <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
              Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
            </div>
            <div className="xgs-bol__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                className="xgs-bol__nav-button"
                onClick={() => onClickBack(props.values)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.name || !postalCodeServiceable}
                className="xgs-bol__nav-button">
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={showConsigneeWarning}
        style={modalStyle}
      >
        <>
          <div className="xgs-modal__content">
            <div className="xgs-bol__consignee__warning">
              <XGSIcon
                icon={XGSIcons.faExclamationCircle}
                className="xgs-bol__consignee__warning__icon"
              />
              <div>
                Unfortunately, we do not have network coverage at the zip code you provided so your BOL request cannot be processed through our customer portal.<br /><br />
                Please contact Customer Service at<br />844-XGS-SHIP&nbsp;(947-7447)
              </div>
            </div>
          </div>
          <div className="xgs-modal__buttons xgs-bol__return__modal__buttons">
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-modal__button"
              onClick={() => setShowConsigneeWarning(false)}
            >
              Close
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default BolConsigneeStep;
