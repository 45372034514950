import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "@googlemaps/react-wrapper";
import XGSMap from "../../../ui-components/map/map";
import XGSCustomMarker from "../../../ui-components/map/custom-marker/customMarker";
import Loading from "../../../ui-components/loading/loading";
import { pickupAssignmentSelector } from "../../../slices/pickup/pickupAssignmentSlice";
import { DriversResponseContentModel } from "../../../app/data/pickup/models";
import { getStopFillColor, getStopLabelProps } from "../../../services/map";
import { StopType } from "../../../app/data/common/route";
import XGSMarkerTooltip from "../../../ui-components/map/marker-tooltip/markerTooltip";
import StopMarkerInfo from "../../../ui-components/map/stop-marker-info/stopMarkerInfo";
import { getCoordinates } from "../../../app/data/map/utils";
import DriverRoute from "./driverRoute";
import { formatAddress } from "../../../services/common/address";
import { getPickupGroupStatus, getPickupsByIds } from "../../../services/pickups";
import { Coordinates } from "../../../app/data/map/models";

import "../../../ui-components/map/map.scss";

export interface PickupMapProps {
  driverId?: number | null;
  pickupIds: string[];
  showAllRoutes?: boolean;
  onDriverSelect: (id: number) => void;
};

const PickupMap: React.FC<PickupMapProps> = (props) => {
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";
  const [driver, setDriver] = useState<DriversResponseContentModel | undefined>(undefined);  

  const pickup = useMemo(() => {
    const pickups = getPickupsByIds(pickupAssignmentState.pickups, props.pickupIds);

    const pickupStatus = pickups.length ? getPickupGroupStatus(pickups) : null;

    return pickups[0] && {
      ...pickups[0],
      status: pickupStatus,
    };

  }, [props.pickupIds, pickupAssignmentState.pickups]);

  const pickupLocationDefined = !!(pickup && pickup.shipper.address.geolocation);
 
  const render = (): ReactElement => {
    return <Loading isLoading={true} />;
  };

  const center = () => {
    return pickupLocationDefined
      ? {
        lat: pickup.shipper.address.geolocation.lat,
        lng: pickup.shipper.address.geolocation.lng
      } : {
      lat: 40.329796,
      lng: -98.641933
    };
  };

  useEffect(() => {
    if (props.driverId) {
      const driverObj = pickupAssignmentState.drivers.find(obj => obj.driverId === props.driverId);
      setDriver(driverObj);
    } else {
      setDriver(undefined);
    }
  }, [props.driverId, pickupAssignmentState.drivers]);

  const driverBoundaryPoints = (driver: DriversResponseContentModel): Coordinates[] => (
    driver.stops.map(stop => getCoordinates(stop.address))
    .concat(driver.pickups ? driver.pickups.map((pickup) => (getCoordinates(pickup.shipper.address.geolocation))) : [])
    .concat(driver.driverLocation ? getCoordinates(driver.driverLocation) : [])
  );

  const displayedDrivers = driver ? [driver] : (props.showAllRoutes ? pickupAssignmentState.drivers : []);

  const displayedDriversBoundaryPoints = displayedDrivers.reduce((result: Coordinates[], driver) => ([...result, ...driverBoundaryPoints(driver)]), []);

  const selectedPickupBoundaryPoints = pickupLocationDefined ? [getCoordinates(pickup.shipper.address.geolocation)] : [];

  const points = pickup ? selectedPickupBoundaryPoints.concat(displayedDriversBoundaryPoints) : []; 

  return (
    <div>
      <div className="xgs-map__container">      
        <Wrapper
          apiKey={apiKey}
          render={render}
          libraries={["geometry", "marker"]}
        >
          <XGSMap
            center={center()}
            zoom={pickupLocationDefined ? 6 : 4}
            fitBounds={points.length > 1}
            points={points}
          > 
            {pickupLocationDefined && (
                <XGSCustomMarker
                  key={`${pickup.pickup.pickupNumber}-${pickup.status}`}
                  position={{
                    lat: pickup.shipper.address.geolocation.lat,
                    lng: pickup.shipper.address.geolocation.lng
                  }}
                  label={{
                    text: "PU",
                    ...getStopLabelProps(StopType.PICKUP),
                  }}
                  fillColor={getStopFillColor(StopType.PICKUP, pickup.status)}
                  tooltip={<XGSMarkerTooltip><StopMarkerInfo info={{ address: formatAddress(pickup.shipper.address) }} /></XGSMarkerTooltip>}
                />
            )}            
            
            {pickup && displayedDrivers && displayedDrivers.map(driver => (
              <DriverRoute
                key={driver.driverId}
                driver={driver}
                showAllRoutes={props.showAllRoutes}
                onClick={() => { props.onDriverSelect(driver.driverId) }}
              />
            ))}
          </XGSMap>
        </Wrapper>
      </div>
    </div>
  );
};

export default PickupMap;
