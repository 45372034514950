import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";
import {
  completePickup,
  pickupDetailsSelector
} from "../../../slices/pickup/pickupDetailsSlice";

import "./pickupDetails.scss";

export interface PickupCompleteProps {
  onClose: () => void;
  onComplete: () => void;
  pickupId: string;
  pickupNumber: string;
  show: boolean;
};

const PickupComplete: React.FC<PickupCompleteProps> = (props) => {
  const dispatch = useDispatch();
  const pickupDetailsState = useSelector(pickupDetailsSelector);
  const [confirmed, setConfirmed] = useState(false);

  const onCompletePickup = () => {
    dispatch(completePickup(
      props.pickupId,
      () => {
        toast.info(`Pickup has been completed!`, { autoClose: 3000 });
        props.onComplete();
      }, (error) => {
        toast.error(error);
      }
    ));
  };

  useEffect(() => {
    if (!props.show) setConfirmed(false);
  }, [props.show]);

  return (
    <SlideOutSidebar
      header="Complete Pickup"
      show={props.show}
      size="extrasmall"
      onClose={props.onClose}
      className="xgs-pickup-details__slideout"
    >
      <>
        <div className="xgs-pickup-details__slideout__inner-header">
          Complete Pickup {props.pickupNumber}
        </div>
        <div className="xgs-pickup-details__slideout__text">
          Do you really want to complete this pickup?
        </div>
        <XGSCheckbox
          name="confirmation"
          onChange={() => setConfirmed(!confirmed)}
          checked={confirmed}
        >
          You acknowledge that XGS has a BOL for this pickup.
        </XGSCheckbox>
        
        <div className="xgs-pickup-details__slideout__buttons">
          <Button
            theme={ButtonThemes.gray}
            onClick={props.onClose}
            type="button"
          >
            No
          </Button>
          <Button
            theme={ButtonThemes.blue}
            spinner={pickupDetailsState.requestStarted && pickupDetailsState.requestCreator === "COMPLETE_PICKUP"}
            type="submit"
            onClick={onCompletePickup}
            disabled={!confirmed}
          >
            Yes
          </Button>
        </div>
      </>
    </SlideOutSidebar>
  );
};

export default PickupComplete;
