import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../ui-components/table/table";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import LabeledTextInput from "../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import TeamsState from "../../slices/company/CompanyState";
import {
  decreaseTeamAccountsCount,
  getAccounts,
  increaseTeamAccountsCount,
  resetErrors,
  teamsSelector
} from "../../slices/company/companySlice";
import AccountDetails from "./accounts/accountDetails";
import AddAccount from "./add-account/addAccount";
import RemoveAccount from "./remove-account/removeAccount";
import "./company.scss";
import Tag from "../../ui-components/molecules/tag/tag";
import { TagColor } from "../../app/data/common/tagColor";

export interface TeamAccountsProps {
  id: string | undefined;
  type: "CUSTOMER" | "AGENT";
}

const TeamAccounts: React.FC<TeamAccountsProps> = (props) => {
  const dispatch = useDispatch();
  const teamsState: TeamsState = useSelector(teamsSelector);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [removeAccountId, setRemoveAccountId] = useState("");
  const [accountId, setAccountId] = useState<string | undefined>();
  const [searchString, setSearchString] = useState<string>("");

  const columns = [
    {
      width: 100,
      minWidth: 100,
      Header: "Account #",
      accessor: "accountNumber",
      Cell: ({ row }: any) => (
        <span className={row.original.deleted ? "xgs-teams__deleted-account" : ""}>
          {row.original.accountNumber}
        </span>
      )
    },
    {
      width: 128,
      minWidth: 128,
      Header: "Customer name",
      accessor: "name",
      Cell: ({ row }: any) => (
        <span className={row.original.deleted ? "xgs-teams__deleted-account" : ""}>
          {row.original.paypoint && (
            <XGSIcon
              icon={XGSIcons.faDollarSign}
              className="xgs-teams__details__payor-account"
              title="Paypoint account"
            />
          )}
          {row.original.name}
        </span>
      )
    },
    {
      width: 203,
      minWidth: 200,
      Header: "Address",
      id: "addressColumn",
      Cell: ({ row }: any) => (
        <span>
          {row.original.address?.line1 ? `${row.original.address.line1}, ` : ""}{row.original.address?.line2 ? `${row.original.address.line2}, ` : ""}{row.original.address?.city ? `${row.original.address.city}, ` : ""}{row.original.address?.state ? `${row.original.address.state}, ` : ""}{row.original.address?.zip}
        </span>
      )
    },
    ...props.type === "AGENT" ? [] : [
      {
        width: 120,
        minWidth: 120,
        Header: "Availability time",
        id: "availability",
        accessor: "isAvailabilityHoursFilled",
        Cell: (props: any) => (
          props.value === "AVAILABLE" ? (
            <Tag mods={{ color: TagColor.GREEN }}>
              AVAILABLE
            </Tag>
          ) : props.value === "NA" ? (
            <Tag mods={{ color: TagColor.GREY }}>
              N/A
            </Tag>
          ) : (
            <Tag mods={{ color: TagColor.GREY }}>
              MISSING
            </Tag>
          )
        )
      },
      {
        width: 112,
        minWidth: 112,
        Header: "Contacts",
        id: "contacts",
        accessor: "isContactsFilled",
        Cell: (props: any) => (
          props.value === "AVAILABLE" ? (
            <Tag mods={{ color: TagColor.GREEN }}>
              AVAILABLE
            </Tag>
          ) : props.value === "NA" ? (
            <Tag mods={{ color: TagColor.GREY }}>
              N/A
            </Tag>
          ) : (
            <Tag mods={{ color: TagColor.GREY }}>
              MISSING
            </Tag>
          )
        )
      },
    ],
    {
      width: 52,
      minWidth: 52,
      Header: "",
      id: "actionColumn",
      Cell: ({ row }: any) => (
        <div className="text-center">
          {!row.original.deleted && (
            <Button
              className="xgs-company__small-button"
              type="button"
              theme={ButtonThemes.gray}
              spinner={false}
              onClick={(e) => {
                e.stopPropagation();
                setRemoveAccountId(row.original.id);
                setShowRemoveConfirmation(true);
              }}
            >
              <XGSIcon
                icon={XGSIcons.faTimes}
                size="sm"
              />
            </Button>
          )}
        </div>
      )
    }
  ];

  const onListInfiniteScroll = () => {
    if (!props.id) return;
    dispatch(getAccounts(props.id, teamsState.accountsSearchString, teamsState.accountsRequest?.lastIds));
  };

  const onAccountClick = (row: any) => {
    setAccountId(row.id);
  };

  const onSearchStart = () => {
    if (!props.id) return;
    dispatch(getAccounts(props.id, searchString || null));
  };

  const onSearchClear = () => {
    if (!props.id) return;
    setSearchString("");
    if (!teamsState.accountsSearchString) return;
    dispatch(getAccounts(props.id, ""));
  };

  useEffect(() => {
    if (!props.id) return;
    dispatch(resetErrors());
    dispatch(getAccounts(props.id, null));
    setAccountId(undefined);
  }, [dispatch, props.id]);

  const currentTeam = useMemo(() => {
    return teamsState.teams.find((team) => team.id === props.id);
  }, [teamsState.teams, props.id])

  return (
    <div className="xgs-teams__accounts">
      <Button
        disabled={(currentTeam?.accounts! > 0 && currentTeam?.agent)}
        theme={ButtonThemes.transparent}
        onClick={() => setShowAddPopup(true)}
        className={`xgs-teams__details__add-button blue-link`}
      >
        <XGSIcon
          icon={XGSIcons.faPlusCircle}
          size="sm"
          className="xgs-teams__details__add-button__icon"
        />
        Add account
      </Button>
      {teamsState.accounts_fetch_was_failed && (
        <XGSErrorMessage>{teamsState.accounts_fetch_fail_reason}</XGSErrorMessage>
      )}
      {!teamsState.accounts_fetch_was_failed && (
        <>
          <div className="xgs-list__controls">
            <div className="xgs-list__controls__search">
              <LabeledTextInput
                labelMode={LabelModes.column}
                className="xgs-list__controls__search__input"
                value={searchString}
                onChange={(e) => setSearchString(e.currentTarget.value)}
                onKeyDown={(e) => (e.key === "Enter") && !teamsState.teams_fetch_was_started && onSearchStart()}
                type="text"
                placeholder="Account name or number"
              />
              <Button
                className="xgs-list__controls__button"
                theme={ButtonThemes.blue}
                disabled={teamsState.teams_fetch_was_started}
                onClick={onSearchStart}>
                Search
              </Button>
              <Button
                className="xgs-list__controls__button"
                theme={ButtonThemes.gray}
                onClick={onSearchClear}>
                Clear
              </Button>
            </div>
          </div>
          <div className="xgs-teams__details__table-wrapper">
            <div className="xgs-teams__details__table">
              <Table
                autoHeight={true}
                isLoading={teamsState.accounts_fetch_was_started}
                columns={columns}
                data={teamsState.accounts}
                onRowClicked={(row: any) => !currentTeam?.agent && onAccountClick(row)}
                cursorPointer={!currentTeam?.agent}
                rowHeight={76}
                minTableHeight={400}
                noResultsText="There are no accounts"
                infiniteScroll={true}
                infiniteScrollLoading={teamsState.accounts_fetchPortion_was_started}
                infiniteScrollHasNext={!teamsState.accountsFetchedAll}
                onInfiniteScroll={onListInfiniteScroll}
                responsive={true}
              />
            </div>
            <AccountDetails
              teamId={props.id}
              id={accountId}
              accountData={accountId ? teamsState.accounts?.find((account: any) => account.id === accountId) : null}
              show={!!accountId}
              readOnly={accountId ? teamsState.accounts?.find((account: any) => account.id === accountId).deleted : false}
              onClose={() => {
                setAccountId(undefined);
              }}
            />
          </div>
        </>
      )}
      {props.id && (
        <>
          <RemoveAccount
            teamId={props.id}
            accountId={removeAccountId}
            show={showRemoveConfirmation}
            type={props.type}
            onAccountRemoved={() => {
              dispatch(decreaseTeamAccountsCount(props.id));
            }}
            onClose={() => {
              setShowRemoveConfirmation(false);
              setRemoveAccountId("");
            }} />
          <AddAccount
            teamId={props.id}
            show={showAddPopup}
            type={props.type}
            onAccountAdded={() => {
              props.id && dispatch(getAccounts(props.id, null));
              dispatch(increaseTeamAccountsCount(props.id));
            }}
            onClose={() => {
              setShowAddPopup(false);
            }} />
        </>
      )}
    </div>
  );
};

export default TeamAccounts;
