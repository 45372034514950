import api, { ApiResponse } from "./../api";
import { GetRatesRequestModel } from "./models";

class RequestRatesService {
  private static instance: RequestRatesService;

  private constructor() {}

  static getInstance(): RequestRatesService {
    if (!RequestRatesService.instance) {
      RequestRatesService.instance = new RequestRatesService();
    }

    return RequestRatesService.instance;
  }

  public getFreightClass = async (
    length: number,
    width: number,
    height: number,
    weight: number
  ): Promise<ApiResponse<any>> => {
    return await api.post("/rate-quote/freight-class", {
      size: {
        length: length,
        width: width,
        height: height
      },
      weight: weight
    });
  };

  public getRates = async (
    request: GetRatesRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/rate-quote", request);
  };

  public submitRequest = async (
    id: string
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/rate-quote/${id}`, {
      requested: true
    });
  };

  public getRatesList = async (
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    let params;
    if (lastIds) {
      params = {
        lastIds: lastIds
      }
    }
    return await api.get("/rate-quote", {
      params: params
    });
  };

  public getRatesDetails = async (
    id: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/rate-quote/${id}`);
  };

  public requestAfterError = async (
    request: GetRatesRequestModel,
    type: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/rate-quote/error", {
      request,
      type
    });
  };      

  public downloadDocument = async (
    quoteNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/rate-quote/${quoteNumber}/document`, {
      responseType: "blob"
    });
  };

  public sendQuoteEmail = async (
    email: string,
    quoteId: string    
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/rate-quote/${quoteId}/send`, { email });
  };
}

export default RequestRatesService;
