import React from "react";
import { cn } from "../../services/common/className";

import "./banner.scss";

export enum BannerColor {
  purple = "purple",
}

export interface BannerProps {
  mods?: {
    color?: BannerColor,
  },
  mix?: string,
}

const Banner: React.FC<BannerProps> = ({
  mods,
  mix,
  ...props
}) => {

  return (
    <div className={cn("xgs-banner")(mods, mix)}>
      {props.children}
    </div>
  );
};

export default Banner;
