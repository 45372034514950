import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import Modal from "react-modal";
import moment from "moment";
import Loading from "../../../../ui-components/loading/loading";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../../ui-components/form/textarea/xgsFormTextarea";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import XGSFormDate from "../../../../ui-components/form/date/xgsFormDate";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import {
  PickupShipperModel,
  PickupShipperSchema
} from "../../../../app/data/pickup/models";
import { StepProps } from "../../../../app/data/common/models";
import {
  pickupSelector,
  checkPickup,
  clearShippers,
  // clearItems,
  getShippers,
  searchCompany,
  setShipper,
  setTeamName,
  setShippersSearch,
  addShipper,
  setPaypointNumber
} from "../../../../slices/pickup/pickupSlice";
import {
  commonSelector,
  getFreightClasses,
  getPickupDays
} from "../../../../slices/common/commonSlice";
import { fromTimeOptions, toTimeOptions } from "../../../../services/common/time";
import { modalStyle } from "../../../../app/data/common/modalStyle";
import ShipperCreate from "../../../../ui-components/shipper-create/shipperСreate";
import XGSPopup, { PopupTheme } from "../../../../ui-components/xgs-popup/xgsPopup";
import { Card } from "../../../../ui-components/card/card";
import CompanyService from "../../../../app/data/company/companyService";
import { SubAccountMinimal } from "../../../../app/data/account/SubAccount";
import { shipperSelector } from "../../../../slices/shipper/shipperSlice";
import {
  currentOrClosestBusinessDay,
  makeId
} from "../../../../hooks/utils";
import { ERROR_MESSAGES } from "../../../../app/data/common/errorMessages";
import XGSCheckbox from "../../../../ui-components/xgs-checkbox/xgsCheckbox";
import ImportantMessage, { ImportantMessageTheme } from "../../../../ui-components/molecules/important-message/importantMessage";
import { getDateClassName } from "../../../../services/pickups";
import { SearchRequesterModel, SearchRequestersResponseModel } from "../../../../app/data/company/models";
import { cn } from "../../../../services/common/className";

let initialValues: PickupShipperModel = {
  teamId: "",
  shipper: {
    id: null,
    accountNumber: null,
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: "",
      additionalAddressLine: "",
    },
    contacts: {
      firstName: "",
      lastName: "",
      mobilePhone: "",
      allowSms: false
    }
  },
  bolNumber: "",
  poNumber: "",
  additionalReference: "",
  specialInstructions: "",
  pickupDate: "",
  pickupReadyTime: undefined,
  pickupCloseTime: undefined
}

const companyService = CompanyService.getInstance();

const PickupShipperStep: React.FC<StepProps> = (props) => {
  const { next } = props;
  const pickupState = useSelector(pickupSelector);
  const commonState = useSelector(commonSelector);
  const shipperState = useSelector(shipperSelector);
  const dispatch = useDispatch();
  const shipperFormRef = useRef<FormikProps<PickupShipperModel>>(null);
  const [team, setTeam] = useState<XGSSelectOption | null | undefined>();
  const [shipperFormValue, setShipperFormValue] = useState<XGSSelectOption | null>();
  const [pickupAvailable, setPickupAvailable] = useState(true);
  const [pickupDateFormValue, setPickupDateFormValue] = useState<string | undefined>();
  const [pickupReadyTimeFormValue, setPickupReadyTimeFormValue] = useState<XGSSelectOption | null>();
  const [pickupCloseTimeFormValue, setPickupCloseTimeFormValue] = useState<XGSSelectOption | null>();  
  const [mobilePhoneValue, setMobilePhoneValue] = useState("");
  const [allowSms, setAllowSms] = useState(false);
  const [hazMat, setHazMat] = useState(false);
  const [shipperCreated, setShipperCreated] = useState(false);
  const [shipperQuery, setShipperQuery] = useState("");
  const [showNonServiceDayNotification, setShowNonServiceDayNotification] = useState(false);

  const shippers = shipperQuery ? pickupState.shippersSearch : pickupState.shippers;

  let companySearch = (value: string) => {
    value?.length > 1 && dispatch(searchCompany(value));
  }
  companySearch = debounce(companySearch, 300);

  const changeTeam = (_team: XGSSelectOption | null | undefined) => {
    setTeam(_team);    
    _team && dispatch(setTeamName(_team.label));
    const selectedTeamId = _team ? _team.value : null;   
    selectedTeamId && dispatch(getShippers(selectedTeamId));  
    shipperFormRef.current?.setFieldValue("teamId", selectedTeamId);    
  };

  const resetShipper = (_team: XGSSelectOption | null | undefined) => {    
    setShipperFormValue(null);    
    dispatch(clearShippers());    
    shipperFormRef.current?.setFieldValue("shipper.id", undefined);
    shipperFormRef.current?.setFieldValue("pickupDate", "");
    setPickupDateFormValue(undefined);
  }

  const onChangeTeam = (_team: XGSSelectOption | null | undefined) => {
    changeTeam(_team);
    resetShipper(_team);
  };

  const shipperOption = (shipper: SubAccountMinimal) => ({    
      label: `${shipper.name} (${shipper.accountNumber})`,
      subtitle: `
        ${(shipper.address?.line1 && (shipper.address?.line1 !== "-")) ? shipper.address?.line1.toLowerCase().capitalize() + ", " : ""}
        ${(shipper.address?.city && (shipper.address?.city !== "-")) ? shipper.address?.city.toLowerCase().capitalize() : ""}${
          (shipper.address?.city && (shipper.address?.city !== "-") && shipper.address?.state && (shipper.address?.state !== "-")) ? ", " : ""}${
            (shipper.address?.state && (shipper.address?.state !== "-")) ? shipper.address?.state : ""}
      `,
      value: `${shipper.id}`    
  });

  const shipperOptions = useMemo(() => {
    let options: XGSSelectOption[] = [];
    if (pickupState.shippers && pickupState.shippers.length > 0) {
      for (let i = 0; i < pickupState.shippers.length; i++) {
        if (!pickupState.shippers[i].isNew && pickupState.shippers[i].id) {
          options.push(shipperOption(pickupState.shippers[i]));
        }        
      }
    }
    if (options.length > 0) {
      options = uniqBy(options, "value");
      options = sortBy(options, ["label"]);
    }
    return options;
  }, [pickupState.shippers]);

  const groupOptions = (accountOptions: XGSSelectOption[], globalOptions?: XGSSelectOption[]) => ([
    {
      label: "Results related to requester:",
      options: accountOptions,
    },
    {
      label: "All results:",
      options: globalOptions || [],
    }
  ]);

  const groupedShipperOptions = useMemo(() => groupOptions(shipperOptions), [shipperOptions]);

  const requesterOption = (company: SearchRequesterModel) => ({
    label: company.name,
    subtitle: company.accountName ? `${company.accountName} (${company.accountNumber})` : null,
    value: company.id,
  });

  const setSelectedShipper = (shipper?: SubAccountMinimal | null) => {    
    shipperFormRef.current?.setFieldValue("shipper.id", shipper?.id || undefined);
    shipperFormRef.current?.setFieldValue("shipper.accountNumber", shipper?.accountNumber || undefined);

    // TODO check
    // if (shipperFormValue?.value !== shipper.id) {
    //   dispatch(clearItems());
    // }  
    
    shipperFormRef.current?.setFieldValue("shipper.name", shipper?.name || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.address1",  shipper?.address?.line1 || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.city",  shipper?.address?.city || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.postalCode",  shipper?.address?.zip || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.state",  shipper?.address?.state || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.additionalAddressLine",  shipper?.address?.additionalAddressLine || undefined);
    shipperFormRef.current?.setFieldValue("pickupDate", "");
    setPickupDateFormValue(undefined);
    setShowNonServiceDayNotification(false);
  };

  const onChangeTeamAccount = (account: XGSSelectOption | null | undefined) => {
    setShipperFormValue(account);   

    const shipper = shippers && shippers.find(obj => obj.id === account?.value);

    if (shipper?.address?.zip) {
      checkPickupAvailability(shipper?.address.zip);
      dispatch(getPickupDays(shipper?.address.zip));      
    }

    setSelectedShipper(shipper);

    if (!team && shipper?.teamId) {
      changeTeam({ value: shipper.teamId, label: shipper.teamName || "-" });
    }
  };
  
  const onAddShipper = () => {
    const shipper: SubAccountMinimal = { 
      ...shipperState.shipper,
      address: {
        line1: shipperState.shipper.address.address1,
        line2: "",
        city: shipperState.shipper.address.city,
        state: shipperState.shipper.address.state,
        zip: shipperState.shipper.address.postalCode,
        country: "",
        additionalAddressLine: shipperState.shipper.address.additionalAddressLine,
      },
      id: makeId(5),
      accountNumber: 69,
      isNew: true,
     };

    dispatch(addShipper(shipper));

    const option = shipperOption(shipper);
    setShipperFormValue(option);

    if (shipper?.address?.zip) {
      dispatch(getPickupDays(shipper?.address?.zip));
    }

    setSelectedShipper(shipper);
    setShipperCreated(false);
  }

  const onClickNext = (data: PickupShipperModel) => {
    if (data.shipper?.address.postalCode) {
      dispatch(getFreightClasses(data.shipper?.address.postalCode));
    }
       
    dispatch(setShipper(data));
    next && next();
  };

  const checkPickupAvailability = (zip: string) => {    
    zip && dispatch(checkPickup(zip, () => setPickupAvailable(true), () => setPickupAvailable(false)));
  };

  const getSearchOptions = (shippers: SubAccountMinimal[]) => groupOptions(
    shippers
      .filter(shipper => shipper.paypointNumber === pickupState.paypointNumber)
      .map(shipperOption),
    shippers
      .filter(shipper => shipper.paypointNumber !== pickupState.paypointNumber)
      .map(shipperOption),
  );

  // eslint-disable-next-line
  const searchShipper = React.useCallback(
    debounce((query, callback) => {
      companyService.getShippersGlobalSearch(query, pickupState.paypointNumber)
      .then(options => {
        const shippers = options.map(option => ({ ...option, id: makeId(5) }));
        dispatch(setShippersSearch(shippers));
        callback(getSearchOptions(shippers)
      )})
    }, 300),
    [pickupState.paypointNumber]
  );

  const getRequesterSearchOptions = (search: SearchRequestersResponseModel | null) => {
    if (!search) return [];

    const requestersOptions = search.teams.map(requesterOption);
    const accountsOptions = search.accounts.map(requesterOption);    

    return [{
      label: "By Company:",
      options: requestersOptions || [],
    },
    {
      label: "By Account:",
      options: accountsOptions || [],
    }]
  }

  // eslint-disable-next-line
  const searchRequester = React.useCallback(
    debounce((query, callback) => {
      companyService.getRequestersGlobalSearch(query)
      .then(options => {        
        callback(getRequesterSearchOptions(options))
      })
    }, 300),
    []
  );

  const checkNonServiceDateNotification = (date: string) => {
    if (commonState.pickupDays) {
      const dateDayOfWeek = moment(date, "MM/DD/YYYY").format("dd").toLowerCase();
      setShowNonServiceDayNotification(commonState.pickupDays[dateDayOfWeek] === false);
    } else {
      setShowNonServiceDayNotification(false);
    }
  };

  const onPickupDateChange = (date) => {
    shipperFormRef.current?.setFieldValue("pickupDate", date || "");
    setPickupDateFormValue(date);
    checkNonServiceDateNotification(date);
  };

  useEffect(() => {
    if (pickupState.teamId) {
      initialValues.teamId = pickupState.teamId;
      setTeam({
        value: pickupState.teamId,
        label: pickupState.teamName
      });
    }
    if (pickupState.shipper.id) {
      initialValues.shipper.id = pickupState.shipper.id;
      let shipperObj = pickupState.shippers?.find(obj => obj.id === pickupState.shipper.id) 
      || pickupState.shippersSearch?.find(obj => obj.id === pickupState.shipper.id);
      shipperObj && setShipperFormValue(shipperOption(shipperObj));
    }
    initialValues.shipper.accountNumber = pickupState.shipper.accountNumber;
    initialValues.shipper.name = pickupState.shipper.name;
    initialValues.shipper.address.address1 = pickupState.shipper.address.address1;
    initialValues.shipper.address.city = pickupState.shipper.address.city;
    initialValues.shipper.address.postalCode = pickupState.shipper.address.postalCode;
    initialValues.shipper.address.state = pickupState.shipper.address.state;
    initialValues.shipper.address.additionalAddressLine = pickupState.shipper.address.additionalAddressLine;
    initialValues.bolNumber = pickupState.bolNumber;
    initialValues.poNumber = pickupState.poNumber;
    initialValues.additionalReference = pickupState.additionalReference;
    initialValues.specialInstructions = pickupState.specialInstructions;
    initialValues.pickupDate = pickupState.pickupDate;
    checkNonServiceDateNotification(pickupState.pickupDate);
    initialValues.pickupReadyTime = pickupState.pickupReadyTime;
    initialValues.pickupCloseTime = pickupState.pickupCloseTime;
    shipperFormRef.current?.setFieldValue("pickupDate", pickupState.pickupDate);
    setPickupDateFormValue(pickupState.pickupDate);
    if (pickupState.pickupReadyTime) {
      setPickupReadyTimeFormValue({
        value: pickupState.pickupReadyTime,
        label: pickupState.pickupReadyTime
      });
    }
    if (pickupState.pickupCloseTime) {
      setPickupCloseTimeFormValue({
        value: pickupState.pickupCloseTime,
        label: pickupState.pickupCloseTime
      });
    }
    initialValues.shipper.contacts.firstName = pickupState.shipper.contacts.firstName;
    initialValues.shipper.contacts.lastName = pickupState.shipper.contacts.lastName;
    initialValues.shipper.contacts.mobilePhone = pickupState.shipper.contacts.mobilePhone;
    pickupState.shipper.contacts?.mobilePhone && setMobilePhoneValue(pickupState.shipper.contacts.mobilePhone);
    initialValues.shipper.contacts.allowSms = pickupState.shipper.contacts.allowSms;
    setAllowSms(pickupState.shipper.contacts.allowSms || false);
    initialValues.shipper.contacts.email = pickupState.shipper.contacts.email;
    setTimeout(() => {
      shipperFormRef.current?.validateForm();
    }, 50);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // preselect shipper if it is alone in dropdown
    if (pickupState.shippers === null || pickupState.shipper) return;
    if (shipperOptions.length === 1) {
      onChangeTeamAccount(shipperOptions[0]);
    }
    // eslint-disable-next-line
  }, [pickupState.shippers, pickupState.shipper, shipperOptions]);

  useEffect(() => {
    if (!pickupCloseTimeFormValue) return;
    const options = toTimeOptions(pickupReadyTimeFormValue?.value);
    const toIndex = options.findIndex((obj: XGSSelectOption) => obj.value === pickupCloseTimeFormValue?.value);
    if (toIndex === -1) {
      // if current "To" time is out of allowed time options
      setPickupCloseTimeFormValue(options[0]);
      shipperFormRef.current?.setFieldValue("pickupCloseTime", options[0].value);
    }
  }, [pickupCloseTimeFormValue, pickupReadyTimeFormValue]);

  useEffect(() => {
    if (!team) {
      dispatch(setPaypointNumber(""));
    }
  }, [team, dispatch]);

  return (
    <div className="xgs-pickup__shipper">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={PickupShipperSchema}
        innerRef={shipperFormRef}
        enableReinitialize
      >
        {(props: FormikProps<PickupShipperModel>) => (
          <Form>
            <div className="xgs-pickup__step-header">
              Shipper Information
              {pickupState.requestStarted && pickupState.requestCreator === "CHECK_PICKUP" && <Loading isLoading={true} className="xgs-pickup__step-header__loader" />}
            </div>
            <LabeledSelectInput
              isDisabled={false}
              isLoading={pickupState.requestStarted && pickupState.requestCreator === "SEARCH_COMPANY"}
              label="Requester (Company):"
              labelMode={LabelModes.column}
              onInputChange={companySearch}
              onValueChange={onChangeTeam}
              openMenuOnClick={pickupState.companies?.length > 0}
              options={pickupState.companies}
              required={true}
              requiredAsteriskDisabled={false}
              placeholder="Enter company name..."
              value={team}
              isClearable
              async
              cacheOptions={false}
              loadOptions={searchRequester}
              formik={true}
              className="xgs-pickup__field"
              isOptionSelected={(option, selectValue) => (option.value === selectValue[0]?.value && option.label === selectValue[0]?.label && option.subtitle === selectValue[0]?.subtitle)}
            />
            <LabeledSelectInput
              name="shipper"
              label="Pickup location (Shipper):"
              labelMode={LabelModes.column}
              value={shipperFormValue}
              onValueChange={onChangeTeamAccount}
              onInputChange={setShipperQuery}
              options={groupedShipperOptions}
              async
              cacheOptions={false}
              loadOptions={searchShipper}
              required={true}
              requiredAsteriskDisabled={false}
              formik={true}
              className="xgs-pickup__field"
              isLoading={pickupState.requestStarted && pickupState.requestCreator === "GET_SHIPPERS"}
              isClearable
              extendAction={pickupState.paypointNumber && shipperQuery ? () => setShipperCreated(true) : undefined}
              extendLabel="Add new shipper"
              placeholder="Start typing..."
            />
            
            {shipperFormValue && (<Card
              icon={(
                <div className="shipper-card__icon">
                  <XGSIcon className="card__info__item" icon={XGSIcons.faMapPin} />
                </div>
              )}
              title="Shipper Location"
              hideButtons
            >
              <div className="xgs-pickup__shipper__address-block">
                <div>Address: <span>{props.values.shipper.address.address1}</span></div>
                <div>City: <span>{props.values.shipper.address.city}</span></div>
                <div>State: <span>{props.values.shipper.address.state}</span></div>
                <div>Zip: <span>{props.values.shipper.address.postalCode}</span></div>
                {props.values.shipper.address.additionalAddressLine && <div>Additional address line: <span>{props.values.shipper.address.additionalAddressLine}</span></div>}
              </div>
            </Card>
            )}

            {shipperFormValue && !pickupAvailable && !(pickupState.requestStarted && pickupState.requestCreator === "CHECK_PICKUP") && (
              <ImportantMessage theme={ImportantMessageTheme.RED} mix="xgs-pickup__message-row">
                The pickup request option isn't available for&nbsp;the&nbsp;current shipper!
              </ImportantMessage>
            )}
            {(pickupAvailable || shipperCreated) && (
              <>
                <div className="xgs-pickup__field">
                  <XGSCheckbox
                    name="hazMat"
                    onChange={() => setHazMat(!hazMat)}
                    checked={hazMat}
                  >
                    Contains hazardous materials?
                  </XGSCheckbox>
                </div>
                <div className="xgs-gray-area  xgs-pickup__field">
                  <ImportantMessage theme={ImportantMessageTheme.BLUE} mix="xgs-pickup__message-row">
                    Please enter the information if available
                  </ImportantMessage>
                  <div className="xgs-pickup__row">
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="bolNumber"
                        label="Bill of Lading (BOL):"
                        required={false}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                      />
                    </div>
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="poNumber"
                        label="Purchase Order (PO):"
                        required={false}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                      />
                    </div>
                  </div>
                  <XGSFormInput
                    type="text"
                    name="additionalReference"
                    label="Additional Reference:"
                    required={false}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                    className="xgs-pickup__field"
                  />
                </div>
                <div className="xgs-gray-area xgs-pickup__field">
                  <div className="xgs-gray-area__header">
                    Pickup Date and Time
                  </div>
                  <XGSFormDate
                    className="xgs-pickup__field"
                    name="pickupDate"
                    label="Pickup Date:"
                    placeholder="Select date..."
                    onDateChange={onPickupDateChange}
                    value={pickupDateFormValue}
                    onChange={() => null}
                    minDate={new Date(currentOrClosestBusinessDay())}
                    required={true}
                    labelMode={LabelModes.column}
                    disableWeekends={true}
                    disabled={!shipperFormValue || (commonState.requestStarted && (commonState.requestCreator === "GET_SERVICE_CENTER_DAYS"))}
                    dayClassName={(date) => getDateClassName(commonState.pickupDays, date)}
                  />

                  {showNonServiceDayNotification &&
                    <ImportantMessage theme={ImportantMessageTheme.RED} mix="xgs-pickup__message-row">
                      Please note that you selected non-service day!
                    </ImportantMessage>
                  }
                  <div className="xgs-bol__return__availability">
                    <div className="xgs-bol__return__availability__controls">
                      <LabeledSelectInput
                        name="pickupReadyTime"
                        value={pickupReadyTimeFormValue}
                        label="Pickup Ready:"
                        isSearchable={false}
                        labelMode={LabelModes.column}
                        onValueChange={(v) => {
                          props.setFieldValue("pickupReadyTime", v?.value);
                          setPickupReadyTimeFormValue(v);
                        }}
                        options={fromTimeOptions()}
                        required={true}
                        formik={true}
                        placeholder="Ready..."
                        menuPlacement="top"
                      />
                      <div className="xgs-bol__return__availability__controls__delimiter">&mdash;</div>
                      <LabeledSelectInput
                        name="pickupCloseTime"
                        value={pickupCloseTimeFormValue}
                        label="Dock close time:"
                        isSearchable={false}
                        labelMode={LabelModes.column}
                        onValueChange={(v) => {
                          props.setFieldValue("pickupCloseTime", v?.value);
                          setPickupCloseTimeFormValue(v);
                        }}
                        options={toTimeOptions(pickupReadyTimeFormValue?.value || "")}
                        required={true}
                        formik={true}
                        placeholder="Close..."
                        menuPlacement="top"
                      />
                    </div>
                  </div>
                </div>
                <div className="xgs-pickup__textarea xgs-pickup__field">
                  <XGSFormTextarea
                    name="specialInstructions"
                    value={props.values.specialInstructions}
                    label="Driver/Dispatcher Pickup Instructions:"
                    required={false}
                    rows={4}
                    counter={150}
                  />
                </div>
                <div className="xgs-gray-area">
                  <div className="xgs-gray-area__header">
                    Shipper contact information
                  </div>
                  <div className="xgs-pickup__row">
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="shipper.contacts.firstName"
                        label="First Name:"
                        requiredAsteriskDisabled
                        labelMode={LabelModes.column}
                      />
                    </div>
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="shipper.contacts.lastName"
                        label="Last Name:"
                        requiredAsteriskDisabled
                        labelMode={LabelModes.column}
                      />
                    </div>
                  </div>
                  <div className="xgs-pickup__row xgs-pickup__mobile-phone-row">
                    <div className={cn("xgs-pickup__row__item")({ width: "flex" })}>
                      <XGSFormPhoneInput
                        name="shipper.contacts.mobilePhone"
                        label="Mobile Number:"
                        labelMode={LabelModes.column}
                        onValueChange={(value) => {
                          props.setFieldValue("shipper.contacts.mobilePhone", value);
                          setMobilePhoneValue(value);
                        }}
                        onBlur={props.handleBlur}
                        value={mobilePhoneValue}
                      />
                    </div>
                    <div className={cn("xgs-pickup__row__item")({ width: "auto" })}>
                      <XGSCheckbox
                        name="shipper.contacts.allowSMS"
                        onChange={() => {
                          props.setFieldValue("shipper.contacts.allowSms", !allowSms);
                          setAllowSms(!allowSms);
                        }}
                        checked={allowSms}
                      >
                        SMS Allowed
                      </XGSCheckbox>
                    </div>
                  </div>
                  <div className="xgs-form__field__notes">
                    <strong>Note:</strong> by providing a telephone number and checking this checkbox you are consenting to be contacted by SMS text message.
                    Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                  </div>
                  <XGSFormInput
                    type="text"
                    name="shipper.contacts.email"
                    label="E-mail:"
                    labelMode={LabelModes.column}
                    required
                  />
                </div>
              </>
            )}
            <div className="xgs-pickup__buttons">
              <Button
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.pickupDate}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={hazMat}
        style={modalStyle}
      >
        <>
          <div className="xgs-modal__content">
            <div className="xgs-pickup__popup__warning">
              <XGSIcon
                icon={XGSIcons.faExclamationCircle}
                className="xgs-pickup__popup__warning__icon"
              />
              <div>
                Please call customer service - XGS&nbsp;is&nbsp;not&nbsp;an&nbsp;approved HazMat carrier.
              </div>
            </div>
          </div>
          <div className="xgs-modal__buttons">
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-modal__button"
              onClick={() => setHazMat(false)}
            >
              Close
            </Button>
          </div>
        </>
      </Modal>

      <XGSPopup
        opened={shipperCreated}
        onClose={() => {
          setPickupAvailable(true);
          setShipperCreated(false)
        }}
        className="shipper-create-popup"
        closeOnDocumentClick={false}
        theme={PopupTheme.NEW}
      >
        <ShipperCreate
          onCreate={onAddShipper}
          onZipChange={(zip) => {
            setPickupAvailable(true);

            if (/^\d{5}$/.test(zip)) {
              checkPickupAvailability(zip);
            }
          }}
          isLoading={pickupState.requestStarted && pickupState.requestCreator === "CHECK_PICKUP"}
          error={pickupAvailable
            ? ""
            : ERROR_MESSAGES.ZIP_PICKUP_UNAVAILABLE}
        />
      </XGSPopup>
    </div>
  );
};

export default PickupShipperStep;
