import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { PickupSummaryDto } from "../../app/data/pickup/models";
import PickupService from "../../app/data/pickup/pickupService";
import { initialInboundPickupsState } from "./inboundPickupState";
import { IState } from "..";
const pickupService = PickupService.getInstance();

const inboundPickupsSlice = createSlice({
  name: "inboundPickups",
  initialState: initialInboundPickupsState,
  reducers: {
    resetInboundPickupsState: (state) => initialInboundPickupsState,
    fetchInboundPickupsStart(state) {
      state.loadingPickups = true;
      state.infiniteLoading = false;
      state.loadingPickupsError = null;
    },
    fetchInboundPickupsSuccess(state, { payload }) {
      state.loadingPickups = false;
      state.pickups = payload.pickups;
      state.totalPages = 0;
      state.totalElements = payload.totalElements;
      state.infiniteLoadingHasNext = payload.infiniteLoadingHasNext;
    },
    startInboundPickupsInfiniteLoading(state) {
      state.loadingPickups = false;
      state.infiniteLoading = true;
      state.infiniteLoadingError = null;
    },
    succeedInboundPickupsInfiniteLoading(state, { payload }) {
      state.infiniteLoading = false;
      state.pickups.push(...payload.pickups);
      state.totalElements += payload.totalElements;
      state.totalPages = payload.totalPages;
      state.infiniteLoadingHasNext = payload.infiniteLoadingHasNext;
    },
    failedInboundPickupsInfiniteLoading(state, { payload }) {
      state.infiniteLoading = false;
      state.infiniteLoadingError = payload;
    },
    fetchInboundPickupsFailure(state, { payload }) {
      state.loadingPickups = false;
      state.loadingPickupsError = payload;
    },
    fetchInboundPickupsSummarySuccess(state, action: PayloadAction<PickupSummaryDto[]>) {
      state.loadingSummaries = false;
      state.summaries = action.payload;
    },
    fetchInboundPickupsSummaryStarted(state) {
      state.loadingSummaries = true;
      state.loadingSummariedError = null;
    },
    fetchInboundPickupsSummaryFailed(state, { payload }) {
      state.loadingSummaries = false;
      state.loadingSummariedError = payload;
    }
  },
});

export const {
  fetchInboundPickupsStart,
  fetchInboundPickupsSuccess,
  fetchInboundPickupsFailure,
  startInboundPickupsInfiniteLoading,
  succeedInboundPickupsInfiniteLoading,
  failedInboundPickupsInfiniteLoading,
  fetchInboundPickupsSummarySuccess,
  fetchInboundPickupsSummaryFailed,
  fetchInboundPickupsSummaryStarted,
  resetInboundPickupsState
} = inboundPickupsSlice.actions;

export const inboundPickupSelector = (state: IState) => {
  return state.inboundPickup;
};

export const fetchInboundPickups = (
  originTerminalCode?: number,
  destinationTerminalCode?: number | null,
  statuses?: string[],
  page: number = 0,
  size: number = 50
): AppThunk => async (dispatch) => {
  dispatch(fetchInboundPickupsStart());
  const response = await pickupService.getInboundPickups(originTerminalCode, destinationTerminalCode, statuses, page, size);
  if (response.ok()) {
    dispatch(
      fetchInboundPickupsSuccess({
        pickups: response.data.content,
        totalPages: page,
        totalElements: response.data.totalElements,
        infiniteLoadingHasNext: response.data.totalElements >= 50
      })
    );
  } else {
    response.getError && dispatch(fetchInboundPickupsFailure(response.getError()));
  }
};

export const fetchInboundPickupsInfinite = (
  originTerminalCode?: number,
  destinationTerminalCode?: number | null,
  statuses?: string[],
  page: number = 0,
  size: number = 50
): AppThunk => async (dispatch) => {
  dispatch(startInboundPickupsInfiniteLoading());
  const response = await pickupService.getInboundPickups(originTerminalCode, destinationTerminalCode, statuses, page, size);
  if (response.ok()) {
    dispatch(
      succeedInboundPickupsInfiniteLoading({
        pickups: response.data.content,
        totalPages: page,
        totalElements: response.data.totalElements,
        infiniteLoadingHasNext: response.data.totalElements >= 50 
      })
    );
  } else {
    response.getError && dispatch(failedInboundPickupsInfiniteLoading(response.getError()));
  }
};

export const fetchInboundPickupsSummary = (origin: number): AppThunk => async (dispatch) => {
  dispatch(fetchInboundPickupsSummaryStarted());
  const response = await pickupService.getInboundPickupsSummary(origin);
  if (response.ok()) {
    dispatch(fetchInboundPickupsSummarySuccess(response.data));
  } else {
    response.getError && fetchInboundPickupsSummaryFailed(response.getError());
  }
};

const inboundPickupReducer = inboundPickupsSlice.reducer;
export default inboundPickupReducer;
