import React from "react";

export const getInboundPickupSummaryColumns = () => (
  [
    {
      Header: "",
      width: 400,
      accessor: "type",
      Cell: (cellProps: any) => (
        <div style={{ fontWeight: 600, color: "rgba(79, 95, 114, 0.72)", marginRight: 4 }}>
          {cellProps.value}
        </div>
      )
    },
    {
      Header: () => <div style={{textAlign: "right"}}>Planned</div>,
      width: 390,
      accessor: "planned",
      Cell: (cellProps: any) => (
        <div style={{ marginRight: 5 }}>
          {cellProps.value}
        </div>
      )
    },
    {
      Header: () => <div style={{textAlign: "right"}}>Actual</div>,
      width: 390,
      accessor: "actual",
      Cell: (cellProps: any) => (
        <div style={{ marginRight: 5 }}>
          {cellProps.value}
        </div>
      )
    },
  ]
)