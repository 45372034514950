import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import InfoBlock from "../../../../templates/info-block/infoBlock";
import InfoGrid from "../../../../templates/info-grid/infoGrid";
import LabeledValue from "../../../../ui-components/molecules/labeled-value/labeledValue";
import BlockPlaceholder from "../../../../ui-components/block-placeholder/blockPlaceholder";
import { InvoiceDetails } from "../../../../app/data/invoice/models";
import UserState from "../../../../slices/user/UserState";
import { userSelector } from "../../../../slices/user/userSlice";
import { UserUtils } from "../../../../app/data/user/userUtils";
import TrailerLocation from "../../../trailer-location/trailerLocation";
import { SHIPMENT_STATUSES } from "../../../../app/data/common/ShipmentStatuses";
import "./invoiceDetailsInfo.scss";

export interface InvoiceDetailsInfoProps extends InvoiceDetails {}

const InvoiceDetailsInfo: React.FC<InvoiceDetailsInfoProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  const getContactPhoneComponent = (phone: string | undefined | null) => {    
    return (
      <span className="xgs-info-block__phone">
        <FontAwesomeIcon
          icon={phone ? Icons.faPhone : Icons.faPhoneSlash}
          size="lg"
          className="chart-icon"
        />
        {phone ? phone.formatPhone() : ""}
      </span>
    );
  }

  const isOutForDelivery = () => props.status?.description === SHIPMENT_STATUSES.outForDelivery;

  return (
    <InfoGrid className="xgs-invoice-details-grid">
      {(props.fullAccess !== false) && (
        <>
          <InfoBlock title="Invoice Summary">
            <LabeledValue
              label="Invoice date:"
              value={props.invoice?.dateInvoice.toUiDateFormat()}
              labelFixWidth
            />
            <LabeledValue
              label="Invoice number:"
              value={props.invoice?.invoiceNumber}
              labelFixWidth
            />
            <LabeledValue
              label="BOL:"
              value={props.invoice?.bolNumber}
              labelFixWidth
            />
            <LabeledValue
              label="PO number:"
              value={props.invoice.poNumber}
              labelFixWidth
              optional
            />
            <LabeledValue
              label="Type of bill:"
              value={props.typeOfBill}
              labelFixWidth
              optional
            />
          </InfoBlock>
        </>
      )}
      <InfoBlock
        title="XGS Origin Hub"
        headerInfo={getContactPhoneComponent(props.origin.phone)}
        className={(props.origin.name || props.origin.address || props.origin.city) ? "xgs-info-block--centered" : ""}
      >
        {(props.origin.name || props.origin.address || props.origin.city) && (
          <div>
            <div className="common-text">{`${props.origin.id ?? ""} ${props.origin.name ?? ""}`}</div>
            <div>&nbsp;</div>
            <div className="common-text">
              {props.origin.address ?? ""}<br />
              {`
                ${props.origin.city ? props.origin.city + ", " : ""} 
                ${props.origin.state ? props.origin.state + " " : ""} 
                ${props.origin.zip ?? ""}
              `}
            </div>
          </div>
        )}
        {!props.origin.address && !props.origin.city && <BlockPlaceholder />}
      </InfoBlock>
      <InfoBlock
        title="XGS Destination Hub"
        headerInfo={getContactPhoneComponent(props.destination.phone)}
        className={(props.destination.address || props.destination.city) ? "xgs-info-block--centered" : ""}
      >
        {(props.destination.name || props.destination.address || props.destination.city) && (
          <div>
            <div className="common-text">{`${props.destination.id ?? ""} ${props.destination.name ?? ""}`}</div>
            <div>&nbsp;</div>
            <div className="common-text">
              {props.destination.address ?? ""}<br />
              {`
                ${props.destination.city ? props.destination.city + ", " : ""} 
                ${props.destination.state ? props.destination.state + " " : ""} 
                ${props.destination.zip ?? ""}
              `}
            </div>
          </div>
        )}
        {!props.destination.name && !props.destination.address && !props.destination.city && <BlockPlaceholder />}
      </InfoBlock>
      {(props.fullAccess !== false) && (
        <>
          <InfoBlock
            title={`Payor # ${props.payor?.number}`}
            headerInfo={getContactPhoneComponent(props.payor?.phone)}
            className="xgs-info-block--centered"
          >
            {props.payor?.info && (
              <div className="common-text pre-wrap">
                {(props.payor.info)}
              </div>
            )}
            {!props.payor?.info && <BlockPlaceholder />}
          </InfoBlock>
          <InfoBlock
            title={`From: Shipper # ${props.shipper?.number}`}
            headerInfo={getContactPhoneComponent(props.shipper?.phone)}
            className="xgs-info-block--centered"
          >
            {props.shipper?.info && (
              <div className="common-text pre-wrap">
                {props.shipper?.info}
              </div>
            )}
            {!props.shipper?.info && <BlockPlaceholder />}
          </InfoBlock>
          <InfoBlock
            title={`To: Consignee # ${props.consignee?.number}`}
            headerInfo={getContactPhoneComponent(props.consignee?.phone)}
            className="xgs-info-block--centered"
          >
            {props.consignee?.info && (
              <div className="common-text pre-wrap">
                {props.consignee.info}
              </div>
            )}
            {!props.consignee?.info && <BlockPlaceholder />}
          </InfoBlock>
          {(props.status?.title !== "BOOKED") && (
            <InfoBlock title={UserUtils.isEmployee(userState.profile) ? "Trailer & Driver" : "Trailer & Manifest"}>
              {(props.trailer ||
                props.cheetahInfo?.driverName ||
                props.cheetahInfo?.driverNumber ||
                props.manifest?.linehaul ||
                props.manifest?.delivery ||
                isOutForDelivery()) && (
                <>
                  {props.trailer && <LabeledValue label="Trailer:" value={props.trailer} labelFixWidth />}
                  {UserUtils.isEmployee(userState.profile) && (props.cheetahInfo?.driverName || props.cheetahInfo?.driverNumber) && (
                    <LabeledValue label="Driver:" value={`${props.cheetahInfo?.driverName ? props.cheetahInfo.driverName : ""} ${props.cheetahInfo?.driverNumber ? props.cheetahInfo.driverNumber : ""}`} labelFixWidth />
                  )}
                  <LabeledValue
                    label="Linehaul manifest:"
                    value={props.manifest?.linehaul}
                    labelFixWidth
                    centered
                    optional
                  />
                  <LabeledValue
                    label="Delivery manifest:"
                    value={props.manifest?.delivery}
                    labelFixWidth
                    centered
                    optional
                  />
                  {
                    isOutForDelivery() &&
                    props.trailer &&
                    props.showOnMap &&
                    <TrailerLocation invoiceNumber={props.invoice?.invoiceNumber} />
                  }
                </>
              )}
              {!props.trailer &&
                !props.cheetahInfo?.driverName &&
                !props.cheetahInfo?.driverNumber &&
                !props.manifest?.linehaul &&
                !props.manifest?.delivery &&
                !isOutForDelivery() && <BlockPlaceholder />
              }
            </InfoBlock>
          )}
        </>
      )}
    </InfoGrid>
  );
};

export default InvoiceDetailsInfo;
