import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../../ui-components/table/table";
import {
  clearPickupLog,
  getPickupLog,
  pickupDetailsSelector
} from "../../../slices/pickup/pickupDetailsSlice";
import { HUMAN_FRIENDLY_ACTIONS } from "../../pickup/common";

import "./pickupDetails.scss";

export interface PickupLogProps {
  pickupId: string;
  onRescheduled: (arg: string | null) => void;
};

const PickupDetailsLog: React.FC<PickupLogProps> = ({onRescheduled, ...props}) => {
  const dispatch = useDispatch();
  const pickupDetailsState = useSelector(pickupDetailsSelector);

  const columns = [
    {
      width: 140,
      Header: "Event Time",
      accessor: "actionExecutedTime",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value && (
            <div className="xgs-pickup-details__log__date">
              <div>{cellProps.value.toUiDateFormat()}</div> <div className="xgs-pickup-details__log__gray-value">{cellProps.value.toUiTimeShortFormat()}</div>
            </div>
          )}
        </>
      )
    },
    {
      width: 208,
      Header: "User",
      accessor: "userExecuted"
    },
    {
      width: 160,
      Header: "Action",
      accessor: "actionPerformed",
      Cell: (cellProps: any) => (
        <>{!!cellProps.value && (HUMAN_FRIENDLY_ACTIONS[cellProps.value] ? HUMAN_FRIENDLY_ACTIONS[cellProps.value] : cellProps.value)}</>
      )
    },
    {
      width: 170,
      Header: "Additional Info",
      id: "additionalInfo",
      Cell: ({ row }: any) => (
        <>
          {row.original.notes && (
            <span className="xgs-pickup-details__log__additional__item">
             {row.original.notes}
            </span>
          )}
          {row.original.driverName && (
            <span className="xgs-pickup-details__log__additional__item">
             {row.original.driverName} {row.original.driverId ? (<span className="xgs-pickup-details__log__gray-value">({row.original.driverId})</span>) : ""}
            </span>
          )}
          {!row.original.driverName && row.original.driverId && (
            <span className="xgs-pickup-details__log__additional__item">
             {row.original.driverId}
            </span>
          )}
          {row.original.pickupNumber && (
            <>
             {row.original.pickupNumber}
            </>
          )}
        </>
      )
    }
  ];

  useEffect(() => {
    if (!props.pickupId) return;
    dispatch(getPickupLog(props.pickupId));
  }, [props.pickupId, dispatch]);

  useEffect(() => {
    if (!pickupDetailsState.pickupLog) return;
    let rescheduledEntry = pickupDetailsState.pickupLog.find(entry => entry.actionPerformed === "RESCHEDULED_TO");
    onRescheduled(rescheduledEntry ? rescheduledEntry.pickupNumber : null);
  }, [pickupDetailsState.pickupLog, onRescheduled]);

  useEffect(() => {
    return () => {dispatch(clearPickupLog())};
  }, [dispatch]);

  return (
    <div className="xgs-pickup-details__log">
      <Table
        isLoading={pickupDetailsState.requestStarted && (pickupDetailsState.requestCreator === "GET_PICKUP_LOG")}
        columns={columns}
        data={pickupDetailsState.pickupLog}
        rowHeight={48}
        minTableHeight={220}
        noResultsText="No entries"
      />
    </div>
  );
};

export default PickupDetailsLog;
