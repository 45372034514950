import React, { useEffect, useState } from "react";
import { AddLane } from "./addLane";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import { LinehaulActivity, LinehaulLane } from "../../app/data/linehaul-mileage/models";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import styles from "../../sass/variables.module.scss";
import Table from "../../ui-components/table/table";
import { getActivityLogColumns } from "./activityHistoryColumns";
import editIcon from "../../images/edit_blue.svg";
import deleteIcon from "../../images/bin_white.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteLinehaulLane, linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import { toast } from "react-toastify";
import LinehaulMileageService from "../../app/data/linehaul-mileage/linehaulMileageService";

export const LaneDetails: React.FC<{ lane?: LinehaulLane, show: boolean, onClose: any }> = (props) => {
  const [editingLane, setEditingLane] = useState(false);
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const dispatch = useDispatch();
  const [activity, setActivity] = useState<LinehaulActivity[]>([]);
  const [loadingActivity, setLoadingActivity] = useState(false);

  const fetchActivity = async () => {
    setLoadingActivity(true)
    const response = await LinehaulMileageService.getInstance().getLaneActivity(props.lane?.laneId!);
      setLoadingActivity(false)
      if (response.ok()) {
        setActivity(response.data);
      } else {
        toast.error("Could not find activity log");
      }
  }

  useEffect(() => {
    if (!props.lane?.laneId) return;
    const fetch = async () => {
      const response = await LinehaulMileageService.getInstance().getLaneActivity(props.lane?.laneId!);
      setLoadingActivity(false)
      if (response.ok()) {
        setActivity(response.data);
      } else {
        toast.error("Could not find activity log");
      }
    }
    setLoadingActivity(true)
    fetch();
  }, [props.lane?.laneId, props.show])

  useEffect(() => {
    setEditingLane(false);
  }, [props.lane])

  return (
    <SlideOutSidebar header="Lane Details" show={props.show} onClose={props.onClose}>
      <div className="xgs-lmi__details">
        <div className="xgs-lmi__title">
          {props.lane?.laneName}
          <Button
            theme={ButtonThemes.white}
            onClick={() => setEditingLane(true)}
            spinner={linehaulMileageState.requestStarted}
            style={{ marginLeft: "auto", color: styles.blue1 }}
          >
            <img src={editIcon} alt="edit"/>&nbsp; Edit Lane
          </Button>
          <Button
            theme={ButtonThemes.red}
            spinner={linehaulMileageState.requestStarted}
            style={{ width: linehaulMileageState.requestStarted ? 160 : 140 }}
            onClick={() => {
              fetchActivity();
              dispatch(
                deleteLinehaulLane(
                  props.lane?.laneId!,
                  () => {
                    props.onClose();
                    toast.info("Lane deleted");
                  }
                ))
              }
            }
          >
            <img src={deleteIcon} alt="edit"/>&nbsp; Delete Lane
          </Button>
        </div>
        <div className="xgs-lmi__details__block">
          <div className="xgs-lmi__details__block-title">Route Summary</div>
          <div className="xgs-lmi__details__header">
            <div className="xgs-lmi__details__header__item">
              <label>Origin:</label>
              <div>{props.lane?.originTerminalName}, {props.lane?.originTerminalAirportcode} <span>{props.lane?.originTerminalNumber}</span></div>
            </div>
            <div className="xgs-lmi__details__header__item">
              <label>Destination:</label>
              <div>{props.lane?.destinationTerminalName}, {props.lane?.destinationTerminalAirportcode} <span>{props.lane?.destinationTerminalNumber}</span></div>
            </div>
           {!!props.lane?.relayCity && <div className="xgs-lmi__details__header__item">
              <label>Relay:</label>
              <div>{props.lane?.relayCity}, {props.lane?.relayState}, <span>{props.lane?.relayZipcode}</span></div>
            </div>}
            <div className="xgs-lmi__details__header__item">
              <label>Miles:</label>
              <div>{props.lane?.miles}</div>
            </div>
          </div>
        </div>
        <div className="xgs-lmi__details__block">
          <div className="xgs-lmi__details__block-title">Activity History</div>
          <Table
            columns={getActivityLogColumns()}
            data={activity}
            isLoading={loadingActivity}
            rowHeight={110}
            infiniteScroll
          />
        </div>
      </div>
      {!!editingLane && <AddLane
        show={!!editingLane} 
        closeModal={() => {
          setEditingLane(false);
          fetchActivity();
        }}
        isEditing={true}
        savedValues={{
          ...props.lane,
          relay: !!props.lane?.relayState
        }}
        laneId={props.lane?.laneId}
      />}
    </SlideOutSidebar>
  )
}