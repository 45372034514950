import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import LabeledInput from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Help from "../../../ui-components/help/help";
import Tag from "../../../ui-components/molecules/tag/tag";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";
import { TagColor } from "../../../app/data/common/tagColor";
import {
  AnnouncementModel,
  AnnouncementResponseModel,
  AnnouncementSchema
} from "../../../app/data/announcement/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import AnnouncementState from "../../../slices/announcement/AnnouncementState";
import {
  announcementSelector,
  createAnnouncement,
  getAnnouncements,
  resetAnnouncement,
  resetErrors,
  updateAnnouncement
} from "../../../slices/announcement/announcementSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { FEATURE } from "../../../app/route/RoutesConfig";
import "../../../sass/forms.scss";
import "./announcementForm.scss";

let initialValues: AnnouncementModel = {
  id: "",
  header: "",
  body: "",
  needUserAcceptance: false,
  from: "",
  to: "",
  status: ""
};

const quillModules = {
  clipboard: {
    matchVisual: false
  },
  toolbar: [
    ["bold", "italic", "underline"],
    [{"list": "bullet"}],
    ["link"],
    ["clean"]
  ]
};

interface AnnouncementFormProps {
  type: "CUSTOMER" | "EMPLOYEE";
};

const AnnouncementForm: React.FC<AnnouncementFormProps> = (props) => {
  const dispatch = useDispatch();
  const announcementState: AnnouncementState = useSelector(announcementSelector);
  const userState: UserState = useSelector(userSelector);
  const [body, setBody] = useState("");
  const [from, setFromDate] = useState<string>("");
  const [to, setToDate] = useState<string>("");
  const [turnOffConfirmOpen, setTurnOffConfirmOpen] = useState<boolean>(false);
  const [announcement, setAnnouncement] = useState<AnnouncementResponseModel>();
  const announcementFormRef = useRef<any>(null);

  const onSubmit = (request: AnnouncementModel) => {
    if (request.from) request.from = request.from.toApiDateFormat();
    if (request.to) request.to = request.to.toApiDateFormat();
    if (!request.recipientType) request.recipientType = props.type;
    if (announcement?.id) {
      dispatch(updateAnnouncement(request, () => {
        toast.info("The announcement has been updated!");
      }));
    } else {
      dispatch(createAnnouncement(request, () => {
        toast.info("The announcement has been created and published!");
      }));
    }
  };

  const onCompleteAnnouncement = () => {
    if (!announcement?.id) return;
    let request = {
      id: announcement.id,
      header: "",
      body: "",
      needUserAcceptance: false,
      from: "",
      to: "",
      status: "COMPLETED",
      recipientType: props.type
    }
    dispatch(updateAnnouncement(request, () => {
      toast.info("The announcement has been turned off!");
      dispatch(resetAnnouncement(props.type));
      setTurnOffConfirmOpen(false);
      announcementFormRef.current.resetForm();
    }));
  };

  const valuesWereChanged = (formValues: AnnouncementModel) => {
    let storedValues = {
      header: announcement?.header,
      body: announcement?.body,
      needUserAcceptance: announcement?.needUserAcceptance,
      from: announcement?.from ? announcement.from.toUiDateFormat() : "",
      to: announcement?.to ? announcement.to.toUiDateFormat() : ""
    };
    return storedValues.header !== formValues.header ||
      storedValues.body !== formValues.body ||
      storedValues.needUserAcceptance !== formValues.needUserAcceptance ||
      storedValues.from !== (formValues.from || "") ||
      storedValues.to !== (formValues.to || "");
  };

  const prepopulateFields = useCallback(() => {
    announcementFormRef.current.setFieldValue("id", announcement?.id || "");
    announcementFormRef.current.setFieldValue("header", announcement?.header || "");
    announcementFormRef.current.setFieldValue("body", announcement?.body || "", false);
    setBody(announcement?.body || "");
    announcementFormRef.current.setFieldValue("needUserAcceptance", announcement?.needUserAcceptance || false);
    announcementFormRef.current.setFieldValue("from", announcement?.from ? announcement.from.toUiDateFormat() : "");
    setFromDate(announcement?.from ? announcement.from.toUiDateFormat() : "");
    announcementFormRef.current.setFieldValue("to", announcement?.to ? announcement.to.toUiDateFormat() : "");
    setToDate(announcement?.to ? announcement.to.toUiDateFormat() : "");
    announcementFormRef.current.setFieldValue("status", announcement?.status || "");
    announcementFormRef.current.setFieldValue("recipientType", props.type);
  }, [announcement, props.type]);

  useEffect(() => {
    dispatch(getAnnouncements((announcements: AnnouncementResponseModel[]) => {
      setAnnouncement(announcements.find(announcement => announcement.recipientType === props.type));
    }));

    return () => {
      dispatch(resetErrors());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!announcementState.announcements || announcementState.announcements.length === 0) return;
    setAnnouncement(announcementState.announcements.find(announcement => announcement.recipientType === props.type));
  }, [announcementState.announcements, props.type]);

  useEffect(() => {
    prepopulateFields();
  }, [prepopulateFields]);

  const previewBlock = (formValues: AnnouncementModel) => (
    <div className="xgs-announcement__form__preview__area" key={props.type + "-form"}>
      <div className="xgs-announcement__form__preview__header">
        {formValues.header}
      </div>
      <div
        className="xgs-announcement__form__preview__body ql-editor"
        dangerouslySetInnerHTML={{__html: body}}>
      </div>
      <div className="xgs-announcement__form__preview__buttons">
        <Button
          theme={ButtonThemes.blue}
          onClick={() => {}}
          className="xgs-modal__button"
          type="button"
        >
          {formValues.needUserAcceptance ? "Accept" : "Ok"}
        </Button>
        {formValues.needUserAcceptance && (
          <Button
            theme={ButtonThemes.gray}
            onClick={() => {}}
            className="xgs-modal__button"
            type="button"
          >
            Log Out
          </Button>
        )}
      </div>
    </div>
  );

  const isAnnouncementsManagementEnabled = !UserUtils.hasFeatureDisabled(userState.profile, FEATURE.ANNOUNCEMENTS);

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={AnnouncementSchema}
        innerRef={announcementFormRef}
      >
        {(props: FormikProps<AnnouncementModel>) => (
          <Form className="xgs-announcement__form">
            <div className="xgs-announcement__form__block xgs-announcement__form__controls">
              <div className="xgs-announcement__form__block__help">
                <Help header="Settings Help">
                  <div className="help__description">The meaning of the settings is described below to help you understand them.</div>
                  <div className="help__item"><strong>Display period</strong> - date range when you want to display the announcement to users. If left blank, it will be displayed immediately and until it is turned off or a date range is set.</div>
                  <div className="help__item"><strong>User acceptance required</strong> - set if you want the users to additionally confirm the acceptance of the announcement by clicking on the corresponding button.</div>
                </Help>
              </div>
              {!isAnnouncementsManagementEnabled && !(announcementState.requestStarted && announcementState.requestCreator === "GET_DATA") && (
                <>
                  {!announcement?.id && (
                    <div className="xgs-announcement__form__notes">
                      <strong>There is no active announcement at the moment.</strong>
                    </div>
                  )}
                  <div className="xgs-announcement__form__notes">
                    If you want to publish {announcement?.id ? " new or edit the current" : " the"} announcement, you need to contact your administrator.
                  </div>
                </>
              )}
              {(isAnnouncementsManagementEnabled || announcement?.id) && (
                <>
                  <div className="xgs-announcement__form__section">
                    <XGSFormInput
                      type="text"
                      name="header"
                      label="Title:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                      className="xgs-announcement__form__field"
                      disabled={!isAnnouncementsManagementEnabled}
                    />
                    <div className="xgs-announcement__form__field">
                      <LabeledInput
                        label="Message:"
                        labelMode={LabelModes.column}
                        required={true}
                        requiredAsteriskDisabled={false}
                        isFailed={() => !!(props.touched.body && props.errors.body)}
                        error={props.errors.body || ""}
                        className="xgs-visual-editor"
                      >
                        {isAnnouncementsManagementEnabled && (
                          <ReactQuill
                            theme="snow"
                            value={body}
                            modules={quillModules}
                            onChange={(value, delta, source, editor) => {
                              const text = editor.getText().replace(/(\r\n|\n|\r)/gm, "");
                              if (text) {
                                setBody(value);
                                props.setFieldValue("body", value);
                              } else {
                                setBody("");
                                props.setFieldValue("body", "");
                              }
                              props.setFieldTouched("body", true, false);
                            }}
                            onBlur={() => {
                              props.setFieldTouched("body", true, false);
                            }}
                          />
                        )}
                        {!isAnnouncementsManagementEnabled && (
                          <div
                            className="xgs-announcement__form__field__text"
                            dangerouslySetInnerHTML={{__html: body}}>
                          </div>
                        )}
                      </LabeledInput>
                    </div>
                    {announcement?.id && isAnnouncementsManagementEnabled && (
                      <div className="xgs-announcement__form__notes xgs-announcement__form__notes--red">
                        Note: making changes to the content will reset user acceptance statuses!
                      </div>
                    )}
                  </div>
                  <div className="xgs-line"></div>
                  <div className="xgs-announcement__form__section">
                    <LabeledDateRangeInput
                      label="Display period:"
                      labelMode={LabelModes.column}
                      className="xgs-announcement__form__field"
                      start={from}
                      end={to}
                      minDate={new Date(moment().format("MM/DD/YYYY"))}
                      onStartChange={(value) => {
                        setFromDate(value);
                        props.setFieldValue("from", value);
                      }}
                      onEndChange={(value) => {
                        setToDate(value);
                        props.setFieldValue("to", value);
                      }}
                      disabled={!isAnnouncementsManagementEnabled}
                    />
                    <div className="xgs-announcement__form__field xgs-announcement__form__field--toggle">
                      <XGSToggle
                        onChange={(checked) => props.setFieldValue("needUserAcceptance", checked)}
                        checked={props.values.needUserAcceptance}
                        disabled={
                          !isAnnouncementsManagementEnabled ||
                          (announcementState.requestStarted && (
                            announcementState.requestCreator === "SAVE" ||
                            announcementState.requestCreator === "GET_DATA" ||
                            announcementState.requestCreator === "TURN_OFF"
                          ))
                        }
                        label="User acceptance required"
                      />
                    </div>
                  </div>
                </>
              )}
              {announcementState.requestFailed && (
                <XGSErrorMessage className="xgs-announcement__form__error">{announcementState.requestError}</XGSErrorMessage>
              )}
              {isAnnouncementsManagementEnabled && (
                <div className="xgs-announcement__form__buttons xgs-announcement__form__buttons--main">
                  <Button
                    theme={ButtonThemes.blue}
                    className="xgs-announcement__form__button xgs-announcement__form__button--save"
                    disabled={!valuesWereChanged(props.values) || !props.isValid || !props.dirty || (announcementState.requestStarted && announcementState.requestCreator === "TURN_OFF")}
                    spinner={announcementState.requestStarted && announcementState.requestCreator === "SAVE" && !turnOffConfirmOpen}
                  >
                    {announcement?.id ? "Save changes" : "Publish"}
                  </Button>
                  <Button
                    theme={ButtonThemes.gray}
                    className="xgs-announcement__form__button"
                    onClick={prepopulateFields}
                    disabled={
                      !valuesWereChanged(props.values) ||
                      (announcementState.requestStarted &&
                        (announcementState.requestCreator === "SAVE" ||
                          announcementState.requestCreator === "GET_DATA" ||
                          announcementState.requestCreator === "TURN_OFF"
                        )
                      )
                    }
                    type="button"
                  >
                    Discard changes
                  </Button>
                  {announcement?.id && (
                    <Button
                      theme={ButtonThemes.redInverted}
                      className="xgs-announcement__form__button xgs-announcement__form__button--delete"
                      onClick={() => setTurnOffConfirmOpen(true)}
                      type="button"
                      disabled={announcementState.requestStarted && (announcementState.requestCreator === "SAVE" || announcementState.requestCreator === "GET_DATA")}
                    >
                      Turn off
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="xgs-announcement__form__block xgs-announcement__form__preview">
              {(props.values.header || body) && isAnnouncementsManagementEnabled && (
                <>
                  {announcement?.status && (
                    <Tag
                      mods={{color: announcement.status === "ACTIVE" ? TagColor.GREEN : (announcement.status === "PENDING" ? TagColor.BLUE : TagColor.GREY)}}
                      mix="xgs-announcement__form__block__status"
                    >
                      {announcement.status}
                    </Tag>
                  )}
                  {previewBlock(props.values)}
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        opened={turnOffConfirmOpen}
        header="Turn off announcement"
        confirmButtonText="Turn Off"
        spinner={announcementState.requestStarted && announcementState.requestCreator === "SAVE"}
        onCancel={() => setTurnOffConfirmOpen(false)}
        onConfirm={() => onCompleteAnnouncement()}
      >
        This announcement will be turned off as completed.<br />
        It cannot be restored after this action.
      </ConfirmationModal>
    </>
  );
};

export default AnnouncementForm;
