import { ItemModel } from "./models";

// Formik has issue when passing undefined values (https://github.com/formium/formik/issues/2332), so we passing empty strings
export const freightItemValues: ItemModel = {
  packageType: "ROLL",
  units: 1,  
  yards: "",
  weight: "",
  length: "",
  width: "",
  height: "",
  commodityCode: "",
  ratingClass: "",
  sidemark: "",
  notes: "",
  description: "",
}