import React from "react";
import { useSelector } from "react-redux";
import Summary from "../../ui-components/summary/summary";
import { SummaryItemColor } from "../../ui-components/summary/summary-tile/summaryTile";
import { dockLoadingSelector } from "../../slices/dock-loading/dockLoadingSlice";
import WeightIcon from "../../images/weight_blue.svg";
import BoxIcon from "../../images/box.svg";
import PersonIcon from "../../images/person_blue.svg";
import BuildingIcon from "../../images/building_blue.svg";
import DocumentIcon from "../../images/paper_blue.svg";
import ClockIcon from "../../images/clock_red.svg";

const DockLoadingSummary: React.FC<{}> = (props) => {
  const dockLoadingState = useSelector(dockLoadingSelector);

  const getSummary = () => (
    [{
      value: dockLoadingState.summary?.totalLoadedWeight ?? "-",
      description: "Total weight",
      icon: <img src={WeightIcon} alt="Weight" />,
      color: SummaryItemColor.blue
    },
    {
      value: dockLoadingState.summary?.totalLadedPieces ?? "-",
      description: "Total items",
      icon: <img src={BoxIcon} alt="Box" />,
      color: SummaryItemColor.blue
    },
    {
      value: dockLoadingState.summary?.numberOfInvolvedUser ?? "-",
      description: "Involved users",
      icon: <img src={PersonIcon} alt="Person" />,
      color: SummaryItemColor.blue
    },
    {
      value: dockLoadingState.summary?.numberOfDoorsLoading ?? "-",
      description: "Number of doors",
      icon: <img src={BuildingIcon} alt="Building" />,
      color: SummaryItemColor.blue
    },
    {
      value: dockLoadingState.summary?.totalLoadedProbillCount ?? "-",
      description: "Number of probills",
      icon: <img src={DocumentIcon} alt="Probill" />,
      color: SummaryItemColor.blue
    },
    {
      value: dockLoadingState.summary?.totalAtRiskProbillsCount ?? "-",
      description: "At Risk Probills",
      icon: <img src={ClockIcon} alt="Box" />,
      color: SummaryItemColor.red
    }
  ]);

  return <Summary data={getSummary()} isLoading={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOCK_LOADING"}/>;
};

export default DockLoadingSummary;