import React from "react";
import { useHistory } from "react-router-dom";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { Routes } from "../../../app/route/RoutesConfig";
import "./pickupManagement.scss";

export interface PickupResultBlockProps {
  pickupNumber: string;
};

const PickupOuterResultBlock: React.FC<PickupResultBlockProps> = (props) => {
  const history = useHistory();

  return (
    <div className="xgs-pickup-assignment__outer-pickup-block">
      <div>
        <p>The pickup with number {props.pickupNumber} was found without using filters and without taking into account the selected terminal, but it cannot be displayed within the&nbsp;pickup&nbsp;assignment&nbsp;process.</p>
        <p>To view information about this pickup, click &quot;View Pickup&quot;.</p>
      </div>
      <Button
        theme={ButtonThemes.blue}
        type="submit"
        className="xgs-pickup-assignment__outer-pickup-block__button"
        onClick={() => history.push(`${Routes.pickup.log}/${props.pickupNumber}`)}
      >
        View Pickup
      </Button>
    </div>
  );
};

export default PickupOuterResultBlock;
