import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer"
import React, { useState } from "react";
import { fetchInboundPickups, fetchInboundPickupsInfinite, inboundPickupSelector } from "../../../slices/pickup/inboundPickupSlice";
import Table from "../../../ui-components/table/table";
import { getInboundPickupColumns } from "./getInboundPickupColumns";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { InboundPickupsFilter } from "./filter/filter";
import { PickupsResponseContentModel } from "../../../app/data/pickup/models";
import PickupDetails from "../../common/pickup-details/pickupDetails";
import { getPickupDetails } from "../../../services/pickups";
import "./inboundPickups.scss";

const initialFilterState = {
  originTerminalCode: 1,
  status: [],
  dest: null
}

export const InboundPickups = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(initialFilterState);
  const inboundPickupState = useSelector(inboundPickupSelector);
  const [selectedPickup, setSelectedPickup] = useState<PickupsResponseContentModel | null>(null);

  const onInfiniteScroll = () => {
    dispatch(fetchInboundPickupsInfinite(filters.originTerminalCode, filters.dest, filters.status, inboundPickupState.totalPages + 1));
  }

  const onSearch = (filters: any) => {
    setFilters(filters)
    dispatch(fetchInboundPickups(filters.originTerminalCode, filters.dest, filters.status));
  }

  const loadingFailed = inboundPickupState.loadingPickupsError || inboundPickupState.infiniteLoadingError;

  const pickupDetails = selectedPickup && getPickupDetails(selectedPickup);

  return (
    <ContentContainer mods={{ "full-width": true }} title="Outbound Linehaul Planning">
      {loadingFailed && <XGSErrorMessage>{inboundPickupState.loadingPickupsError || inboundPickupState.infiniteLoadingError}</XGSErrorMessage>}
      {!loadingFailed && (
        <div className="xgs-inbound-pickups">
          <InboundPickupsFilter 
            onSearch={onSearch}
          />
          <Table
            columns={getInboundPickupColumns()}
            data={inboundPickupState.pickups}
            isLoading={inboundPickupState.loadingPickups}
            infiniteScroll
            cursorPointer
            onInfiniteScroll={onInfiniteScroll}
            infiniteScrollHasNext={inboundPickupState.infiniteLoadingHasNext}
            infiniteScrollLoading={inboundPickupState.infiniteLoading}
            rowHeight={56}
            responsive
            minTableHeight={320}
            onRowClicked={(data: any) => {
              setSelectedPickup(data);
            }}
          />
        </div>
      )}
      <PickupDetails
        readonly
        isOpen={!!selectedPickup}
        pickup={pickupDetails}
        onClose={() => setSelectedPickup(null)}
        hideLogs
      />
    </ContentContainer>
  )
}
