import api, { ApiResponse } from "./../api";

class CommonService {
  private static instance: CommonService;
  private constructor() {}
  static getInstance(): CommonService {
    if (!CommonService.instance) {
      CommonService.instance = new CommonService();
    }
    return CommonService.instance;
  }

  public searchLocations = async (
    query: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/rate-quote/zip-codes?search=${encodeURIComponent(query)}`);
  };

  public getServiceCenterDays = async (
    zip: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/transit/routing-guide?zip=${zip}`);
  };

  public getFreightClasses = async (
    zip: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/freight-classes?zip=${zip}`);
  };

  public getProbillDetails = async (
    search: string,
    searchKey: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/probills/search?search=${encodeURIComponent(search)}&searchKey=${searchKey}`);
  };

  public getCC = async (): Promise<ApiResponse<any>> => {
    return await api.get("/rate-quote/commodity-codes");
  };
}

export default CommonService;
