import React from "react";
import Tag from "../../../ui-components/molecules/tag/tag";
import { getStatusColor } from "../../../services/pickups";

export const getInboundPickupColumns = () => [
  {
    Header: "Pickup #",
    accessor: "pickup.pickupNumber",
    width: 120,
    Cell: (props: any) => (
      <div className="blue-link">
        {props.value}
      </div>
    )
  },
  {
    Header: "Consignee",
    accessor: "pickupFullData.pickupRequest.consigneeName",
    width: 120
  },
  {
    Header: "Shipper",
    accessor: "pickupFullData.pickupRequest.shipperName",
    width: 120
  },
  {
    Header: "Shipper address",
    accessor: "shipper",
    width: 292,
    Cell: (cellProps: any) => (
      <>{cellProps.value.address.address1}, {cellProps.value.address.city}, {cellProps.value.address.postalCode}</>
    )
  },
  {
    Header: "Destination Terminal",
    accessor: "destinationServiceCenter",
    width: 200,
    Cell: (cellProps: any) => (
      cellProps.value ? <>
        {cellProps.value?.airportCode} - {cellProps.value?.terminalName?.toUpperCase()} <span style={{ color: "rgba(79, 95, 114, 0.72)" }}>{cellProps.value?.terminalNumber}</span>
      </> : <></>
    )
  },
  {
    Header: "Items",
    accessor: "items.count",
    width: 60
  },
  {
    Header: "Weight",
    accessor: "items.weight",
    width: 80
  },
  {
    Header: "Driver",
    accessor: "pickup.driverName",
    width: 120
  },
  {
    Header: "Status",
    accessor: "pickup.pickupStatus",
    width: 130,
    Cell: (cellProps: any) =>  (
      <Tag mods={{color: getStatusColor(cellProps.value), uppercase: true}}>
        { cellProps.value }
      </Tag>
    )
  }
]