import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import SubAccount from "../../app/data/account/SubAccount";
import { PickupRequestModel } from "../../app/data/pickup/models";
import BolService from "../../app/data/bol/bolService";
import PickupService from "../../app/data/pickup/pickupService";
import CompanyService from "../../app/data/company/companyService";
import TrackingService from "../../app/data/tracking/trackingService";
import { initialPickupState } from "./PickupState";

const bolService = BolService.getInstance();
const companyService = CompanyService.getInstance();
const pickupService = PickupService.getInstance();
const trackingService = TrackingService.getInstance();

export const pickupSlice = createSlice({
  name: "pickup",
  initialState: initialPickupState,
  reducers: {
    resetPickupState: (state) => initialPickupState,
    setTeamName: (state, { payload }) => {
      state.teamName = payload;
    },
    setShipper: (state, { payload }) => {
      state.teamId = payload.teamId;
      state.shipper.id = payload.shipper.id;
      state.shipper.accountNumber = Number(payload.shipper.accountNumber);
      state.shipper.name = payload.shipper.name;
      state.shipper.address = {
        address1: payload.shipper.address?.address1,
        city: payload.shipper.address?.city,
        postalCode: payload.shipper.address?.postalCode,
        state: payload.shipper.address?.state,
        additionalAddressLine: payload.shipper.address?.additionalAddressLine,
      };
      state.bolNumber = payload.bolNumber;
      state.poNumber = payload.poNumber;
      state.additionalReference = payload.additionalReference;
      state.specialInstructions = payload.specialInstructions;
      state.pickupDate = payload.pickupDate;
      state.pickupReadyTime = payload.pickupReadyTime;
      state.pickupCloseTime = payload.pickupCloseTime;
      state.shipper.contacts = {
        firstName: payload.shipper.contacts.firstName,
        lastName: payload.shipper.contacts.lastName,
        mobilePhone: payload.shipper.contacts.mobilePhone,
        allowSms: payload.shipper.contacts.allowSms,
        email: payload.shipper.contacts.email
      };
    },
    setConsignee: (state, { payload }) => {
      state.consignee.name = payload.name;
      state.consignee.address.address1 = payload.address?.address1;
      state.consignee.address.city = payload.address?.city;
      state.consignee.address.postalCode = payload.address?.postalCode;
      state.consignee.address.state = payload.address?.state;
      state.consignee.address.additionalAddressLine = payload.address?.additionalAddressLine;
      state.consignee.phone = payload.phone;
      state.consignee.email = payload.email;
    },
    addItem: (state, { payload }) => {
      state.items.push({
        packageType: payload.packageType,
        units: payload.units ? parseFloat(payload.units) : undefined,
        yards: payload.yards ? parseFloat(payload.yards) : undefined,
        weight: payload.weight ? parseFloat(payload.weight) : undefined,
        length: payload.length ? parseFloat(payload.length) : undefined,
        width: payload.width ? parseFloat(payload.width) : undefined,
        height: payload.height ? parseFloat(payload.height) : undefined,
        ratingClass: payload.ratingClass,
        sidemark: payload.sidemark,
        notes: payload.notes,
        deleted: false,
        commodityCode: payload.commodityCode,
      });
    },
    updateItem: (state, { payload }) => {
      const i = payload.index;
      state.items[i].packageType = payload.data.packageType;
      state.items[i].units = payload.data.units ? parseFloat(payload.data.units) : undefined;
      state.items[i].yards = payload.data.yards ? parseFloat(payload.data.yards) : undefined;
      state.items[i].weight = payload.data.weight ? parseFloat(payload.data.weight) : undefined;
      state.items[i].length = payload.data.length ? parseFloat(payload.data.length) : undefined;
      state.items[i].width = payload.data.width ? parseFloat(payload.data.width) : undefined;
      state.items[i].height = payload.data.height ? parseFloat(payload.data.height) : undefined;
      state.items[i].ratingClass = payload.data.ratingClass;
      state.items[i].sidemark = payload.data.sidemark;
      state.items[i].notes = payload.data.notes;
      state.items[i].commodityCode = payload.data.commodityCode;
    },
    updatePickupNumber: (state, { payload }) => {
      state.pickupNumber = payload.pickupNumber
    },
    deleteItem: (state, { payload }) => {
      state.items[payload].deleted = true;
    },
    clearDeletedItems: (state) => {
      state.items = state.items.filter(item => !item.deleted);
    },
    clearItems: (state) => {
      state.items = [];
    },
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    clearRequestError: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    setCompanies: (state, { payload }) => {
      state.companies = [];
      state.companies.push(...payload.map((company: any) => {
        return {
          label: company.name,
          value: company.id,
          valueForSearch: company.name
        }
      }));
    },
    clearShippers: (state) => {
      state.shippers = null;
      state.shippersSearch = null;
    },
    setShippers: (state, { payload }) => {
      state.shippers = payload;
    },
    setShippersSearch: (state, { payload }) => {
      state.shippersSearch = payload;
    },
    addShipper: (state, { payload }) => {
      state.shippers?.push(payload);
      state.shippersSearch?.push(payload);
    },
    // updateShipper: () => {
    //   const index = state.shippers.findIndex(shipper.accountNumber === paymentLoadingFailed.accountNumber)
    // },
    setConsignees: (state, { payload }) => {
      state.consignees = payload;
    },
    setConsigneeCheckResult: (state, { payload }) => {
      state.consigneeCheckResult = payload;
    },
    setConsigneeZipChecked: (state, { payload }) => {
      state.consigneeZipChecked = payload;
    },
    setPaypointNumber: (state, { payload }) => {
      state.paypointNumber = payload;
    },
  }
});

export const {
  addItem,
  clearDeletedItems,
  clearItems,
  clearRequestError,
  clearShippers,
  deleteItem,
  requestFailed,
  requestStarted,
  requestSucceed,
  resetPickupState,
  setCompanies,
  setConsignee,
  setConsigneeCheckResult,
  setConsignees,
  setConsigneeZipChecked,
  setShipper,
  setShippers,
  setShippersSearch,
  addShipper,
  setTeamName,
  setPaypointNumber,
  updateItem,
  updatePickupNumber,
} = pickupSlice.actions;

export const pickupSelector = (state: IState) => {
  return state.pickup;
};

export const searchCompany = (
  value: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SEARCH_COMPANY"));
  const response = await trackingService.searchTeams(value);
  if (response.ok()) {
    dispatch(setCompanies(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getShippers = (
  teamId: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_SHIPPERS"));
  const response1 = await companyService.getAllAccounts(teamId);
  const response2 = await companyService.getAllExtraShippers(teamId);
  if (response1.ok() || response2.ok()) {
    dispatch(setShippers([
      ...(response1.data && response1.data.content.filter((obj: SubAccount) => obj.shipper)),
      ...(response2.data && response2.data?.content)
    ]));
    dispatch(setPaypointNumber(response1.data && response1.data.content.find((obj: SubAccount) => obj.paypoint)?.accountNumber));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response1.getError
      ? response1.getError()
      : (response2.getError
        ? response2.getError()
        : "Error")
    ));
  }
};

export const searchShippers = (
  query: string,
  paypointNumber: number,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SEARCH_SHIPPERS"));
  const response = await companyService.searchShippers(query, paypointNumber);
  if (response.ok()) {
    dispatch(setShippersSearch(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getConsignees = (): AppThunk => async (dispatch) => {
  // dispatch(requestStarted("GET_CONSIGNEES"));
  // const response = await pickupService.getConsignees();
  // if (response.ok()) {
  //   dispatch(requestSucceed());
  //   dispatch(setConsignees(response.data));
  // } else {
  //   dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  // }
};

export const checkPickup = (
  zip: string,
  onSuccess: () => void,
  onFailed: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CHECK_PICKUP"));
  const response = await bolService.checkPickup(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    onFailed();
  }
};

export const createPickup = (
  request: PickupRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CREATE_PICKUP"));
  const response = await pickupService.createPickup(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(updatePickupNumber(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const checkConsignee = (
  zip: string,
  onSuccess: () => void,
  onNotFound: () => void,
  onFailed: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CHECK_CONSIGNEE_ZIP"));
  const response = await bolService.checkConsignee(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setConsigneeCheckResult(true));
    dispatch(setConsigneeZipChecked(zip));
    onSuccess();
  } else {
    const error = response.getError ? response.getError() : "Error";
    dispatch(requestFailed(error));
    dispatch(setConsigneeCheckResult(false));
    dispatch(setConsigneeZipChecked(zip));
    if (response.status === 404) {
      onNotFound();
    } else {
      onFailed();
    }
  }
};

const pickupReducer = pickupSlice.reducer;
export default pickupReducer;
