import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import PickupService from "../../app/data/pickup/pickupService";
import { PickupRescheduleModel } from "../../app/data/pickup/models";
import { initialPickupDetailsState } from "./PickupDetailsState";

const pickupService = PickupService.getInstance();

export const pickupDetailsSlice = createSlice({
  name: "pickupDetails",
  initialState: initialPickupDetailsState,
  reducers: {
    resetpickupDetailsState: (state) => initialPickupDetailsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailStatus = payload.status;
      state.requestError = payload.error;
    },   
    setPickupLog: (state, { payload }) => {
      state.pickupLog = payload;
    },
    addPickupLog: (state, { payload }) => {
      state.pickupLog = [...state.pickupLog, ...payload];
    },
    clearPickupLog: (state) => {
      state.pickupLog = [];
    },
    setPickup: (state, { payload }) => {
      state.pickup = payload;
    },
  }
});

export const {
  resetpickupDetailsState,
  requestStarted,
  requestSucceed,
  requestFailed,  
  setPickupLog,
  addPickupLog,
  clearPickupLog,
  setPickup,
} = pickupDetailsSlice.actions;

export const pickupDetailsSelector = (state: IState) => {
  return state.pickupDetails;
};

export const getPickupLog = (
  pickupId: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_PICKUP_LOG"));
  const response = await pickupService.getPickupLog(pickupId);
  if (response.ok()) {
    dispatch(setPickupLog(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const cancelPickup = (
  pickupId: string,
  notes: string,
  onSuccess: () => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CANCEL_PICKUP"));
  const response = await pickupService.cancelPickup({
    pickupId,
    notes
  });
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const reschedulePickup = (
  pickupId: string,
  request: PickupRescheduleModel,
  onSuccess: (newPickupId: string, newPickupNumber: string) => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("RESCHEDULE_PICKUP"));
  const response = await pickupService.reschedulePickup(pickupId, request);

  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess(response.data.newPickupId, response.data.newPickupNumber);
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const completePickup = (
  pickupId: string,
  onSuccess: () => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("COMPLETE_PICKUP"));
  const response = await pickupService.completePickup(pickupId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const getPickup = (
  pickupNumber: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_PICKUP"));
  const response = await pickupService.getPickup(pickupNumber);
  if (response.ok()) {
    dispatch(setPickup(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

const pickupDetailsReducer = pickupDetailsSlice.reducer;
export default pickupDetailsReducer;
