import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { initialDownloadState } from "./DownloadState";

import { ApiResponse } from "../../app/data/api";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";

export const downloadSlice = createSlice({
  name: "download",
  initialState: initialDownloadState,
  reducers: {    
    resetDownload: (state, { payload }) => {
      state[payload] = {        
        downloadStarted: false,
        downloadSucceed: false,
        downloadFailed: false,
      };
    }, 
    downloadStarted: (state, { payload }) => {
      state[payload] = {
        downloadStarted: true,
        downloadSucceed: false,
        downloadFailed: false,
      };      
    },
    downloadSucceed: (state, { payload }) => {
      state[payload] = {
        downloadStarted: false,
        downloadSucceed: true,
      };      
    },
    downloadFailed: (state, { payload }) => {
      state[payload] = {
        downloadStarted: false,
        downloadSucceed: false,
        downloadFailed: true,
      };
    },      
  }
});

export const {
  resetDownload,
  downloadStarted,
  downloadSucceed,
  downloadFailed, 
} = downloadSlice.actions;

export const downloadSelector = (state: IState) => state.download;

export const getDownload = (
  downloadId: string,
  method: () => Promise<ApiResponse<any>>,
  onSuccess: (response: any) => void,
  onError: (error: string) => void,
): AppThunk => async (dispatch) => {
  dispatch(downloadStarted(downloadId));
  const response = await method();
  if (response.ok()) {
    dispatch(downloadSucceed(downloadId));
    onSuccess?.(response.data.uri);
  } else {
    dispatch(downloadFailed(downloadId));
    onError?.(ERROR_MESSAGES.DOWNLOAD);
  }  
};


const downloadReducer = downloadSlice.reducer;
export default downloadReducer;
