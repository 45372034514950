import React, { useEffect } from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchInboundPickupsSummary, inboundPickupSelector, resetInboundPickupsState } from "../../../slices/pickup/inboundPickupSlice";
import buildingIcon from "../../../images/building.svg";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import "./summary.scss";
import { Routes } from "../../../app/route/RoutesConfig";
import { Link } from "react-router-dom";
import { userSelector } from "../../../slices/user/userSlice";
import Table from "../../../ui-components/table/table";
import { getInboundPickupSummaryColumns } from "./getInboundPickupSummaryColumns";

export const InboundPickupSummary: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const inboundPickupState = useSelector(inboundPickupSelector);
  const userState = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchInboundPickupsSummary(userState.activeTerminal?.id!));
    return () => {
      dispatch(resetInboundPickupsState());
    }
  }, [dispatch, userState.activeTerminal?.id])

  return (
    <ContentContainer isLoading={inboundPickupState.loadingSummaries} title="Outbound Linehaul Planning Summary">
      <div className="xgs-pu-summary__tiles-container">
        {!inboundPickupState.summaries.length && !inboundPickupState.loadingSummaries && <div className="xgs-pu-summary__empty">No pickups found for selected terminal.</div>}
        {
          inboundPickupState.summaries.map((summary) => {
            return (
              <div className="xgs-pu-summary__tile">
                <div className="xgs-pu-summary__tile__header">
                  <div className="xgs-pu-summary__tile__icon">
                    <img src={buildingIcon} alt=""/>
                  </div>
                  <span>{summary.serviceCenter.airportCode} - {summary.serviceCenter.terminalName.toUpperCase()} ({summary.serviceCenter.terminalNumber})</span>
                  <div className="xgs-pu-summary__tile__header__button">
                    <Link to={"/" + userState.activeTerminal?.id + Routes.pickup.inboundPickups + "?dest=" + summary.serviceCenter.terminalNumber}>
                      <Button theme={ButtonThemes.blue} >
                        Show Pickups
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="xgs-pu-summary__tile__body xgs-pu-summary__tile__body--large">
                  <Table
                    columns={getInboundPickupSummaryColumns()}
                    rowHeight={32}
                    responsive
                    data={
                      [
                        {type: "No. of Pickups", planned: summary.assignedSummary?.totalPickups, actual: summary.completedSummary?.totalPickups},
                        {type: "No. of Items", planned: summary.assignedSummary?.totalItems, actual: summary.completedSummary?.totalItems},
                        {type: "Weight", planned: summary.assignedSummary?.totalWeight, actual: summary.completedSummary?.totalWeight},
                        {type: "No. of Drivers", planned: summary.assignedSummary?.totalDrivers, actual: summary.completedSummary?.totalDrivers},
                      ]
                    }
                  />
                </div>
                <div className="xgs-pu-summary__tile__body xgs-pu-summary__tile__body--small">
                  <Table
                    columns={getInboundPickupSummaryColumns()}
                    rowHeight={32}
                    responsive
                    data={
                      [
                        {type: "Pickups", planned: summary.assignedSummary?.totalPickups, actual: summary.completedSummary?.totalPickups},
                        {type: "Items", planned: summary.assignedSummary?.totalItems, actual: summary.completedSummary?.totalItems},
                        {type: "Weight", planned: summary.assignedSummary?.totalWeight, actual: summary.completedSummary?.totalWeight},
                        {type: "Drivers", planned: summary.assignedSummary?.totalDrivers, actual: summary.completedSummary?.totalDrivers},
                      ]
                    }
                  />
                  <div className="xgs-pu-summary__tile__body__button">
                    <Link to={"/" + userState.activeTerminal?.id + Routes.pickup.inboundPickups + "?dest=" + summary.serviceCenter.terminalNumber}>
                      <Button theme={ButtonThemes.blue} >
                        Show Pickups
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </ContentContainer>
  )
}