import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonSizes, ButtonThemes } from "../../../../ui-components/button/button";
import FreightItemCard from "../../../common/freight-item/freightItemCard";
import ReturnItemForm from "./returnItemForm";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  addItem,
  clearDeletedItems,
  deleteItem,
  returnSelector,
  updateItem
} from "../../../../slices/return/returnSlice";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";
import { StepProps } from "../../../../app/data/common/models";

import "../../bol.scss";

interface ReturnItemsStepProps extends StepProps {
  showRateQuote: boolean;
}

const ReturnItemsStep: React.FC<ReturnItemsStepProps> = (props) => {
  const { previous, next, push, showRateQuote } = props;
  const dispatch = useDispatch();
  const returnState: ReturnState = useSelector(returnSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddItemForm, setAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);
  const [copyItemIndex, setCopyItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedItems());
    previous && previous();
  };

  const onClickNext = () => {
    dispatch(clearDeletedItems());
    if (showRateQuote) {
      next && next();
    } else {
      push && push("return-completed-step");
    }
  };

  const getClassLabel = (ratingClassValue: string) => {
    let ratingClass = commonState.freightClasses.find(obj => obj.value === ratingClassValue);
    return ratingClass ? ratingClass.label : ratingClassValue;
  };

  return (
    <div className="xgs-bol__return__step">
      <div className="xgs-bol__section">Detail Information</div>
      {returnState.items.map((item, index) => (
        <div key={index}>
          {(index === editItemIndex) && (
            <ReturnItemForm
              editMode
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateItem({ index, data }));
                setEditItemIndex(undefined);
              }}
              onCancel={() => {
                setEditItemIndex(undefined);
              }}
            />
          )}
          {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
            <FreightItemCard
              item={item}
              onAdd={() => {
                setAddItemForm(false);
                setEditItemIndex(index);
              }}
              onRemove={() => {
                setRemoveItemIndex(index);
                setShowRemoveConfirmation(true);
              }}
              onCopy={() => {
                setCopyItemIndex(index);
                setAddItemForm(true);
              }}
              classLabel={getClassLabel(item.ratingClass)}
            />
          )}
        </div>
      ))}
      {(showAddItemForm || returnState.items.length === 0) && (
        <ReturnItemForm
          itemIndex={copyItemIndex}
          onSubmit={(data) => {
            dispatch(addItem(data));
            setAddItemForm(false);
            setCopyItemIndex(undefined);
          }} />
      )}
      {!showAddItemForm &&
        (editItemIndex === undefined) &&
        (returnState.items.length > 0) &&
        (returnState.items.filter(item => !item.deleted).length < 10) && (
          <Button
            className="xgs-items__add"
            theme={ButtonThemes.transparent}
            size={ButtonSizes.auto}
            onClick={() => setAddItemForm(true)}
          >
            + Add Handling Unit
          </Button>
        )}
      <div className="xgs-bol__items__limitation">
        {returnState.items.filter(item => !item.deleted).length === 0
          ? (
            <span>Up to 10 units are allowed.</span>
          )
          : (
            <span>{returnState.items.filter(item => !item.deleted).length} of 10 allowed units have been entered.</span>
          )
        }
      </div>
      <div className="xgs-bol__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          className="xgs-bol__nav-button"
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={
            returnState.items.length === 0
            || returnState.items.filter(item => !item.deleted).length === 0
            || (editItemIndex !== undefined)
          }
          className="xgs-bol__nav-button"
          spinner={returnState.requestStarted}
          onClick={onClickNext}
        >
          {showRateQuote ? "Next" : "Submit"}
        </Button>
      </div>
      <ConfirmationModal
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        The item will be removed from the list.
      </ConfirmationModal>
    </div>
  );
};

export default ReturnItemsStep;
