import React from "react";

export const topSellingColumns = [
  {
    Header: <>SKU # /<br/>Description</>,
    accessor: "sku",
    width: 152,
    Cell: (cellProps: any) => (
      <div className="xgs-warehouse-summary__item-cell">
        <span>{cellProps.row.original.sku}</span><br/>
        {cellProps.row.original.description}
      </div>
    )
  },
  {
    Header: "Warehouse",
    accessor: "terminal",
    width: 212,
    Cell: (cellProps: any) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.locationAirportCode} - {cellProps.row.original.locationTerminalName} <span>{cellProps.row.original.locationTerminalNumber}</span>
      </div>
    )
  },
  {
    Header: <>Orders<br/>(30 days)</>,
    accessor: "numberOfShipments",
    width: 80
  }
]