import React from "react";
import "./progressBar.scss";

export interface ProgressBarProps {
  percentage: number;
  additionalInfo: string;
  multicolor?: boolean;
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const getColorClassName = () => {
    const baseClassName = "xgs-progress-bar__line__filler";
    if (props.multicolor) {
      if (props.percentage < 34) return baseClassName + "--1st-3rd";
      if ((props.percentage >= 34) && (props.percentage < 68)) return baseClassName + "--2nd-3rd";
      if ((props.percentage >= 68) && (props.percentage < 100)) return baseClassName + "--3rd-3rd";
    } else {
      if (props.percentage < 100) return baseClassName + "--not-full";
    }
    if (props.percentage === 100) return baseClassName + "--full";
  };

  return (
    <div className="xgs-progress-bar__container">
      <span className="xgs-progress-bar__text">
        <span className="xgs-progress-bar__percentage">{props.percentage}%</span>
        <span className="xgs-progress-bar__info">{props.additionalInfo}</span>
      </span>
      <div className="xgs-progress-bar__line">
        <div className={"xgs-progress-bar__line__filler " + getColorClassName()} style={{width: props.percentage + "%"}}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
