import React, { useEffect, useCallback, memo } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { useHistory } from "react-router";
import "./filter.scss";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useDispatch, useSelector } from "react-redux";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { fetchAllTerminals, warehouseInventorySelector } from "../../../slices/warehouse-inventory/warehouseInventorySlice";

export type FilterConfig = {
  key: string;
  label: string;
  type: FilterTypes;
  triggerSearch?: boolean;
  options?: Array<{ value: string; label: string }>;
  placeholderText?: string;
};

export enum FilterTypes {
  text = "text",
  number = "number",
  dateRange = "date_range",
  select = "select",
  multi_select = "multi_select",
  date = "date",
  mask = "mask"
}

type TrackingFilterProps = {
  sameRowButtons?: boolean;
  onSearch: (filters: any) => void;
  onClear?: () => void;
};

function parseQueryString(queryString: string): Record<string, string | null> {
  const params = new URLSearchParams(queryString);
  const result: Record<string, string | null> = {};

  params.forEach((value, key) => {
    result[key] = value || null;
  });

  return result;
}

function stringifyQueryString(query: Record<string, string | null>): string {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    params.set(key, value ?? '');
  });

  return params.toString();
}

const filterKeys = ["loc", "sku", "roll", "pallet", "desc"]

export const CustomerInventoryListFilter: React.FC<TrackingFilterProps> = memo(
  ({ onSearch, onClear, ...props }) => {
    const location = useLocation();
    const history = useHistory();
    const warehouseInventoryState = useSelector(warehouseInventorySelector);
    const dispatch = useDispatch();

    const getOriginsOptions = () => {
      return (
        warehouseInventoryState.terminals?.map(
          (origin): XGSSelectOption => ({
            label: origin.name,
            value: origin.id.toString(),
          })
        ) || []
      );
    };

    const getFiltersFromURL = useCallback(() => {
      const queryParams = parseQueryString(location.search);

      return filterKeys.reduce((acc: { [key: string]: string }, filter) => {
        if (queryParams[filter]) {
          acc[filter] = queryParams[filter] as string;
        } else {
          acc[filter] = "";
        }
        return acc;
      }, {});
    }, [location.search]);

    useEffect(() => {
      const filters = getFiltersFromURL();
      onSearch(filters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch]);

    const handleFilterChange = (key: string, value: any) => {
      const filters = getFiltersFromURL();
      filters[key] = value;
      Object.keys(filters).forEach(key => {
        if (!filters[key]) delete filters[key]
        if (filters[key] === ":") delete filters[key]
       })
      history.push({ search: stringifyQueryString(filters) });
      if (key === "loc") {
        onSearch(filters);
      }
    };

    const handleSearch = () => {
      const filters = getFiltersFromURL();
      onSearch(filters);
    };

    const handleClear = () => {
      history.push({ search: "" });
      onSearch({})
      onClear && onClear();
    };

    useEffect(() => {
      dispatch(fetchAllTerminals());
    }, [dispatch]);

    const onOriginChanged = (_origin: XGSSelectOption | null | undefined) => {
      handleFilterChange("loc", _origin?.value);
    };

    return (
      <div className="xgs-inventory-filter">
        <div className="xgs-inventory-filter__controls">
        <div className="xgs-inventory-filter__controls">
          <div className="xgs-inventory-filter__controls__item">
            <LabeledSelectInput
              isLoading={warehouseInventoryState.loadingTerminals}
              onValueChange={onOriginChanged}
              value={getOriginsOptions().find(o => o.value === getFiltersFromURL().loc) || null}
              options={getOriginsOptions()}
              label="Location/Warehouse:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
          <div className="xgs-inventory-filter__controls__item">
            <LabeledTextInput
              onChange={(e) => handleFilterChange("sku", e.currentTarget.value)}
              labelMode={LabelModes.column}
              placeholder=""
              label="SKU #:"
              value={getFiltersFromURL().sku}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          <div className="xgs-inventory-filter__controls__item">
            <LabeledTextInput
              onChange={(e) => handleFilterChange("roll", e.currentTarget.value)}
              labelMode={LabelModes.column}
              placeholder=""
              label="Roll #:"
              value={getFiltersFromURL().roll}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          <div className="xgs-inventory-filter__controls__item">
            <LabeledTextInput
              onChange={(e) => handleFilterChange("pallet", e.currentTarget.value)}
              labelMode={LabelModes.column}
              placeholder=""
              label="Pallet #:"
              value={getFiltersFromURL().pallet}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          <div className="xgs-inventory-filter__controls__item">
            <LabeledTextInput
              onChange={(e) => handleFilterChange("desc", e.currentTarget.value)}
              labelMode={LabelModes.column}
              placeholder=""
              label="Search by Desc:"
              value={getFiltersFromURL().desc}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          {props.sameRowButtons && <div className="xgs-inventory-filter__buttons xgs-inventory-filter__buttons--small xgs-inventory-filter__controls__item">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.blue} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>}
        </div>
          {props.sameRowButtons && <div className="xgs-inventory-filter__buttons xgs-inventory-filter__buttons--small xgs-inventory-filter__controls__item">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.blue} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>}
        </div>
        {!props.sameRowButtons && <div className="xgs-inventory-filter__bottom-row">
          <div className="xgs-inventory-filter__buttons">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.gray} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>
          {Object.values(getFiltersFromURL()).filter(val => !!val && val !== "").length > 0 && (
            <div className="xgs-inventory-filter__base-filters">
              <XGSIcon
                icon={XGSIcons.faExclamationCircle}
                size="sm"
                className="xgs-tracking-filter__base-filters__icon"
              />
              <div className="xgs-tracking-filter__base-filters__text">Filters applied to results below.</div>
            </div>
          )}
        </div>}
      </div>
    );
  }
);
