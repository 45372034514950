import React, { ReactNode, DetailedHTMLProps, memo } from "react";

import "./contentContainer.scss";
import Loading from "../../ui-components/loading/loading";
import { cn } from "../../services/common/className";

export interface ContentContainerProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title?: string;
  titleComponent?: ReactNode;
  header?: ReactNode;
  isLoading?: boolean;
  mods?: {};
}

const ContentContainer: React.FC<ContentContainerProps> = memo(({
  title,
  titleComponent,
  header,
  isLoading,
  className,
  mods,
  ...props
}) => {
  return (
    <div className={cn("xgs-site__content-container")(mods, [className])} {...props}>
      {titleComponent}
      {!titleComponent && (
        <>
          <span className="xgs-site__content-container__title">{title}</span>
          <Loading isLoading={isLoading} />
        </>
      )}
      <div className="xgs-site__content-container__component">
        <div className="xgs-site__content-container__header">
          {header}
        </div>
        <div className="xgs-site__content-container__body">
          {props.children}
        </div>
        <div className="xgs-site__content-container__bottom"></div>
      </div>
    </div>
  );
});

export default ContentContainer;
