import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";

import { uploadImage } from "../../../slices/feature-banner/featureBannerSlice";

import { registerSmartBreak } from "./registerSmartBreak";

const IMAGE_MAX_SIZE = 12 * 1024 * 1024; // 12 MB

interface EditorProps {
  value: any;
  onChange: (value: string) => void;
  onBlur: () => void;
};

const Parchment = Quill.import("parchment");

Quill.register("modules/imageResize", ImageResize);
registerSmartBreak();

function lineBreakMatcher() {
  const Delta = Quill.import("delta");
  const newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
}

function handleLinebreak(range: any, context: any) {
  const { quill } = this;  
  
  const currentLeaf = quill.getLeaf(range.index)[0];
  const nextLeaf = quill.getLeaf(range.index + 1)[0];

  quill.insertEmbed(range.index, "break", true, Quill.sources.USER);

  if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
    quill.insertEmbed(range.index, "break", true, Quill.sources.USER);
  }
  quill.setSelection(range.index + 1, Quill.sources.SILENT);

  Object.keys(context.format).forEach((name) => {
    if (Parchment.query(name, Parchment.Scope.BLOCK)) return;
    if (Array.isArray(context.format[name])) return;
    if (name === "link") return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

function handleEnter(range: any, context: any) {
  const { quill } = this;

  if (range.length > 0) {
    quill.scroll.deleteAt(range.index, range.length);
  }
  const lineFormats = Object.keys(context.format).reduce((acc: any, format) => {
    if (
      Parchment.query(format, Parchment.Scope.BLOCK) &&
      !Array.isArray(context.format[format])
    ) {
      acc[format] = context.format[format];
    }
    return acc;
  }, {});

  const previousChar = quill.getText(range.index - 1, 1);

  quill.insertText(range.index, "\n", lineFormats, Quill.sources.USER);

  if (
    previousChar === "" ||
    (previousChar === "\n" &&
      !(context.offset > 0 && context.prefix.length === 0))
  ) {
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  } else {
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }
  Object.keys(context.format).forEach((name) => {
    if (lineFormats[name] != null) return;
    if (Array.isArray(context.format[name])) return;
    if (name === "link") return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

window.Quill = Quill;

const Editor: React.FC<EditorProps> = (props) => {
  const dispatch = useDispatch();  
  const quillRef = useRef<ReactQuill>();

  const editorImageHandler = useCallback(() => {    
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/gif, image/jpeg, image/png");
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        if (file.size >= IMAGE_MAX_SIZE) {
          toast.error("Insertion failed because image size must be less than 12 MB!");
          return;
        }
        let fd = new FormData();
        fd.append("file", file);
        let range = quillRef.current.editor.getSelection(true);
        const currentContent = quillRef.current.editor.getText();
        if (currentContent === " "
          || currentContent === ""
          || currentContent === null
          || JSON.stringify(currentContent) === "\"\\n\"") {
          quillRef.current.editor.insertText(0, " ");
          range = quillRef.current.editor.getSelection(true);
        }
        quillRef.current.editor.insertEmbed(range.index, "image", `${window.location.origin}/image-placeholder.gif`);
        quillRef.current.editor.setSelection(range.index + 1);
        dispatch(uploadImage(fd, (data) => {
          quillRef.current.editor.deleteText(range.index, 1);
          if (!data.uri) {
            toast.error("An error occurred while inserting an image!");
            return;
          }
          quillRef.current.editor.insertEmbed(range.index, "image", data.uri);
        }));
      } else {
        toast.error("Insertion failed because the object is not an image!");
      }
    };
  }, [dispatch]);

  const quillModules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false,
        matchers: [["BR", lineBreakMatcher]],
      },
      toolbar: {
        container: [
          [{ "header": [1, 2, false] }],
          ["bold", "italic", "underline"],
          [{"list": "bullet"}],
          ["image"],
          ["link"],
          ["clean"]
        ],
        handlers: {
          image: editorImageHandler
        }
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"]
      },
      keyboard: {
        bindings: {
          break: {
            key: 13,
            handler: handleEnter
          },
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: handleLinebreak
          }
        }
      }
    }), [editorImageHandler]
  );

  const removeExtraneousLines = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const length = quill.getLength();
      const text = quill.getText(length - 2, 2);

      if (text === "\n\n") {
        quill.deleteText(quill.getLength() - 2, 2);
      }
    }
  };

  useEffect(() => {
    removeExtraneousLines();
  }, []);

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"      
      modules={quillModules}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      bounds=".xgs-visual-editor"
    />
  )
};

export default Editor;
