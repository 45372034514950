import { AddressModel } from "../../app/data/common/address";

export default interface ShipperState {
  shipper: {
    name: string;
    address: AddressModel;
  };
  addressLookupValue: any;
}

export const initialShipperState: ShipperState = {
  shipper: {
    name: "",
    address: {
      address1: "",
      city: "",
      state: "",
      postalCode: "",
    }
  },
  addressLookupValue: null,
};