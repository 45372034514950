import React, { useEffect, useCallback, memo } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { useHistory } from "react-router";
import "./filter.scss";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useDispatch, useSelector } from "react-redux";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { fetchAllTerminals } from "../../../../slices/warehouse-inventory/warehouseInventorySlice";
import { getServiceCenters, serviceCentersSelector } from "../../../../slices/service-centers/serviceCentersSlice";
import { InboundPickupStatus } from "../../../../app/data/common/route";
import { inboundPickupSelector } from "../../../../slices/pickup/inboundPickupSlice";
import { userSelector } from "../../../../slices/user/userSlice";

type TrackingFilterProps = {
  sameRowButtons?: boolean;
  onSearch: (filters: any) => void;
  onClear?: () => void;
};

function parseQueryString(queryString: string): Record<string, string | null> {
  const params = new URLSearchParams(queryString);
  const result: Record<string, string | null> = {};

  params.forEach((value, key) => {
    result[key] = value || null;
  });

  return result;
}

function stringifyQueryString(query: Record<string, string | null | string[]>): string {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    params.set(key, value as string ?? '');
  });

  return params.toString();
}

const filterKeys = ["dest", "status"]

const defaultStatuses = [
  {
    label: "ASSIGNED",
    value: "ASSIGNED"
  },
  {
    label: "COMPLETED",
    value: "COMPLETED"
  }
]

export const InboundPickupsFilter: React.FC<TrackingFilterProps> = memo(
  ({ onSearch, onClear, ...props }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const serviceCentersState = useSelector(serviceCentersSelector);
    const inboundPickupState = useSelector(inboundPickupSelector);
    const userState = useSelector(userSelector);

    const searchWithOriginTerminal = (filters: any) => {
      filters.originTerminalCode = userState.activeTerminal?.id!;
      onSearch(filters);

    }
    const getDestinationOptions = () => {
      return (
        serviceCentersState.destinations?.map(
          (destination): XGSSelectOption => ({
            label: destination.name,
            value: destination.id.toString(),
          })
        ) || []
      );
    };

    const getFiltersFromURL = useCallback(() => {
      const queryParams = parseQueryString(location.search);

      return filterKeys.reduce((acc: { [key: string]: string | string[] }, filter) => {
        if (queryParams[filter]) {
          if (filter === "status") {
            acc[filter] = queryParams[filter].split(",");
          } else {
            acc[filter] = queryParams[filter] as string;
          }
        } else {
          if (filter === "status") {
            acc[filter] = [];
          } else {
            acc[filter] = "";
          }
        }
        return acc;
      }, {});
    }, [location.search]);

    const handleFilterChange = (key: string, value: any) => {
      const filters = getFiltersFromURL();
      filters[key] = value;
      Object.keys(filters).forEach(key => {
        if (!filters[key] || filters[key].length === 0) delete filters[key]
       })
      history.push({ search: stringifyQueryString(filters) });
      searchWithOriginTerminal(filters);
    };

    const handleSearch = () => {
      const filters = getFiltersFromURL();
      searchWithOriginTerminal(filters);
    };

    const handleClear = () => {
      history.push({ search: "" });
      searchWithOriginTerminal({})
      onClear && onClear();
    };

    useEffect(() => {
      dispatch(fetchAllTerminals());
    }, [dispatch]);

    const onDestinationChanged = (_origin: XGSSelectOption | null | undefined) => {
      handleFilterChange("dest", _origin?.value);
    };

    const getStatusesOptions = () => {
      const keys = Object.keys(InboundPickupStatus)
      const statusesOptions: XGSSelectOption[] = [];
      keys.forEach((key, index) => {
        let label = key.toLowerCase();
        label = label.charAt(0).toUpperCase() + label.slice(1);
        statusesOptions.push({
          label: label,
          value: key.toString()
        })
      });
      return statusesOptions;
    };

    const onStatusChange = (v: any) => {
      let statuses = v ? v.map((option: XGSSelectOption) => option.value) : [];
      handleFilterChange("status", statuses);
    };
  
    useEffect(() => {
      if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
    }, [dispatch, serviceCentersState]);

    useEffect(() => {
      if (!getFiltersFromURL().status?.length) {
        onStatusChange(defaultStatuses);
      } else {
        handleSearch();
        
      }
      // eslint-disable-next-line
    }, [])

    const selectedStatusesStrings = getFiltersFromURL().status as string[];
    const allStatusOptions = getStatusesOptions();
    const selectedStatusesOptions = selectedStatusesStrings?.map(status => allStatusOptions.find(option => option.value === status)).filter((v) => !!v);

    return (
      <div className="xgs-inbound-pickups-filter">
        <div className="xgs-inbound-pickups-filter__controls">
          <div className="xgs-inbound-pickups-filter__controls__item">
            <LabeledSelectInput
              isLoading={serviceCentersState.loading}
              onValueChange={onDestinationChanged}
              value={getDestinationOptions().find(o => o.value === getFiltersFromURL().dest) || null}
              options={getDestinationOptions()}
              label="Destination Terminal:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
          <div className="xgs-inbound-pickups-filter__controls__large-item">
            <LabeledSelectInput
              onMultiValuesChange={(option) => onStatusChange(option)}
              value={selectedStatusesOptions}
              options={getStatusesOptions()}
              label="Statuses:"
              labelMode={LabelModes.column}
              isClearable={false}
              isMulti
              disabled={inboundPickupState.infiniteLoading || inboundPickupState.loadingPickups}
            />
          </div>
          <div className="xgs-inbound-pickups-filter__buttons xgs-inbound-pickups-filter__buttons--small xgs-inbound-pickups-filter__controls__item">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.gray} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
