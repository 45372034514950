import React, { memo } from "react";

import "./labeledDateRangeInput.scss";
import LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps,
} from "../labeledInput";
import XGSDateRange, { XGSDateRangeBaseProps } from "../../../xgs-date/xgs-date-range/xgsDateRange";

export interface LabeledDateRangeInputProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    XGSDateRangeBaseProps {
  className?: string;
  disabled?: boolean;
  maxDate?: Date;
}

const LabeledDateRangeInput: React.FC<LabeledDateRangeInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  fields,
  meta,
  error,
  className,
  ...props
}) => {
  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={`xgs-labeled-date-range-input ${className}`}
    >
      <XGSDateRange
        isFailed={isFailed}
        {...fields}
        {...props}
        className={`${isFailed() ? "not-valid" : ""}`}
      />
    </LabeledInput>
  );
});

export default LabeledDateRangeInput;
