import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormCheckbox from "../../../ui-components/form/checkbox/xgsFormCheckbox";
import RequestRatesState from "../../../slices/rates/RequestRatesState";
import { requestRatesSelector } from "../../../slices/rates/requestRatesSlice";
import {
  ShippingItemFormModel,
  ShippingItemSchema
} from "../../../app/data/rates/models";
import CommonState from "../../../slices/common/CommonState";
import { commonSelector } from "../../../slices/common/commonSlice";
import "./shippingItemForm.scss";

interface ShippingItemFormProps {
  itemIndex?: number,
  onCancel?: () => void,
  onSubmit: (data: ShippingItemFormModel) => void,
  showWarning?: boolean
};

let initialValues: ShippingItemFormModel = {
  freightClass: "",
  description: "",
  pieces: undefined,
  yards: undefined,
  weight: undefined,
  rolls: false
};

const ShippingItemForm: React.FC<ShippingItemFormProps> = (props) => {
  const {
    itemIndex,
    onCancel,
    onSubmit,
    showWarning
  } = props;
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [freightClassOption, setFreightClassOption] = useState<XGSSelectOption | null>();
  const shippingItemRef = useRef<any>(null);

  useEffect(() => {
    if (itemIndex === undefined) return;
    initialValues = {
      freightClass: requestRatesState.items[itemIndex].freightClass,
      description: requestRatesState.items[itemIndex].description,
      pieces: requestRatesState.items[itemIndex].pieces,
      yards: requestRatesState.items[itemIndex].yards,
      weight: requestRatesState.items[itemIndex].weight,
      rolls: requestRatesState.items[itemIndex].rolls
    };
    let freightClass = commonState.freightClasses.find(obj => obj.value === requestRatesState.items[itemIndex].freightClass);
    freightClass && setFreightClassOption(freightClass);
    shippingItemRef.current.setFieldValue("freightClass", requestRatesState.items[itemIndex].freightClass);
    shippingItemRef.current.setFieldValue("description", requestRatesState.items[itemIndex].description);
    shippingItemRef.current.setFieldValue("yards", requestRatesState.items[itemIndex].yards);
    shippingItemRef.current.setFieldValue("pieces", requestRatesState.items[itemIndex].pieces);
    shippingItemRef.current.setFieldValue("rolls", requestRatesState.items[itemIndex].rolls);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (itemIndex !== undefined) return;
    setFreightClassOption(null);
    shippingItemRef.current.setFieldValue("freightClass", undefined);
  }, [itemIndex, commonState.freightClasses]);

  useEffect(() => {
    if (itemIndex !== undefined) return;
    // Formik has issue when passing undefined values (https://github.com/formium/formik/issues/2332), so we passing empty strings
    shippingItemRef.current.resetForm({ values: {
      freightClass: "",
      description: "",
      pieces: "",
      yards: "",
      weight: "",
      rolls: false
    }});
    setFreightClassOption(null);
  }, [itemIndex]);

  return (
    <div className="xgs-request-rates__shipping-item-form">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ShippingItemSchema}
        innerRef={shippingItemRef}
        enableReinitialize
      >
        {(props: FormikProps<ShippingItemFormModel>) => (
          <Form>
            <div className="xgs-request-rates__shipping-item-form__row">
              <LabeledSelectInput
                name="packaging"
                value={freightClassOption}
                options={commonState.freightClasses}
                label="Class:"
                labelMode={LabelModes.column}
                className="xgs-request-rates__shipping-item-form__field xgs-request-rates__shipping-item-form__field--full-width"
                isLoading={commonState.requestStarted && commonState.requestCreator === "GET_FREIGHT_CLASSES"}
                onValueChange={(e) => {
                  if (!e || !e.value || !e.label) return;
                  props.setFieldValue("freightClass", e.value);
                  setFreightClassOption({
                    label: e.label,
                    value: e.value
                  })
                }}
                required={true}
                requiredAsteriskDisabled={false}
              />
            </div>
            {showWarning && (
              <div className="xgs-request-rates__shipping-item-form__freight-notes">
                <strong>Note:</strong> The list of freight classes will be available after selecting the pickup location.
              </div>
            )}
            {!(commonState.freightClasses.find(freightClass => freightClass.value === "1" || freightClass.value === "3")) && (commonState.freightClasses.length > 0) && (
              <div className="xgs-request-rates__shipping-item-form__freight-notes">
                <strong>Note:</strong> Carpet and Carpet Tile class can only be used for outbound shipments from North Georgia or South California.
              </div>
            )}
            <div className="xgs-request-rates__shipping-item-form__row">
              <XGSFormInput
                type="text"
                name="description"
                label="Description:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-request-rates__shipping-item-form__field xgs-request-rates__shipping-item-form__field--full-width"
              />
            </div>
            <div className="xgs-request-rates__shipping-item-form__row xgs-request-rates__shipping-item-form__row--spaced">
              <XGSFormInput
                type="text"
                name="pieces"
                label="Pieces:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-request-rates__shipping-item-form__field"
                disabled={props.values.rolls}
              />
              <XGSFormInput
                type="text"
                name="yards"
                label="Sq. yards:"
                required={props.values.freightClass === "1" || props.values.freightClass === "3"}
                requiredAsteriskDisabled={!(props.values.freightClass === "1" || props.values.freightClass === "3")}
                labelMode={LabelModes.column}
                className="xgs-request-rates__shipping-item-form__field"
              />
              <XGSFormInput
                type="text"
                name="weight"
                label="Weight (lbs):"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-request-rates__shipping-item-form__field"
              />
            </div>
            <div className="xgs-request-rates__shipping-item-form__row">
              <XGSFormCheckbox
                mix="xgs-request-rates__shipping-item-form__checkbox"
                name="rolls"
                onChange={(e) => {
                  props.handleChange(e);
                  if (e.target.checked) {
                    props.setFieldValue("pieces", 1);
                  } else {
                    props.setFieldValue("pieces", "");
                  }
                }}
              >
                Is this freight a roll (core&nbsp;included)?
              </XGSFormCheckbox>              
            </div>
            <div className="xgs-request-rates__shipping-item-form__row">
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={
                  !props.isValid ||
                  !props.values.weight ||
                  !!((itemIndex !== undefined) && !props.dirty)
                }
                className="xgs-request-rates__shipping-item-form__button"
              >
                {(itemIndex !== undefined) ? "Save changes" : "Add item"}
              </Button>
              {(itemIndex !== undefined) && (
                <Button
                  type="button"
                  theme={ButtonThemes.blue}
                  onClick={onCancel}
                  className="xgs-request-rates__shipping-item-form__button"
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShippingItemForm;
