import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormDate from "../../../ui-components/form/date/xgsFormDate";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import ImportantMessage, { ImportantMessageTheme } from "../../../ui-components/molecules/important-message/importantMessage";
import {
  reschedulePickup,
  pickupDetailsSelector
} from "../../../slices/pickup/pickupDetailsSlice";
import {
  commonSelector,
  getPickupDays
} from "../../../slices/common/commonSlice";
import { currentOrClosestBusinessDay } from "../../../hooks/utils";
import {
  PickupRescheduleModel,
  PickupRescheduleSchema
} from "../../../app/data/pickup/models";
import { fromTimeOptions, toTimeOptions } from "../../../services/common/time";
import { getDateClassName } from "../../../services/pickups";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { WeekDay } from "../../../ui-components/xgs-date/xgs-date/xgsDate";

import "./pickupDetails.scss";

export interface PickupRescheduleProps {
  show: boolean;
  pickupId: string;
  pickupNumber: string;
  shipperZip: string;
  onReschedule: (newPickupId: string, newPickupNumber: string, pickupDate?: string, pickupReadyTime?: string, pickupCloseTime?: string) => void;
  onClose: () => void;
  type: "CUSTOMER" | "EMPLOYEE";
};

let initialValues: PickupRescheduleModel = {
  pickupDate : "",
  pickupReadyTime : "",
  pickupCloseTime : ""
};

const PickupReschedule: React.FC<PickupRescheduleProps> = ({onClose, type, ...props}) => {
  const dispatch = useDispatch();
  const rescheduleFormRef = useRef<FormikProps<PickupRescheduleModel>>(null);
  const commonState = useSelector(commonSelector);
  const pickupDetailsState = useSelector(pickupDetailsSelector);
  const [pickupDateFormValue, setPickupDateFormValue] = useState<string | undefined>();
  const [pickupReadyTimeFormValue, setPickupReadyTimeFormValue] = useState<XGSSelectOption | null>();
  const [pickupCloseTimeFormValue, setPickupCloseTimeFormValue] = useState<XGSSelectOption | null>();
  const [showNonServiceDayNotification, setShowNonServiceDayNotification] = useState(false);

  const onReschedulePickupConfirm = (data: PickupRescheduleModel) => {
    const request = {...data};
    request.pickupDate = request.pickupDate.toApiDateFormat();
    dispatch(reschedulePickup(
      props.pickupId,
      request,
      (newPickupId, newPickupNumber) => {
        toast.info("The pickup has been rescheduled!", { autoClose: 3000 });
        props.onReschedule(newPickupId, newPickupNumber, data.pickupDate, data.pickupReadyTime, data.pickupCloseTime);
      }, () => {
        toast.error("Failed to reschedule the pickup!");
      }
    ));
  };

  const onPickupDateChange = (date: string) => {
    rescheduleFormRef.current?.setFieldValue("pickupDate", date || "");
    setPickupDateFormValue(date);
    if (commonState.pickupDays) {
      const dateDayOfWeek = moment(date, "MM/DD/YYYY").format("dd").toLowerCase() as WeekDay;
      setShowNonServiceDayNotification(commonState.pickupDays[dateDayOfWeek] === false);
    } else {
      setShowNonServiceDayNotification(false);
    }
  };

  useEffect(() => {
    dispatch(getPickupDays(props.shipperZip));
  }, [props.shipperZip, dispatch]);

  useEffect(() => {
    rescheduleFormRef.current?.resetForm();
    setPickupDateFormValue(undefined);
    setPickupReadyTimeFormValue(null);
    setPickupCloseTimeFormValue(null);
    setShowNonServiceDayNotification(false);
  }, [props.show]);

  useEffect(() => {
    if (!pickupCloseTimeFormValue || !pickupReadyTimeFormValue) return;
    const options = toTimeOptions(pickupReadyTimeFormValue.value);
    const toIndex = options.findIndex((obj: XGSSelectOption) => obj.value === pickupCloseTimeFormValue?.value);
    if (toIndex === -1) {
      // if current "To" time is out of allowed time options
      setPickupCloseTimeFormValue(options[0]);
      rescheduleFormRef.current?.setFieldValue("pickupCloseTime", options[0].value);
    }
  }, [pickupCloseTimeFormValue, pickupReadyTimeFormValue]);

  return (
    <SlideOutSidebar
      header="Reschedule Pickup"
      show={props.show}
      size="extrasmall"
      onClose={onClose}
      className="xgs-pickup-details__slideout"
    >
      <>
        <div className="xgs-pickup-details__slideout__inner-header">
          Reschedule Pickup {props.pickupNumber}
        </div>
        <div className="xgs-pickup-details__slideout__text">
          <p>A copy of the pickup will be created with the new date and time. The original pickup will be cancelled.</p>
          {type === "EMPLOYEE" && (
            <p><strong>The customer will receive an email that the pickup has been rescheduled.</strong></p>
          )}
        </div>
        <div className="xgs-pickup-details__reschedule-form">
          <Formik
            onSubmit={onReschedulePickupConfirm}
            initialValues={initialValues}
            validationSchema={PickupRescheduleSchema}
            innerRef={rescheduleFormRef}
            enableReinitialize
          >
            {(props: FormikProps<PickupRescheduleModel>) => (
              <Form>
                <div className="xgs-pickup__date">
                  <XGSFormDate
                    name="pickupDate"
                    label="Pickup Date:"
                    placeholder="Select date..."
                    onDateChange={onPickupDateChange}
                    value={pickupDateFormValue}
                    onChange={() => null}
                    minDate={new Date(currentOrClosestBusinessDay())}
                    required={true}
                    labelMode={LabelModes.column}
                    disableWeekends={true}
                    disabledWeekDays={type === "EMPLOYEE" ? null : commonState.pickupDays}
                    disabled={commonState.requestStarted && (commonState.requestCreator === "GET_SERVICE_CENTER_DAYS")}
                    dayClassName={(date) => getDateClassName(commonState.pickupDays, date)}
                  />
                </div>
                {showNonServiceDayNotification &&
                  <ImportantMessage theme={ImportantMessageTheme.BLUE} mix="xgs-pickup__message-row xgs-pickup__message-row--top-12">
                    Please note that you selected non-service day!
                  </ImportantMessage>
                }
                <div className="xgs-pickup__time xgs-bol__return__availability">
                  <div className="xgs-bol__return__availability__label">Hours of Availability: <span>*</span></div>
                  <div className="xgs-bol__return__availability__controls">
                    <LabeledSelectInput
                      name="pickupReadyTime"
                      value={pickupReadyTimeFormValue}
                      label=""
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      onValueChange={(v) => {
                        props.setFieldValue("pickupReadyTime", v?.value);
                        setPickupReadyTimeFormValue(v);
                      }}
                      options={fromTimeOptions()}
                      required={true}
                      requiredAsteriskDisabled={true}
                      formik={true}
                      placeholder="Ready..."
                      menuPlacement="bottom"
                    />
                    <div className="xgs-bol__return__availability__controls__delimiter">-</div>
                    <LabeledSelectInput
                      name="pickupCloseTime"
                      value={pickupCloseTimeFormValue}
                      label=""
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      onValueChange={(v) => {
                        props.setFieldValue("pickupCloseTime", v?.value);
                        setPickupCloseTimeFormValue(v);
                      }}
                      options={pickupReadyTimeFormValue ? toTimeOptions(pickupReadyTimeFormValue.value) : []}
                      required={true}
                      requiredAsteriskDisabled={true}
                      formik={true}
                      placeholder="Close..."
                      menuPlacement="bottom"
                    />
                  </div>
                </div>
                <div className="xgs-pickup-details__slideout__buttons">
                  <Button
                    theme={ButtonThemes.gray}
                    onClick={onClose}
                    type="button"
                  >
                    No
                  </Button>
                  <Button
                    theme={ButtonThemes.blue}
                    spinner={pickupDetailsState.requestStarted && pickupDetailsState.requestCreator === "RESCHEDULE_PICKUP"}
                    type="submit"
                    disabled={!props.isValid || !props.dirty}
                  >
                    {
                      pickupDetailsState.requestStarted && pickupDetailsState.requestCreator === "RESCHEDULE_PICKUP"
                      ? <>Yes, Res...</>
                      : <>Yes, Reschedule</>
                    }
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    </SlideOutSidebar>
  );
};

export default PickupReschedule;
