import {  
  PickupLogItem,
  PickupsResponseContentModel,
} from "../../app/data/pickup/models";

export default interface PickupDetailsState {
  requestStarted: boolean;  
  requestCreator: string;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestFailStatus: number;
  requestError: string;  
  pickupLog: PickupLogItem[];
  pickup: PickupsResponseContentModel | null;
};

export const initialPickupDetailsState: PickupDetailsState = {
  requestStarted: false,  
  requestCreator: "",
  requestSucceed: false,
  requestFailed: false,
  requestFailStatus: 0,
  requestError: "",  
  pickupLog: [],
  pickup: null,
};
