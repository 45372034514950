import React from "react";

import {ReactComponent as InfoIcon} from "../../../images/info_dark.svg";
import {ReactComponent as WarningIcon} from "../../../images/warning.svg";
import { cn } from "../../../services/common/className";

import "./importantMessage.scss";

export enum ImportantMessageTheme {
  BLUE = "blue",
  RED = "red",
};

export interface ImportantMessageProps {
  children: any;
  theme: ImportantMessageTheme;
  mix?: string;  
}

const ImportantMessage: React.FC<ImportantMessageProps> = (props) => {
  return (
    <div className={cn("xgs-important-message")({theme: props.theme}, props.mix)}>
      {props.theme === ImportantMessageTheme.BLUE && <InfoIcon
        className="xgs-important-message__icon"
      />}

      {props.theme === ImportantMessageTheme.RED && <WarningIcon
        className="xgs-important-message__icon"
      />}

      <span className="xgs-important-message__text">
        {props.children}
      </span>
    </div>
  );
}

export default ImportantMessage;