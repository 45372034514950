import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Chat from "./chat/chat";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import { ChatFormModel, ChatFormSchema, ChatMessageModel } from "../../../app/data/chat/models";
import { addMessage, chatSelector, sendChatMessage, setProbillNumber, requestStarted, requestSucceed, setAnimation, setCurrentChatId } from "../../../slices/chat/chatSlice";
import { toast } from "react-toastify";
import ChatService from "../../../app/data/chat/chatService";
import { useDownload } from "../../../hooks/useDownload";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";

import "./shipmentChat.scss";

const initialValues = {
  probillNumber: "",
};

const INITIAL_MESSAGES = [
  "Hello! I'm AI-powered helper.",
  "Can you please provide the probill number in form above 👆 for the shipment you need help with?",
];

const MESSAGE_DELAY = 500;

const chatService = ChatService.getInstance();

const ShipmentChat: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const chatState = useSelector(chatSelector);
  const messages = chatState.messages;
  const formRef = useRef<FormikProps<ChatFormModel>>(null);

  const send = (message: ChatMessageModel) => {
    dispatch(sendChatMessage(
      message,
      () => toast.error("Something went wrong.")
    ));
  }

  const onSubmitProbillNumber = (values: ChatFormModel) => {
    const newGroup = !!chatState.probillNumber;
    dispatch(setProbillNumber(values.probillNumber));
    dispatch(setCurrentChatId(null));
    send({ text: `Probill Number: ${values.probillNumber}`, newGroup });

    formRef.current?.resetForm();
  };

  const downloadChat = () => chatService.downloadChat(chatState.chatIds);

  const {
    download,
    loadingState,
  } = useDownload(
    "CHAT",
    downloadChat,
  );

  useEffect(() => {
    let timeouts: any[] = [];

    if (chatState.messages.length < INITIAL_MESSAGES.length) {
      INITIAL_MESSAGES.slice(chatState.messages.length).forEach((message, i) => {
        dispatch(requestStarted(""));
        const timeout = setTimeout(() => {
          dispatch(requestSucceed());
          dispatch(addMessage({
            incoming: true,
            text: message,
          }));
        }, MESSAGE_DELAY * (i + 1));

        timeouts.push(timeout);
      }); 
    }

    return () => {  
      timeouts.forEach((timeout: any) => { clearTimeout(timeout) });
    }
  }, [chatState.messages, dispatch]);

  useEffect(() => {
    return () => { dispatch(setAnimation(false)); }
  }, [dispatch]);

  return (
    <ContentContainer
      title="Shipment Chat"
      titleComponent={
        <ContentContainerToolbar
          title="Shipment Chat"
        >
          <Button
          className="shipment-chat__mobile-element"
            theme={ButtonThemes.gray}
            size={ButtonSizes.autoMedium}
            disabled={!chatState.chatIds?.length}
            spinner={loadingState?.downloadStarted}
            onClick={() => {
              !loadingState?.downloadStarted && download();
            }}
          >
            <DownloadIcon/>
          </Button>
        </ContentContainerToolbar>
      }
     mods={{ "screen-height": true, "mobile-no-padding": true }}
     >
      <div className="shipment-chat">
        <div className="shipment-chat__row">
          <Formik
            onSubmit={onSubmitProbillNumber}
            initialValues={initialValues}
            validationSchema={ChatFormSchema}
            innerRef={formRef}
          >
            {(formikProps: FormikProps<ChatFormModel>) => (
              <Form className="shipment-chat__form shipment-chat__row-item">
                <XGSFormInput
                  type="text"
                  name="probillNumber"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  placeholder="Enter Probill Number"
                  maxLength={8}
                />

                <Button
                  type="submit"
                  theme={ButtonThemes.blue}
                  size={ButtonSizes.autoMedium}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                >
                  Submit
                </Button>

              </Form>
            )}
          </Formik>

          <div className="shipment-chat__row-item shipment-chat__desktop-element">
            <Button
              theme={ButtonThemes.gray}
              size={ButtonSizes.autoMedium}
              disabled={!chatState.chatIds?.length}
              spinner={loadingState?.downloadStarted}
              onClick={() => {
                !loadingState?.downloadStarted && download();
              }}
            >
              Download as PDF
            </Button>
          </div>
        </div>

        <Chat
          mix="shipment-chat__chat"
          messages={messages}
          onSend={send}
          isLoading={chatState.requestStarted}
          isDisabled={!chatState.probillNumber}
          animated={chatState.animation}
        />
      </div>
    </ContentContainer>
  );
};

export default ShipmentChat;
