import api, { ApiResponse } from "./../api";
import axios, { CancelTokenSource } from "axios";
import {
  DockLoadingFilterModel,
  DockLoadingResponseModel,
  DoorDetailsResponseModel,
  InvolvedUsersResponseModel
} from "./models";
import { urlParams } from "../../../services/common/urlParams";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_DOCK_LOADING: "get_dock_loading_status",
  GET_DOOR_DETAILS: "get_door_details",
  GET_INVOLVED_USERS: "get_involved_users"
};

class DockLoadingService {
  private source: {
    [key: string]: CancelTokenSource;
  };

  private static instance: DockLoadingService;

  private constructor() {
    this.source = {};
  };

  static getInstance(): DockLoadingService {
    if (!DockLoadingService.instance) {
      DockLoadingService.instance = new DockLoadingService();
    }
    return DockLoadingService.instance;
  };

  public getDockLoadingStatus = async (
    terminalNumber: number,
    filter: DockLoadingFilterModel
  ): Promise<ApiResponse<DockLoadingResponseModel>> => {
    this.source[REQUEST_ID.GET_DOCK_LOADING]?.cancel();
    this.source[REQUEST_ID.GET_DOCK_LOADING] = axios.CancelToken.source();
    return await api.get("/proxy/linehaul/dock/loading", { params: urlParams({terminalNumber, ...filter}), cancelToken: this.source[REQUEST_ID.GET_DOCK_LOADING].token });
  };

  public getDoorDetails = async (
    terminalNumber: number,
    doorNumber: number
  ): Promise<ApiResponse<DoorDetailsResponseModel>> => {
    this.source[REQUEST_ID.GET_DOOR_DETAILS]?.cancel();
    this.source[REQUEST_ID.GET_DOOR_DETAILS] = axios.CancelToken.source();
    return await api.get("/proxy/linehaul/dock/loading/details", { params: urlParams({terminalNumber, doorNumber}), cancelToken: this.source[REQUEST_ID.GET_DOOR_DETAILS].token });
  };

  public getInvolvedUsers = async (
    terminalNumber: number
  ): Promise<ApiResponse<InvolvedUsersResponseModel>> => {
    this.source[REQUEST_ID.GET_INVOLVED_USERS]?.cancel();
    this.source[REQUEST_ID.GET_INVOLVED_USERS] = axios.CancelToken.source();
    return await api.get("/proxy/linehaul/dock/loading/users", { params: urlParams({terminalNumber}), cancelToken: this.source[REQUEST_ID.GET_INVOLVED_USERS].token });
  };
};

export default DockLoadingService;
