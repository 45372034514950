import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import DockLoadingService from "../../app/data/dock-loading/dockLoadingService";
import { DockLoadingFilterModel } from "../../app/data/dock-loading/models";
import { initialDockLoadingState } from "./DockLoadingState";

const dockLoadingService = DockLoadingService.getInstance();

export const dockLoadingSlice = createSlice({
  name: "dockLoading",
  initialState: initialDockLoadingState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    clearData: (state) => {
      state.summary = null;
      state.doors = [];
    },
    storeDockLoading: (state, { payload }) => {
      state.summary = payload.summary || null;
      state.doors = payload.doors || [];
    },
    storeDoorDetails: (state, { payload }) => {
      state.door = payload;
    },
    storeInvolvedUsers: (state, { payload }) => {
      state.involvedUsers = payload?.users ? payload.users : [];
    },
    clearInvolvedUsers: (state) => {
      state.involvedUsers = [];
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetErrors,
  clearData,
  storeDockLoading,
  storeDoorDetails,
  storeInvolvedUsers,
  clearInvolvedUsers
} = dockLoadingSlice.actions;

export const dockLoadingSelector = (state: IState) => state.dockLoading;

export const getDockLoadingStatus = (
  terminalNumber: number,
  filter: DockLoadingFilterModel
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_DOCK_LOADING"));
  const response = await dockLoadingService.getDockLoadingStatus(terminalNumber, filter);
  if (response.ok()) {
    dispatch(storeDockLoading(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(clearData);
    response.getError && dispatch(requestFailed("Error"));
  }
};

export const getDoorDetails = (
  terminalNumber: number,
  doorNumber: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_DOOR_DETAILS"));
  const response = await dockLoadingService.getDoorDetails(terminalNumber, doorNumber);
  if (response.ok()) {
    dispatch(storeDoorDetails(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getInvolvedUsers = (
  terminalNumber: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_INVOLVED_USERS"));
  const response = await dockLoadingService.getInvolvedUsers(terminalNumber);
  if (response.ok()) {
    dispatch(storeInvolvedUsers(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const dockLoadingReducer = dockLoadingSlice.reducer;
export default dockLoadingReducer;
