import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { UserRoles, UserStatuses } from "../user/enums";

export interface CreateTeamRequestModel {
  accounts: number[];
  email: string;
  name: string;
  agent: boolean;
};

export interface CreateTeamFormModel {
  accounts: string;
  email: string;
  name: string;
  agent: boolean;
};

export const CreateTeamSchema: Yup.ObjectSchema<CreateTeamFormModel> = Yup.object().shape({
  accounts: Yup
    .string()
    .matches(/^(\d{3,8}|(\d{3,8}(,| )*)*)$/, validationMessages.notValid)
    .test(
      "is-accounts-list",
      "Each account number must be no less than 100 and no more than 99999999",
      (value: any) => {
        return value ? !(
          value.split(/(?:,| )+/)
            .map(Number)
            .filter((accountNumber: number) => accountNumber && (accountNumber < 100 || accountNumber > 99999999))
            .length > 0
        ) : false;
      }
    )
    .required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required),
  name: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  agent: Yup.boolean().required(validationMessages.required)
}).defined();

export interface UpdateTeamModel {
  teamId: string;  
  name?: string;
  accounts?: number[];
};

export interface AddTeamAccountModel {
  account: number | string;
};

export const AddTeamAccountSchema: Yup.ObjectSchema<AddTeamAccountModel> = Yup.object().shape({
  account: Yup.string()
    .matches(/^\d+$/, validationMessages.notValid)
    .min(3, "The value must be no less than 3 digits")
    .max(8, "The value must be no more than 8 digits")    
    .typeError(validationMessages.notValid)
    .required(validationMessages.required)
}).defined();

export interface AddTeamShipperModel {
  accounts: string;
};

export const AddTeamShipperSchema: Yup.ObjectSchema<AddTeamShipperModel> = Yup.object().shape({
  accounts: Yup
    .string()
    .matches(/^(\d{3,8}|(\d{3,8}(,| )*)*)$/, validationMessages.notValid)
    .test(
      "is-accounts-list",
      "Each account number must be no less than 100 and no more than 99999999",
      (value: any) => {
        return value ? !(
          value.split(/(?:,| )+/)
            .map(Number)
            .filter((accountNumber: number) => accountNumber && (accountNumber < 100 || accountNumber > 99999999))
            .length > 0
        ) : false;
      }
    )
    .required(validationMessages.required)
}).defined();

export interface SearchCustomersModel {
  search: string;
  company: string;
  role: UserRoles | null;
  status: UserStatuses | null;
};

export interface TeamInfo {
  id: string;
  name: string;
  users: number;
  accounts: number;
  agent: boolean;
}

export interface SearchRequesterModel {
  id: string;
  name: string;
  accountName?: string;
  accountNumber?: number;
};

export interface SearchRequestersResponseModel {
  teams: SearchRequesterModel[];
  accounts: SearchRequesterModel[];
}
