import moment from "moment";
import dateFormats from "../app/data/common/dateFormats";

export {};

declare global {
  interface String {
    toUiDateFormat: () => string;
    toUiDateTimeFormat: () => string;
    toUiDateTimeShortFormat: () => string;
    toUiDateWeekFormat: () => string;
    toApiDateFormat: () => string;
    toApiTimeFormat: () => string;
    toUiTimeFormat: () => string;
    toCustomDateFormat: (format: string) => string;
    capitalize: () => string;
    formatPhone: () => string;
    toUiDateOrdinal: () => string;
  }
}

export function initStringExtenstions() {
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateFormat = function () {
    return moment(this.toString()).format(dateFormats.uiDateShort);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateTimeFormat = function () {
    return moment(this.toString()).format(dateFormats.uiDateTime);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateTimeShortFormat = function () {
    return moment(this.toString()).format(dateFormats.uiDateTimeShort);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiTimeShortFormat = function () {
    return moment(this.toString()).format(dateFormats.uiTimeShort);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateWeekFormat = function () {
    return moment(this.toString()).format(dateFormats.uiDateWeek);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toApiDateFormat = function () {
    return moment(this.toString()).format(dateFormats.apiDate);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toApiTimeFormat = function () {
    return moment(this.toString(), "h:mm a").format("HH:mm:ss");
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiTimeFormat = function () {
    return moment(this.toString(), "HH:mm:ss").format("h:mm a");
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toCustomDateFormat = function (format) {
    return moment(this.toString()).format(format);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.formatPhone = function () {
    const cleanedPhone = ("" + this).replace(/\D/g, "");
    const match = cleanedPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? "(" + match[1] + ") " + match[2] + "-" + match[3] : "";
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateOrdinal = function () {
    return moment(this.toString()).format(dateFormats.uiDateOrdinal);
  }
}
