import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { Routes } from "../../../../app/route/RoutesConfig";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonSizes, ButtonThemes } from "../../../../ui-components/button/button";
import FreightItemCard from "../../../common/freight-item/freightItemCard";
import BolItemForm from "./bolItemForm";
import BolState from "../../../../slices/bol/BolState";
import {
  bolSelector,
  addItem,
  updateItem,
  deleteItem,
  clearDeletedItems,
  createBol
} from "../../../../slices/bol/bolSlice";
import { BolRequestModel } from "../../../../app/data/bol/models";
import { StepProps } from "../../../../app/data/common/models";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";
import "../../bol.scss";
import "../../../../sass/blocks.scss";

const BolItemsStep: React.FC<StepProps> = (props) => {
  const { previous } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const bolState: BolState = useSelector(bolSelector);
  const userState: UserState = useSelector(userSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddItemForm, setAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);
  const [copyItemIndex, setCopyItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedItems());
    previous && previous();
  };

  const onSubmit = () => {
    if (!bolState.shipper) return;
    dispatch(clearDeletedItems());
    let items = bolState.items.filter(item => !item.deleted);
    let request: BolRequestModel = {
      shipper: bolState.shipper,
      shipperName: bolState.shipperName,
      shipperAddress: bolState.shipperAddress,
      bolNumber: bolState.bolNumber,
      poNumber: bolState.poNumber,
      payType: bolState.payType,
      specialInstructions: bolState.specialInstructions,
      willCall: bolState.willCall,
      consignee: bolState.consignee,
      items: items
    };
    if (bolState.quoteNumber) request.quoteNumber = bolState.quoteNumber;
    if (bolState.payor) request.payor = bolState.payor;
    if (bolState.pickupRequest && bolState.pickupDate && bolState.pickupReadyTime && bolState.pickupCloseTime) {
      request.pickupDate = bolState.pickupDate.toApiDateFormat();
      request.pickupReadyTime = bolState.pickupReadyTime;
      request.pickupCloseTime = bolState.pickupCloseTime;
    }
    dispatch(createBol(request, () => {
      toast.info("BOL was successfully created!");
      mixpanel.track(`Bill of Lading was created ${request.pickupDate ? "(w/ pickup)" : "(w/o pickup)"}`);
      history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.bol.list}`);
    }));
  };

  const getClassLabel = (ratingClassValue: string) => {
    let ratingClass = commonState.freightClasses.find(obj => obj.value === ratingClassValue);
    return ratingClass ? ratingClass.label : ratingClassValue;
  };

  useEffect(() => {
    if (!bolState.requestFailed || bolState.requestCreator === "CHECK_PICKUP") return;
    toast.error(bolState.requestError || "Error", { autoClose: 4000 });
  }, [bolState.requestFailed, bolState.requestError, bolState.requestCreator]);

  return (
    <div className="xgs-bol__consignee">
      <div className="xgs-bol__section">Detail Information</div>
      {bolState.items.map((item, index) => (
        <div key={index}>
          {(index === editItemIndex) && (
            <BolItemForm
              editMode
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateItem({ index, data }));
                setEditItemIndex(undefined);
              }}
              onCancel={() => {
                setEditItemIndex(undefined);
              }}
            />
          )}
          {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
            <FreightItemCard
              item={item}
              onAdd={() => {
                setAddItemForm(false);
                setEditItemIndex(index);
              }}
              onRemove={() => {
                setRemoveItemIndex(index);
                setShowRemoveConfirmation(true);
              }}
              onCopy={() => {
                setCopyItemIndex(index);
                setAddItemForm(true);
              }}
              classLabel={getClassLabel(item.ratingClass)}
            />
          )}
        </div>
      ))}
      {(showAddItemForm || bolState.items.length === 0) && !bolState.quoteNumber && (
        <BolItemForm
          itemIndex={copyItemIndex}
          onSubmit={(data) => {
            dispatch(addItem(data));
            setAddItemForm(false);
            setCopyItemIndex(undefined);
          }} />
      )}
      {!showAddItemForm &&
        !bolState.quoteNumber &&
        (editItemIndex === undefined) &&
        (bolState.items.length > 0) &&
        (bolState.items.filter(item => !item.deleted).length < 10) && (
          <Button
            className="xgs-items__add"
            theme={ButtonThemes.transparent}
            size={ButtonSizes.auto}
            onClick={() => setAddItemForm(true)}
          >
            + Add Handling Unit
          </Button>
        )}
      {!bolState.quoteNumber && (
        <div className="xgs-bol__items__limitation">
          {bolState.items.filter(item => !item.deleted).length === 0
            ? (
              <span>Up to 10 units are allowed.</span>
            )
            : (
              <span>{bolState.items.filter(item => !item.deleted).length} of 10 allowed units have been entered.</span>
            )
          }
        </div>
      )}
      <div className="xgs-bol__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          className="xgs-bol__nav-button"
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={
            bolState.items.length === 0
            || bolState.items.filter(item => !item.deleted).length === 0
            || (editItemIndex !== undefined)
          }
          className="xgs-bol__nav-button"
          spinner={bolState.requestStarted}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
      <ConfirmationModal
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        The item will be removed from the list.
      </ConfirmationModal>
    </div>
  );
};

export default BolItemsStep;
