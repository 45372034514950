import moment from "moment";
import { PickupStatus } from "../../app/data/common/route";
import { TagColor } from "../../app/data/common/tagColor";
import { PickupDetailsModel, PickupsGroupModel, PickupsResponseContentModel } from "../../app/data/pickup/models";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import { WeekDay, weekDays } from "../../ui-components/xgs-date/xgs-date/xgsDate";

export const getPickupById = (pickups, id) => (
  pickups.reduce((result, item) => {
    if (result) return result
    if (item.pickups?.length > 0) {
      return getPickupById(item.pickups, id)
    }
    return item.pickup.pickupId === id ? item : null
  }, null)
);

export const getPickupsByIds = (pickups, ids) => (
  pickups.reduce((result, item) => {    
    if (item.pickups?.length > 0) {
      return [...result, ...getPickupsByIds(item.pickups, ids)];
    }
    return ids.find(id => id === item.pickup.pickupId) ? [...result, item] : result
  }, [])
);

export const getPickupGroupStatus = (items: PickupsResponseContentModel[]) => {
  if (items.find(item => item.status === PickupStatus.MISSED)) return PickupStatus.MISSED;
  if (items.find(item => item.status === PickupStatus.COMPLETED)) return PickupStatus.COMPLETED;
  if (items.find(item => item.status === PickupStatus.UNASSIGNED)) return PickupStatus.UNASSIGNED;
  if (items.find(item => item.status === PickupStatus.ASSIGNED)) return PickupStatus.ASSIGNED;
  return PickupStatus.CANCELED;
}

export const isGroup = (item: PickupsResponseContentModel | PickupsGroupModel ): item is PickupsGroupModel => ("pickups" in item && !!item.pickups);

export const isSubPickup = (item: PickupsResponseContentModel | PickupsGroupModel): item is PickupsResponseContentModel => !isGroup(item) && !item.pickup.single;

export const convertToSubPickup = (item: PickupsResponseContentModel) => ({ ...item, pickup: { ...item.pickup, single: false }});

export const getStatusColor = (status: PickupStatus) => {
  switch (status) {
    case PickupStatus.ASSIGNED:
      return TagColor.YELLOW;
    case PickupStatus.UNASSIGNED:
      return TagColor.ORANGE;
    case PickupStatus.COMPLETED:
      return TagColor.GREEN;
    case PickupStatus.MISSED:
      return TagColor.RED;
    default:
      return TagColor.GREY
  }
}

export const getStatusesOptions = () => {
  const keys = Object.keys(PickupStatus)
  const statusesOptions: XGSSelectOption[] = [];
  keys.forEach((key, index) => {
    let label = key.toLowerCase();
    label = label.charAt(0).toUpperCase() + label.slice(1);
    statusesOptions.push({
      label: label,
      value: key.toString()
    })
  });
  return statusesOptions;
};

export const getCustomerPickupSourceLabel = (source: string) => {
  switch (source) {
    case "Customer":
    case "Customer API":   return "BOL";
    case "Customer Return": return "Return";
    case "Employee": return "XGS";
    case "EDI":
    case "EDI Pickup": return "EDI";
    default: return "-"
  }
};

export const getPickupDetails = (pickupData: PickupsResponseContentModel): PickupDetailsModel | null => {
  if (!pickupData) return null;

  return {
  pickupId: pickupData.pickup.pickupId,
  pickupNumber: pickupData.pickup.pickupNumber,
  probillNumber: pickupData.pickupFullData.pickupRequest.probillNumber,
  pickupStatus: pickupData.pickup.pickupStatus,
  missed: pickupData.pickup.missed,
  pickupDate: pickupData.pickupFullData.pickupRequest.pickupDate,
  pickupReadyTime: pickupData.pickupFullData.pickupRequest.pickupReadyTime,
  pickupCloseTime: pickupData.pickupFullData.pickupRequest.pickupCloseTime,
  dateCreated: pickupData.pickupFullData.pickupRequest.dateCreated,
  userCreated: pickupData.pickupFullData.pickupRequest.userCreated,
  creatorType: pickupData.pickupFullData.pickupRequest.creatorType,
  userUpdated: pickupData.pickupFullData.pickupRequest.userUpdated,
  dateUpdated: pickupData.pickupFullData.pickupRequest.dateUpdated,
  bolNumber: pickupData.pickupFullData.pickupRequest.bolNumber,
  poNumber: pickupData.pickupFullData.pickupRequest.poNumber,
  additionalReference: pickupData.pickupFullData.pickupRequest.additionalReference,
  specialInstructions: pickupData.pickupFullData.pickupRequest.specialInstructions,
  manifestNumber: pickupData.pickupFullData.pickupRequest.manifestNumber,
  driverId: pickupData.pickupFullData.pickupRequest.driverId,
  driverName: pickupData.pickup.driverName,
  requester: pickupData.requester?.teamName,
  shipper: {
    name: pickupData.pickupFullData.pickupRequest.shipperName,
    address: {
      city: pickupData.pickupFullData.pickupRequest.shipperCity,
      state: pickupData.pickupFullData.pickupRequest.shipperState,
      address1: pickupData.pickupFullData.pickupRequest.shipperAddress,
      postalCode: pickupData.pickupFullData.pickupRequest.shipperZip,
      additionalAddressLine: pickupData.pickupFullData.pickupRequest.shipperAddressAdditional,
    },
    firstName: pickupData.pickupFullData.pickupRequest.shipperFirstName,
    lastName: pickupData.pickupFullData.pickupRequest.shipperLastName,
    mobilePhone: pickupData.pickupFullData.pickupRequest.shipperMobilePhone,
    allowSms: pickupData.pickupFullData.pickupRequest.shipperAllowSms,
    email: pickupData.pickupFullData.pickupRequest.shipperEmail,
  },
  consignee: {
    name: pickupData.pickupFullData.pickupRequest.consigneeName,
    address: {
      city: pickupData.pickupFullData.pickupRequest.consigneeCity,
      state: pickupData.pickupFullData.pickupRequest.consigneeState,
      address1: pickupData.pickupFullData.pickupRequest.consigneeAddress1,
      postalCode: pickupData.pickupFullData.pickupRequest.consigneePostalCode,
      additionalAddressLine: pickupData.pickupFullData.pickupRequest.consigneeAddressAdditional,
    },    
    phone: pickupData.pickupFullData.pickupRequest.consigneePhone,    
    email: pickupData.pickupFullData.pickupRequest.consigneeEmail,
  },
  payor: {
    name: pickupData.pickupFullData.pickupRequest.payorName,
    address: {
      city: pickupData.pickupFullData.pickupRequest.payorCity,
      state: pickupData.pickupFullData.pickupRequest.payorState,
      address1: pickupData.pickupFullData.pickupRequest.payorAddress,
      postalCode: pickupData.pickupFullData.pickupRequest.payorZip,
    },    
    phone: pickupData.pickupFullData.pickupRequest.payorPhone,
  },
  documents: pickupData.pickupFullData.pickupRequest.documents,
  items: pickupData.pickupFullData.pickupRequest.items,
}};

export const getDateClassName = (pickupDays: weekDays | null, date: Date) => {
  if (!pickupDays) return "";
  const dateDayOfWeek = moment(date).format("dd").toLowerCase() as WeekDay;
  return pickupDays[dateDayOfWeek] === false ? "xgs-pickup__date__non-service-day" : "";
};